<template>
    <div class="mt-4 mb-4 blue-bg row">
        <div class="col-6 mt-3 mb-3">
            <ul class="bullet" v-for="heading in evenHeadings" :key="heading.id">
                <li><a :href="'#' + heading.id">{{ heading.text }}</a></li>
            </ul>
        </div>
        <div class="col-6 mt-3 mb-3">
            <ul class="bullet" v-for="heading in oddHeadings" :key="heading.id">
                <li><a :href="'#' + heading.id">{{ heading.text }}</a></li>
            </ul>
        </div>
    </div>
</template>

<script type="text/javascript">

    export default {
        inheritAttrs: false,
        name: 'HeadingBookmarks',
        
        mixins: [],

        components: {
            
        },

        filters: {

        },

        provide: function() {
            return {

            }
        },

        inject: [],

        props: {
            headings: {
                type: Array,
                required: true
            }
        },

        data() {
            return {

            }
        },

        computed: {
            evenHeadings() {
                return this.headings.filter((x, i) => (i % 2) === 0)
            },
            oddHeadings() {
                return this.headings.filter((x, i) => (i % 2) === 1)
            }
        },

        watch: {

        },

        created() {

        },

        mounted() {
        },

        methods: {

        },


    }
</script>

<style lang="scss">
@import '../../../styles/custom-variables.scss';
.blue-bg {
    background-color: rgba($midblue, 0.15);
}
ul.bullet {
    margin-block-end: 0px;
    line-height: 162.6%;
    font-size: 15px;
    li::marker {
        color: var(--bs-link-color);
    }
}
</style>