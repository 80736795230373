<template>
	<div class="investment-owner p-3">
		<div class="d-flex justify-content-between">
			<h5 class="text">{{investment.company_name}}</h5>
			<a href="#" v-on:click.stop v-on:click.prevent="onEditClick">edit</a>
		</div>
		<div class="owners">
			<div>Associate: {{associates}}</div>
			<div>Verification: {{verifiers}}</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "InvestmentOwner",
	props: ['investment'],
	emits: ['edit'],
	computed: {
		associates() {
			if(this.investment.investment_financials_uploader) {
				return this.investment.investment_financials_uploader.map( (user) => {return user.name}).join(', ') ?? []
			} else {
				return 'Not set'
			}
		},
		verifiers() {
			if(this.investment.investment_financials_verifier) {
				return this.investment.investment_financials_verifier.map((user) => {
					return user.name
				}).join(', ') ?? []
			} else {
				return 'Not set'
			}
		}
	},
	methods: {
		onEditClick() {
			this.$emit('edit', this.investment)
		}
	}
}
</script>

<style lang="scss" scoped>
	h5 {
		font-weight: 400;
	}
	@import '../../styles/custom-variables';
	.investment-owner:nth-child(odd) {
		background-color: $zebra;
	}
	.owners {
		color: $graphite;
	}
</style>