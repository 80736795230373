import { defineStore } from 'pinia'
import UserDataService from "@/firebaseServices/UserDataService";

export const useUserStore = defineStore('users', {
	state: () => ({
		users: [],
	}),
	actions: {
		async initializeUsers() {
			this.users = await UserDataService.list(null, null, 1000)
		}
	},
})