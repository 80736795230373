<template>
	<div>
		<h3>Add-on Acquisitions</h3>
		<table class="table table-striped mt-3">
			<thead>
				<tr>
					<th>Name</th>
					<th>Entry Date</th>
					<th>Enterprise Value at Entry</th>
					<th>Equity at Entry</th>
					<th>Net Debt at Entry</th>
					<th>Revenue at Entry</th>
					<th>EBITDA at Entry</th>
					<th>EBITDA Purch. Mult at Entry</th>
					<th>Equity Invested</th>
					<th>Sub Debt Invested</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="acquisition in acquisitionsData" :key="acquisition.key">
					<td>{{acquisition['Add-on Company Name']}}</td>
					<td><format-date :date="acquisition['Entry Date']" utc-time="true" to-format="MM/dd/yy" /></td>
					<td><format-currency :number="Number(acquisition['Enterprise Value at Entry'] || NaN)" /></td>
					<td><format-currency :number="Number(acquisition['Equity at Entry'] || NaN)" /></td>
					<td><format-currency :number="Number(acquisition['Net Debt at Entry'] || NaN)" /></td>
					<td><format-currency :number="Number(acquisition['Revenue at Entry'] || NaN)" /></td>
					<td><format-currency :number="Number(acquisition['EBITDA at Entry'] || NaN)" /></td>
					<td><format-number :number="Number(acquisition['EBITDA Purch. Mult. At Entry'] || NaN)" /></td>
					<td><format-currency :number="Number(acquisition['BCAP Equity Invested'] || NaN)" /></td>
					<td><format-currency :number="Number(acquisition['BCAP Sub Debt Invested'] || NaN)" /></td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script type="text/javascript">
	import FormatDate from '@/views/utils/FormatDate'
	import FormatCurrency from '@/views/utils/FormatCurrency'
	import FormatNumber from "@/views/utils/FormatNumber";

	export default {
		inheritAttrs: false,

		mixins: [],

		components: {
			FormatNumber,
			FormatDate,
			FormatCurrency
		},

		filters: {
		},

		provide: function() {
			return {
			}
		},

		inject: [],

		props: {
			sourceData: {
				type: Array,
				require: true
			}
		},

		data() {
			return {
			
			}
		},

		computed: {
			acquisitionsData() {
				if(this.dataLoaded()) {
					const rows = []
					let index = 0
					const acquisitions = this.sourceData
					for(const acquisition of acquisitions) {
						acquisition.key = index
						rows.push(acquisition.data)
						index++
					}
					return rows
				} else {
					return []
				}
			}
		},

		watch: {

		},

		created() {
		},

		mounted() {
		},

		methods: {
			dataLoaded() {
				return this.sourceData && typeof(this.sourceData) != 'undefined' && Object.keys(this.sourceData).length > 0
			}
		}
	}
</script>

<style lang="scss">
	@import '../../../styles/custom-variables.scss';
	table {
		thead {
			th {
				color: $graphite;
			}
		}
		tbody {
			td {
				color: $black;
			}
		}
	}
</style>
