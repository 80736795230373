<template>
  <tr>
    <td><format-date v-bind:date="request.object_data.requestedDate"></format-date></td>
    <td><format-user-name v-bind:name="request.object_data.requestorDisplayName"></format-user-name></td>
    <td><router-link :to="{name: 'CompanyInvestment', params: {investmentId: request.object_data.company_id}}">{{ request.object_data.company_name }}</router-link></td>
    <td>{{request.object_data.message}}</td>
    <td>
      <div class="btn-group">
        <button v-on:click.stop class="btn btn-secondary btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
          Actions
        </button>
        <ul class="dropdown-menu">
          <li><a class="dropdown-item" v-on:click="markCompleted">Mark Completed</a></li>
        </ul>
      </div>
    </td>
  </tr>
</template>

<script type="text/javascript">

	import {RequestDataApi} from "@/api/request_data_api";
	import FormatDate from '../utils/FormatDate'
	import FormatUserName from "@/views/utils/FormatUserName";

	export default {
		name: 'OutstandingRequest',
		inheritAttrs: false,
		emits: ['requestUpdated'],
		mixins: [],
		components: {
			FormatDate,
			FormatUserName
		},
		
		filters: {
		},
		
		provide: function() {
			return {
			}
		},
		
		inject: [],
		
		props: ['request'],

		computed: {
		},
		
		watch: {
		},
		
		created() {
		},
		
		mounted() {
		},
		
		methods: {
			markCompleted() {
				console.log(`markcomplete ${JSON.stringify(this.request)}`)
				RequestDataApi.updateStatus({requestId: this.request.EquivateObjectId, status: 'COMPLETED'})
						.then(response => {
							this.$emit('requestUpdated', this.request, response.data)
						})
			}
		}
	}
</script>

<style lang="scss" scoped>
	@import '../../styles/custom-variables';
	td {
		color: $red;
	}
</style>
