<template>
	<modal-dialog :visible="visible" @close="close">
		<template v-slot:header>Upload Data</template>
		<template v-slot:body>
			<form v-on:submit.prevent="onFormSubmit">
				<div class="mb-3">
					<label class="form-label">Investment</label>
					<drop-down :options="companies" :value="selectedCompanyId" v-on:option-selected="onCompanySelected"></drop-down>
					<span class="field-error" v-if="v$.selectedCompanyId.$error">{{ v$.selectedCompanyId.$errors[0].$message }}</span>
				</div>
				
				<div class="mb-3">
					<drop-zone ref="dropZone" class="drop-area" @filesDropped="onFilesDropped"></drop-zone>
					<span class="field-error" v-if="v$.selectedFile.$error">{{ v$.selectedFile.$errors[0].$message }}</span>
				</div>
				<div class="mb-3">
					<button class="btn btn-primary w-100" :class="{disabled: isUploadDisabled}">Upload File</button>
				</div>
			</form>
		</template>
	</modal-dialog>
</template>

<script type="text/javascript">

	import ModalDialog from "@/views/shared/ModalDialog";
	import DropZone from '@/views/shared/DropZone'
	import DropDown from '@/views/shared/DropDown'
	import {getDownloadURL, getStorage, ref, deleteObject} from "firebase/storage";
	import {DateTime} from "luxon";
	import {uploadBytes} from "@firebase/storage";
	import useVuelidate from "@vuelidate/core";
	import {required} from "@vuelidate/validators";
	import {InvestmentsApi} from '@/api/investment_api'
	import {UploadsApi} from "@/api/uploads_api";

	export default {
		name: 'DataCollectionUploadModal',
		inheritAttrs: false,
		emits: ['close'],
		mixins: [],
		
		components: {
			ModalDialog,
			DropDown,
			DropZone
		},
		
		filters: {
		},
		
		provide: function() {
			return {
			}
		},
		
		inject: [],
		
		props: ['visible','companies'],

		setup() {
			return {
				v$: useVuelidate()
			}
		},

		validations () {
			return {
				selectedCompanyId: {required},
				selectedFile: {required}
			}
		},
		
		data() {
			return {
				companyId: null,
				companyName: null,
				selectedFile: null,
				uploading: false
			}
		},
		
		computed: {
			selectedCompanyId() {
				return this.companyId
			},
			isUploadDisabled() {
				return this.selectedCompanyId == null || !this.selectedFile || this.uploading == true
			},
		},
		
		watch: {
			
		},
		
		created() {
		},
		
		mounted() {
		},
		
		methods: {
			close() {
				this.$emit('close');
			},

			async onFilesDropped(event) {
				console.log('onFilesDropped' + event)
				const file = event[0]
				const storage = getStorage();
				const dateStamp = DateTime.now().toFormat('yyyy-LL-dd-HH-mm-ss')
				let storageRef = ref(storage, `tenants/${this.$tenantId()}/financials-validations/${this.companyId}/${dateStamp}/${file.name}`);
				let uploadResult = await uploadBytes(storageRef, file);
				const fileRef = uploadResult.ref;

				return InvestmentsApi.validateUpload({bucket: fileRef.bucket, fullPath: fileRef.fullPath, name: fileRef.name, investmentId:this.companyId}).then(async (result) => {
					if(result.data.success) {
						this.$refs.dropZone.validationComplete(true, [])
						this.selectedFile = file
					} else {
						this.$refs.dropZone.validationComplete(false, result.data.errors)
					}

					return deleteObject(fileRef)
				})
			},

			onCompanySelected(optionId, optLabel) {
				this.companyId = optionId
				this.companyName = optLabel
			},

			async onFormSubmit() {
				this.v$.$validate()
				if (!this.v$.$error) {
					this.uploading = true
					const storage = getStorage();
					const dateStamp = DateTime.now().toFormat('yyyy-LL-dd-HH-mm-ss')
					let storageRef = ref(storage, `tenants/${this.$tenantId()}/financials/${this.companyId}/${dateStamp}/${this.selectedFile.name}`);

					let uploadResult = await uploadBytes(storageRef, this.selectedFile);
					let primaryUrl = await getDownloadURL(uploadResult.ref);
					console.log('File available at', primaryUrl);

					UploadsApi.newFinancialsUpload({investmentId: this.companyId, investmentName: this.companyName, fileName: this.selectedFile.name, url: primaryUrl, uploadingUser: this.$store.state.username, bucket: storageRef.bucket, path: storageRef.fullPath}).then(() => {
						this.$refs.dropZone.reset()
						document.location.reload()
					})
					.catch(err => {
						console.error('Error creating new financials upload', err)
					})
					.finally(() => {
						this.uploading = false
					})
				}
			}

		}
	}
</script>

<style lang="scss" scoped>
</style>