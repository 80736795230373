import { getFunctions, httpsCallable } from "firebase/functions";

const FundsApi = function() {
	this.getFund = (fundId) => {
		return httpsCallable(getFunctions(), 'getFund')(fundId)
	}

	this.getFundInvestments = ({fundId, status, limit, offset}) => {
		return httpsCallable(getFunctions(), 'getFundInvestments')({fundId, limit, offset, status: status})
	}
}

FundsApi.getFunds = () => {
	console.log('getFunds')
	return httpsCallable(getFunctions(), 'getFunds')()
		.then(fundData => {
			const fundsObj = {}

			fundData.data.funds.forEach(fund => {
				const fdata = fundsObj[fund.id] || mapFund(fund)

				fdata[fund.financial_data_type] = fund.financial_data

				fundsObj[fund.id] = fdata
			})

			return Object.values(fundsObj).sort((f1, f2) => f1.displayOrder - f2.displayOrder)
		})
}

FundsApi.getFundInvestments = ({status, limit, offset}) => {
		return httpsCallable(getFunctions(), 'getFundInvestments')({limit, offset, status: status})
}

FundsApi.createFund = (data) => {
	return httpsCallable(getFunctions(), 'createFund')(data)
}
	
export {FundsApi}


//
// utilities
//
function mapFund(fund) {
	return {
		id: fund.fund_data.id || fund.id,
		asOf: fund.last_updated,
		firestore_id: fund.fund_data.firestore_id,
		name: fund.fund_data.name || fund.name,
		displayOrder: fund.fund_data.display_order || fund.display_order,
		companies: fund.fund_data.companies,
	}
}
