<template>
	<ul class="list-inline">
		<li class="list-inline-item" v-for="navOpt in navOptions" v-bind:key="navOpt.value">
			<a class="btn pill me-1 ms-1" :class="{active: activeNavOption.value === navOpt.value}" @click="navPillClicked(navOpt)">{{navOpt.name}}</a>
		</li>
	</ul>
</template>

<script type="text/javascript">
	export default {
		inheritAttrs: false,

		mixins: [],

		components: {
		},

		filters: {
		},

		provide: function() {
			return {
			}
		},

		inject: [],

		props: {
			navOptions: {
				type: Array,
				required: true,
			}
		},

		data() {
			return {
				activeNav: null
			}
		},

		computed: {
			activeNavOption() {
				if(this.activeNav) return this.activeNav

				return this.navOptions[0]
			}
		},

		watch: {

		},

		created() {
		},

		mounted() {
			this.activeNav = this.navOptions[0]
		},

		methods: {
			navPillClicked(navOpt) {
				this.activeNav = navOpt
				this.$emit('pill-nav-clicked', this.activeNav.value)
			}
		}
	}
</script>

<style lang="scss" scoped>
</style>
