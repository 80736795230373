export default {
	data() {
		return {
			years: [],
			year: null,
			quarter: null,
			lastQuarterDate: null,
			quarters: ['Q1', 'Q2', 'Q3', 'Q4']
		}
	},
	mounted() {
		this.lastQuarterDate = new Date()
		this.lastQuarterDate.setMonth(this.lastQuarterDate.getMonth() - 3) // get last quarter

		// Years (get last 4 years)
		let currentYear = this.lastQuarterDate.getFullYear()
		this.year = currentYear
		for (let i = 0; i < 4; i++) {
			this.years.push(currentYear - i)
		}

		// Current quarter
		let month = this.lastQuarterDate.getMonth()
		if (month >= 0 && month <= 2) {
			this.quarter = 'Q1'
		} else if (month >= 3 && month <= 5) {
			this.quarter = 'Q2'
		} else if (month >= 6 && month <= 8) {
			this.quarter = 'Q3'
		} else {
			this.quarter = 'Q4'
		}
	}
}