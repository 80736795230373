<template>
	<div>
		<div class="row">
			<div class="col">
				<bread-crumb
						:breadcrumbData="breadcrumbData" />
			</div>
		</div>

		<div v-if="loading" class="report-loader">
			<div class="loader-message">Custom Report Generating</div>
			<div><h3 class="loader-submessage">This may take up to 1 minute.</h3></div>
					<div class="loader-container">
					<div class="loader-div">
						<img src="../../assets/equivateloader.gif" class="loader-image" alt="Loader">
				</div>
			</div>
		</div>
		
		<section class="mt-5" v-if="!loading">
				<div class="d-flex justify-content-between">
					<h3>Custom Report</h3>
					<ul class="list-inline">
						<!-- <li class="list-inline-item"><button class="btn btn-secondary" @click="saveReport()">Save Report</button></li> -->
						<li class="list-inline-item"><button class="btn btn-primary" @click="exportReport()">Export</button></li>
					</ul>
				</div>
			<div>
				<EasyDataTable
					:alternating="true"
					:rows-per-page="rows_per_page"
					:hide-footer="hideFooter"
					:headers="this.tableHeadings"
					:items="this.tableItems"
					:dense=true
					table-class-name="custom-table no-shadow"
				>
					<template #item-fund="{fund, fund_id}">
						<span v-for="fundObj in splitFundAgg(fund, fund_id)" :key="fundObj.id">
							<span v-if="fundObj.index != 0">/</span>
							<router-link :to="{name: 'FundInvestment', params: { fundId: fundObj.id }}">{{fundObj.name}}</router-link>
						</span>
					</template>
					<template #item-portfolio_company_name="{portfolio_company_name, company_id}">
						<span><router-link :to="{name: 'CompanyInvestment', params: { investmentId: company_id }}">{{portfolio_company_name}}</router-link></span>
					</template>
				</EasyDataTable>
			</div>
		</section>
	</div>
	
</template>

<script type="text/javascript">

	import BreadCrumb from '@/views/nav/BreadCrumb.vue';
	import {ReportApi} from "@/api/report_api";
	import {formatDollars} from '@/utils/currency_utils'
	import {formatPercentage} from '@/utils/percent_utils'
	import {convertDateFromUnixTimestamp} from '@/utils/date_utils'
	import {downloadData} from "@/utils/download_utils";
	import {parse} from "json2csv"

	export default {
		name: 'ReportView',
		inheritAttrs: false,

		mixins: [],

		components: {
			BreadCrumb
		},

		filters: {
		},

		provide: function() {
			return {
			}
		},

		inject: [],

		props: ['funds','investments'],

		data() {
			return {
				tableHeadings: [],
				tableItems: [],
				loading: true,
				rows_per_page: 25
			}
		},

		computed: {
			breadcrumbData() {
				return [
					{
						linkText: 'Reports',
						routerLinkName: 'ReportsView'
					},
					{
						linkText: 'Custom Report'
					}
				]
			},

			hideFooter() {
				return this.tableItems.length <= this.rows_per_page
			}
		},

		watch: {

		},

		created() {
		},

		mounted() {
			this.loadReportData()
		},

		methods: {
			loadReportData() {
				const reportApi = ReportApi
				if(this.investments != '-'){
					let investmentIds = this.investments.split(',')
					ReportApi.getInvestmentLPSummary(investmentIds).then((resp) => {
						if(resp.data.length >0) {
							this.setResults(resp.data)
							
						}
					}).catch(err =>
						{
							console.error('Error calling reportApi.getInvestmentLPSummary()', err)
							this.loading = false
						}
					)
				} else if (this.funds != '-') {
					let fundIds = this.funds.split(',')
					ReportApi.getFundLPSummary(fundIds).then((resp) => {
						if(resp.data.length >0) {
							this.setResults(resp.data)
						}
					}).catch(err =>
						{
							console.error('Error calling reportApi.getFundLPSummary()', err)
							this.loading = false
						}
					)
				} else {
					reportApi.getFullLPSummary().then((resp) => {
						if(resp.data.length >0) {
							this.setResults(resp.data)
						}
					}).catch(err =>
						{
							console.error('Error calling reportApi.getFullLPSummary()', err)
							this.loading = false
						}
					)
				}

			},

			setResults(results) {
				this.processHeaders(results);
				this.tableItems = results.map(item => this.flattenObject(item)).map(item => this.formatInput(item))
				this.loading = false
			},

			toSnakeCase(str) {
				return str.toLowerCase().replace(/\s+/g, '_').replace(/[^a-z0-9_%]/g, '').replace(/^_+/, '');
			},

			formatEntry(outerKey, innerKey) {
				const compoundKey = `${outerKey} ${innerKey}`;
				const med_length = ['Overview Weighted Average Investment Life', 'Bertram Fund Allocation Ignite I-A', 'At Current/At Exit Total FMV (Debt + Equity)', 'At Current/At Exit Equity & Debt Gain / (Loss)', 'At Current/At Exit Gross MOIC (Equity and Debt)', 'MOIC two full quarters prior to exit']
				const long_length = ['At Current/At Exit Net MOIC Investment (Equity and Debt)']
				const xlong_length = ['Company Description (Short Description)', 'Board members from the fund']
				if (compoundKey.trim() === 'Fund') {
					return { text: compoundKey, value: this.toSnakeCase(compoundKey), fixed: true, width: 160 };
				} else if (compoundKey.trim() === 'Portfolio Company Name') {
					return { text: 'Investment', value: this.toSnakeCase(compoundKey), fixed: true, width: 200 };
				} else if (xlong_length.includes(compoundKey.trim())) {
					return { text: compoundKey, value: this.toSnakeCase(compoundKey), width: 300 };
				} else if (long_length.includes(compoundKey.trim())) {
					return { text: compoundKey, value: this.toSnakeCase(compoundKey), width: 200 };
				} else if (med_length.includes(compoundKey.trim()) || compoundKey.includes("Bertram Funds")) {
					return { text: compoundKey, value: this.toSnakeCase(compoundKey), width: 180 };
				} else {
					return { text: compoundKey, value: this.toSnakeCase(compoundKey), width: 130 };
				}
			},

			processHeaders(results) {
				for (let result in results) {
					this.processHeader(results[result])
				}
			},
			processHeader(obj, prefix = '') {
				for (let key in obj) {
					if (Object.prototype.hasOwnProperty.call(obj, key)) {
						let value = obj[key]
						let compoundKey = prefix ? `${prefix} ${key}` : key;

						if (value !== null && typeof value === 'object' && !Array.isArray(value)) {
							this.processHeader(value, compoundKey); // Recursively process nested objects
						} else if (key !== 'Fund Id' && key !== 'Company Id'){
							let potentialHeader = this.formatEntry(prefix, key)
							if (!this.tableHeadings.some(el => el.value === potentialHeader.value)){
								this.tableHeadings.push(potentialHeader);
							}
						}
					}
				}
			},

			flattenObject(obj, prefix = '') {
				const currency_terms = ["Revenue", "EBITDA", "Value", "Invested","Debt", "Equity", "MOIC", "Fees"]
				const percentage_terms = ["Allocation", "%"]
				const date_terms = ['Date']
				let flatObject = {};
				for (let key in obj) {
					if (Object.prototype.hasOwnProperty.call(obj, key)) {
						let value = obj[key];
						let compoundKey = prefix ? `${prefix} ${key}` : key;

						if (value !== null && typeof value === 'object' && !Array.isArray(value)) {
							Object.assign(flatObject, this.flattenObject(value, compoundKey));
						} else if (currency_terms.some(term => compoundKey.includes(term))) {
							flatObject[this.toSnakeCase(compoundKey)] = formatDollars(value);
						} else if (percentage_terms.some(term => compoundKey.includes(term)) && typeof value === 'number') {
							flatObject[this.toSnakeCase(compoundKey)] = formatPercentage(value);
						} else if (date_terms.some(term => compoundKey.includes(term)) && !isNaN(parseInt(value))) {
							flatObject[this.toSnakeCase(compoundKey)] = convertDateFromUnixTimestamp(value);
						}
						else {
							flatObject[this.toSnakeCase(compoundKey)] = value;
						}
					}
				}
				for (const [key, value] of Object.entries(flatObject)) {
					flatObject[key] = this.formatInput(value)
				}
				return flatObject;
			},

			formatInput(input) {
				if (!isNaN(input) && input != null && input.toString().indexOf('.') !== -1) {
					return parseFloat(input).toFixed(3);
				} else {
					return input
				}
			},

			saveReport() {
				alert("One day you will be able to save a report!")
			},

			exportReport() {
				let currentDate = new Date().toJSON().slice(0, 19);
				const jsonForCsv = []
				for (const r in this.tableItems) {
					let row = this.tableItems[r]
					const { ... csvData } = Object.fromEntries(
						Object.entries(row).filter(([key]) => key !== 'fund_id' && key !== 'company_id'));
					jsonForCsv.push(csvData)
				}
				downloadData({fileName: `CustomReport${currentDate}.csv`, data: parse(jsonForCsv, {})})
			},

			splitFundAgg(fundName, fundId) {
				let out = []
				const nameArr = fundName.split('/')
				const idArr = fundId.split('/')
				for (let i = 0; i < nameArr.length; i++) {
					let obj = {}
					obj.name = nameArr[i]
					obj.id = idArr[i]
					obj.index = i
					out.push(obj)
				}
				console.log('split', out)
				return out
			}
		}
	}
</script>

<style lang="scss">
@import '../../styles/custom-variables';
.report-options {
	background-color: $zebra;
}
.report-loader {
	width: 566px; 
	height: 200px;
	left: 50%;
	top: 50%;
	position: absolute;
    transform: translate(-50%,-50%);
}
.loader-message {
	width: 566px; 
	height: 25px; 
	left: 0px; 
	top: 84px; 
	position: absolute; 
	text-align: center; 
	color: $navy; 
	font-size: 24px;
}
.loader-submessage {
	width: 276px; 
	height: 71px; 
	left: 145px; 
	top: 129px; 
	position: absolute; 
	text-align: center;
	color: $darkblue !important;
}
.loader-container {
	width: 48px; 
	height: 48px; 
	left: 259px; 
	top: 0px; 
	position: absolute; 
	flex-direction: column; 
	justify-content: flex-start; 
	align-items: flex-start; 
	gap: 16px; 
	display: inline-flex
}
.loader-div {
	width: 48px; 
	height: 48px; 
	position: relative
}
.loader-image {
	width: 48px; 
	height: 48px; 
	display:block; 
	margin-left: auto; 
	margin-right: auto;
}
</style>
