import {useUserStore} from '@/stores/user'

function formatUserName(name) {
    if (name) {
        let userName = name

        // If email.. lookup the user in the store
        const regexExp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/gi;
        if (regexExp.test(name)) {
            for (const user of useUserStore().$state.users) {
                if (user.data().email.toLowerCase() == name.toLowerCase()) {
                    userName = user.data().name || name
                }
            }
        }

        if(userName) {
            let parts = userName.split(' ')
            if (parts.length == 2) {
                return `${parts[0].charAt(0)}. ${parts[1]}`
            } else {
                return parts[0]
            }
        }
    }
    return ''
}

export {formatUserName}