import { initializeApp } from "firebase/app";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getStorage, connectStorageEmulator } from "firebase/storage";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getAuth, connectAuthEmulator } from "firebase/auth";

const firebaseConfig = {
  "projectId": process.env.VUE_APP_FIREBASE_PROJECT_ID,
  "appId": process.env.VUE_APP_FIREBASE_APP_ID,
  "storageBucket": process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  "locationId": process.env.VUE_APP_FIREBASE_LOCATION_ID,
  "apiKey": process.env.VUE_APP_FIREBASE_API_KEY,
  "authDomain": process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  "messagingSenderId": process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  "measurementId": process.env.VUE_APP_FIREBASE_MEASUREMENT_ID
};

const app = initializeApp(firebaseConfig)
if(process.env.NODE_ENV === 'development' && window.location.host == 'localhost:8080' && process.env.VUE_APP_USE_LOCAL_EMULATORS != 'false') {
	const db = getFirestore();
	connectFirestoreEmulator(db, 'localhost', 8081);

	const auth = getAuth();
	connectAuthEmulator(auth, "http://localhost:9099");

	const storage = getStorage();
	connectStorageEmulator(storage, "localhost", 9199);

	const functions = getFunctions();
	connectFunctionsEmulator(functions, "localhost", 5001);
}

export default app