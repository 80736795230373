<template>
	<div class="row">
		<div class="col">
			<div class="form-label">Funds</div>
			<VueMultiselect
					v-model="internalFundsSelected"
					:disabled="isScopedToInvestment()"
					:multiple="multiSelect"
					:placeholder="compFundPlaceholder"
					label="name"
					:close-on-select="!multiSelect"
					track-by="name"
					v-on:select="onFundsChanged"
					v-on:remove="onFundsChanged"
					:options="funds">
			</VueMultiselect>
		</div>
		<div class="col">
			<div class="form-label">Investments</div>
			<VueMultiselect
					v-model="internalInvestmentsSelected"
					:disabled="isScopedToInvestment()"
					:multiple="multiSelect"
					:placeholder="compInvestPlaceholder"
					label="name"
					:close-on-select="!multiSelect"
					track-by="name"
					v-on:select="onInvestmentsChanged"
					v-on:remove="onInvestmentsChanged"
					:options="availableInvestments">
			</VueMultiselect>
		</div>

		<div class="col" v-if="includeDateRange">
			<div class="form-label">DATE RANGE</div>
			<div class="dropdown-container">
			<select class="custom-dropdown" disabled>
				<option value="" disabled selected>Most Recent</option>
			</select>
			</div>
		</div>
		
	</div>
</template>

<script type="text/javascript">

import FundDataService from "@/firebaseServices/FundDataService";
import {InvestmentsApi} from "@/api/investment_api";
import VueMultiselect from 'vue-multiselect'

export default {
	name: 'FundInvestmentsSelector',
	inheritAttrs: false,

	mixins: [],

	components: {
		VueMultiselect
	},

	filters: {
	},

	provide: function() {
		return {
		}
	},

	inject: [],

	emits: ['update:fundsSelected', 'update:investmentsSelected', 'investmentsChanged'],

	props: {
		fundsPlaceholder: {
			type: String,
			default() {
				return 'All Funds'
			}
		},

		investmentsPlaceholder: {
			type: String,
			default() {
				return 'All Investments'
			}
		},

		fundsSelected: {
			type: Array,
			default() {
				return []
			}
		},
		investmentsSelected: {
			type: Array,
			default() {
				return []
			}
		},
		investmentId: {
			type: String,
			default() {
				return null
			}
		},
		readOnly: {
			type: Boolean,
			default(){
				return false
			}
		},
		multiSelect: {
			type: Boolean,
			default() {
				return true
			}
		},
		includeDateRange: {
			type: Boolean,
			default() {
				return false
			}
		}
	},

	data() {
		return {
			funds: [],
			investments: [],
			availableInvestments: [],
			internalFundsSelected: [],
			internalInvestmentsSelected: []
		}
	},

	computed: {
		compFundPlaceholder() {
			let retPlaceholder = (this.internalFundsSelected && this.internalFundsSelected.length > 0) ? '' : this.fundsPlaceholder
			return retPlaceholder
		},
		compInvestPlaceholder() {
			let retPlaceholder = (this.internalInvestmentsSelected && this.internalInvestmentsSelected.length > 0) ? '' : this.investmentsPlaceholder
			return retPlaceholder
		}

	},

	watch: {

	},

	created() {
	},

	mounted() {
		this.loadFunds()
		this.loadInvestments()
	},

	methods: {
		isScopedToInvestment() {
			return this.investmentId != null && this.readOnly
		},

		getAllInvestments() {
			return this.availableInvestments
		},

		loadFunds() {
			FundDataService.loadFunds().then(response => {
				this.funds = response.map(fund => ({
					id: fund.id,
					name: fund.name
				}))
			})
		},
		loadInvestments() {
			InvestmentsApi.getInvestments().then(response => {
				this.investments = response.data.investments
				this.generateInvestments(this.investmentId)
			})
		},

		generateInvestments(primaryInvestmentId) {
			// Need to regenerate the list of investments
			let funds = this.internalFundsSelected
			if (!this.internalFundsSelected) {
				funds = []
			} else if(this.internalFundsSelected.constructor && this.internalFundsSelected.constructor.name != 'Array') {
				funds = [this.internalFundsSelected]
			}

			const fundIds = funds.map(fund => (fund.id))
			let primaryInvestment = null
			this.availableInvestments = this.investments.filter(company => {
				if(fundIds.length == 0 && company.id != primaryInvestmentId) {
					return true
				}
				if(company.id == primaryInvestmentId) {
					primaryInvestment = company
					return true
				} 
				const companyFundIds = company.funds.map(companyFund => companyFund.id)
				const intersectFunds = fundIds.filter(fundId => companyFundIds.includes(fundId))
				return intersectFunds.length > 0
			}).sort((c1, c2) => c1.name.localeCompare(c2.name))

			this.internalInvestmentsSelected = primaryInvestment != null ? [primaryInvestment] : []
			this.$emit('update:fundsSelected', funds)
			this.$emit('update:investmentsSelected', this.internalInvestmentsSelected)
		},

		onFundsChanged() {
			this.internalInvestmentsSelected = []
			this.onInvestmentsChanged()
			this.generateInvestments(null)
		},

		onInvestmentsChanged() {
			let investments = this.internalInvestmentsSelected
			if(this.internalInvestmentsSelected && this.internalInvestmentsSelected.constructor.name != 'Array') {
				investments = [this.internalInvestmentsSelected]
			}
			this.$emit('update:investmentsSelected', investments)
			this.$emit('investmentsChanged', investments)
		}
	}
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style lang="scss">
@import '../../styles/custom-variables';
.multiselect__select::before {
	border-color: $black transparent transparent transparent;
}
.multiselect__tags {
	border-radius: 0px;
	.multiselect__placeholder {
		color: $black
	}
	.multiselect__tag {
		background: #E2EBF5;
		color: $graphite;
		.multiselect__tag-icon::after {
			color: $darkblue;
		}
		.multiselect__tag-icon:hover::after,
		.multiselect__tag-icon:focus::after {
		color:  $darkblue; /* Replace with hover/focus color of 'x' */
		}
	}
}
.multiselect, .multiselect__input, .multiselect__single {
	font-size: 14px;
}
.multiselect__option--highlight {
  background-color: #E2EBF5;
  background: #E2EBF5;
  color: $graphite; 
}

.multiselect__option--selected {
	background-color: white;
	position: relative;
	span {
		text-shadow: .05px 0 currentColor, -.05px 0 currentColor, 0 .05px currentColor, 0 -.05px currentColor;
	}
}

.multiselect__option--selected::after {
  content: 'Remove';
  color:  $darkblue;
  position: absolute;
  right: 10px;
  font-weight: normal;
}

.multiselect__option[data-select]::after {
  content: none;
}

.multiselect__option--selected[data-deselect]::after {
  content: 'Remove' !important;
  color:  $darkblue;
  position: absolute;
  right: 10px;
  font-weight: normal;
  background: white;
}

.multiselect__option--selected.multiselect__option--highlight {
	background: #E2EBF5;
	color: $graphite;
}
.multiselect__option--selected.multiselect__option--highlight::after {
  background-color: #E2EBF5;
  color: $darkblue;
}

.multiselect__option {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  text-align: left;
}

.multiselect__content {
  max-width: 100%;
  overflow: hidden;
}

.custom-dropdown {
  width: 100%;
  height: 100%;
  padding: 8px 12px;
  border: 1px solid #CBCBCB;
  border-radius: 1px;
  background-color: #f5f5f5;
  color: #a0a0a0;
  cursor: not-allowed;
}

.dropdown-container {
	height: 42px;
}

</style>
