import { defineStore } from 'pinia'
import {FundsApi} from '@/api/funds_api'

export const useFundStore = defineStore('funds', {
	state: () => ({
		funds: [],
	}),
	actions: {
		async initializeFunds() {
			this.funds = await FundsApi.getFunds()
		},
		async reloadFunds() {
			this.funds = await FundsApi.getFunds()
		}
	},
})
