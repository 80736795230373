import { getFunctions, httpsCallable } from "firebase/functions";

const RequestDataApi = function() {
}

RequestDataApi.requestData = (requestOptions) => {
	return httpsCallable(getFunctions(), 'requestData')(requestOptions)
}

RequestDataApi.getRequests = ({userId=null, status=null, limit=100, offset=0, sort='date_created', order='DESC'}={}) => {
	return httpsCallable(getFunctions(), 'getRequests')({userId, status, limit, offset, sort, order})
}

RequestDataApi.updateStatus = ({requestId=null, status=null}={}) => {
	return httpsCallable(getFunctions(), 'updateStatus')({requestId, status})
}

export {RequestDataApi}

