<template>
	<div class="main-container">
		<div class="container">
			<div class="row">
				<div class="col-md-7 ml-sm-auto col-lg-7 px-4 mb-2">
					<h2>Admin Utilities</h2>
					<div class="alert alert-success" role="alert" v-if="updateMessage">
						{{ updateMessage }}
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col-md-7 ml-sm-auto col-lg-7 px-4 mb-4">
					<div class="border p-3">
						<h3>Reset Financials</h3>
						<div class="form-text mb-2">This will clear out all of the financials for all portfolio companies and funds.
							This requires re-importing of fund definition spreadsheet, quarterly financials spreadsheets, and cash
							in/cash out spreadsheet.
						</div>
						<button type="submit" class="btn btn-primary" v-on:click="reset">Reset</button>
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col-md-7 ml-sm-auto col-lg-7 px-4 mb-4">
					<div class="border p-3">
						<h3>Regenerate Download links</h3>
						<div class="form-text mb-2">This is required after running the migration scripts for tenant support. The
							download links for the financial files need to be regenerated.
						</div>
						<button type="submit" class="btn btn-primary" v-on:click="regenerate">Regenerate</button>
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col-md-7 ml-sm-auto col-lg-7 px-4 mb-4">
					<form @submit.prevent="onFundsFormSubmit" class="border p-3">
						<div class="row mb-2">
							<h3>Import Fund Details</h3>
							<div class="form-text">Import the Fund size, amount invested, amount committed spreadsheet.</div>
						</div>
						<div class="row">
							<label class="col-sm-2">
								<button class="btn btn-primary btn-block">Upload</button>
							</label>
							<div class="col-sm-10">
								<input type="file" class="form-control" @change="onFundsFileChanged">
								<span class="field-error" v-if="v$.selectedFundsFile.$error">{{
										v$.selectedFundsFile.$errors[0].$message
									}}</span>
							</div>
						</div>
					</form>
				</div>
			</div>

			<div class="row">
				<div class="col-md-7 ml-sm-auto col-lg-7 px-4 mb-4">
					<form @submit.prevent="onFinancialsFormSubmit" class="border p-3">
						<div class="row mb-2">
							<h3>Import Investment Financials</h3>
							<div class="form-text">Import the Fund size, amount invested, amount committed spreadsheet.</div>
						</div>
						<div class="row mb-3">
							<label class="col-sm-2 col-form-label">Company *</label>
							<div class="col-sm-10">
								<select v-model="company" class="form-select">
									<option value="" disabled>Select</option>
									<option v-for="company in companies" :key="company" :value="company.id">{{ company.name }}</option>
								</select>
								<span class="field-error" v-if="v$.company.$error">{{ v$.company.$errors[0].$message }}</span>
							</div>
						</div>

						<div class="row mb-3">
							<label class="col-sm-2 col-form-label">File *</label>
							<div class="col-sm-10">
								<input type="file" class="form-control" @change="onFinancialsFileChanged">
								<span class="field-error" v-if="v$.selectedFinancialsFile.$error">{{ v$.selectedFinancialsFile.$errors[0].$message }}</span>
							</div>
						</div>
						<div>
							<button class="btn btn-primary btn-block">Upload</button>
						</div>
					</form>

				</div>
			</div>

			<div class="row">
				<div class="col-md-7 ml-sm-auto col-lg-7 px-4 mb-4">
					<form @submit.prevent="onCashFormSubmit" class="border p-3">
						<div class="row mb-2">
							<h3>Import Cash In/Cash Out</h3>
							<div class="form-text">Import the cash in/cash out spreadsheet.</div>
						</div>
						<div class="row">
							<label class="col-sm-2">
								<button class="btn btn-primary btn-block">Upload</button>
							</label>
							<div class="col-sm-10">
								<input type="file" class="form-control" @change="onCashFileChanged">
								<span class="field-error" v-if="v$.selectedCashFile.$error">{{
										v$.selectedCashFile.$errors[0].$message
									}}</span>
							</div>
						</div>
					</form>
				</div>
			</div>

		</div>
	</div>
</template>

<script>

import AdminDataService from "@/firebaseServices/AdminDataService";
import {getDownloadURL, getStorage, ref} from "firebase/storage";
import {uploadBytes} from "@firebase/storage";
import useVuelidate from "@vuelidate/core";
import {required} from "@vuelidate/validators";
import FinancialsDataService from "@/firebaseServices/FinancialsDataService";
import {DateTime} from "luxon";
import DateUtils from "@/mixins/DateUtils";
import CompanyDataService from "@/firebaseServices/CompanyDataService";

export default {
	name: 'AdminView',
	components: {},
	mixins: [DateUtils],
	data() {
		return {
			updateMessage: '',
			company: '',
			companies: [],
			selectedFundsFile: null,
			selectedFinancialsFile: null,
			selectedCashFile: null
		}
	},
	methods: {
		async reset() {
			this.updateMessage = ""
			if (confirm("Do you really want to reset all data?")) {
				await AdminDataService.reset()
				this.updateMessage = "Successfully reset all financials data"
			}
		},

		async regenerate() {
			this.updateMessage = ""
			await AdminDataService.regnerateDownloadURLs()
			this.updateMessage = "Successfully regenerated the download URLs"
		},

		onFundsFileChanged(event) {
			this.selectedFundsFile = event.target.files[0]
		},
		async onFundsFormSubmit() {
			this.updateMessage = ""
			this.v$.selectedFundsFile.$validate()
			if (!this.v$.selectedFundsFile.$error) {
				const storage = getStorage();
				let storageRef = ref(storage, `tenants/${this.$tenantId()}/import_fund_data/${this.selectedFundsFile.name}`);
				await uploadBytes(storageRef, this.selectedFundsFile);
				this.updateMessage = "Successfully imported Fund details"
			}
		},

		onFinancialsFileChanged(event) {
			this.selectedFinancialsFile = event.target.files[0]
		},
		async onFinancialsFormSubmit() {
			this.updateMessage = ""
			this.v$.selectedFinancialsFile.$validate()
			if (!this.v$.selectedFinancialsFile.$error) {
				const storage = getStorage();
				const dateStamp = DateTime.now().toFormat('yyyy-LL-dd-HH-mm-ss')
				let storageRef = ref(storage, `tenants/${this.$tenantId()}/financials/${this.company}/${dateStamp}/${this.selectedFinancialsFile.name}`);

				let uploadResult = await uploadBytes(storageRef, this.selectedFinancialsFile);
				let primaryUrl = await getDownloadURL(uploadResult.ref);
				console.log('File available at', primaryUrl);
				const docId = await FinancialsDataService.create(this.company, 2022, 'Q4', this.selectedFinancialsFile.name, primaryUrl, [], this.$store.state.username, storageRef.bucket, storageRef.fullPath)
				// Immediately approve it
				await FinancialsDataService.update(docId, {'status': 'Approved'})
				this.updateMessage = "Successfully imported financials data"
			}
		},

		onCashFileChanged(event) {
			this.selectedCashFile = event.target.files[0]
		},
		async onCashFormSubmit() {
			this.updateMessage = ""
			this.v$.selectedCashFile.$validate()
			if (!this.v$.selectedCashFile.$error) {
				const storage = getStorage();
				let storageRef = ref(storage, `tenants/${this.$tenantId()}/import_fund_performance/${this.selectedCashFile.name}`);
				await uploadBytes(storageRef, this.selectedCashFile);
				this.updateMessage = "Successfully imported cash flow data"
			}
		},

	},
	setup() {
		return {v$: useVuelidate()}
	},
	validations() {
		return {
			selectedFundsFile: {required},
			selectedFinancialsFile: {required},
			company: {required},
			selectedCashFile: {required},
		}
	},
	mounted() {
		// Companies
		CompanyDataService.list(null, null, 100).then(response => {
			response.companies.forEach((row) => {
				if (row.status == 'Active') {
					this.companies.push({id: row.id, name: row.name})
				}
			});
		})
	}
}
</script>
