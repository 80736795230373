<style lang="scss">
	.paging-control {
    .list-inline-item {
      margin: 0;
      padding: 5px;
    }
  }
</style>
<template>
	<div class="paging-control">
		<ul class="list-inline" v-if="showPagingControl">
			<li class="list-inline-item incremental-control" v-if="showPreviousButton">
				<a href="#" v-bind:class="previousButtonClass" v-on:click.prevent="firePagePrevious">Prev</a>
			</li>
			<li class="list-inline-item incremental-control" v-if="showNextButton">
				<a href="#" v-bind:class="nextButtonClass" v-on:click.prevent="firePageNext">Next</a>
			</li>
		</ul>
	</div>
</template>

<script type="text/javascript">

	export default {

    props: ['rows', 'pageSize'],

		data() {
			return {
        offset: 0,
				total: 0,
        initialLoad: true
			}
		},

		mounted() {
		},

		computed: {
			previousButtonClass() {
				return {
					'btn': true,
					'btn-primary': true,
					'btn-sm': true
				}
			},
			
			nextButtonClass() {
				return {
					'btn': true,
					'btn-primary': true,
					'btn-sm': true
				}
			},

			showPreviousButton() {
				return this.offset != 0
			},

			showNextButton() {
				return this.offset + this.pageSize < this.total
			},

      showPagingControl() {
          return this.showPreviousButton || this.showNextButton
      }
		},

		methods: {
      reset() {
        this.initialLoad = true
      },
      firePagePrevious() {
				this.$emit('page-load', this.offset - this.pageSize)
			},
      firePageNext() {
        this.$emit('page-load', this.offset + this.pageSize)
      },
      loadComplete(currentOffset, totalResults) {
				this.offset = currentOffset
				this.total = totalResults
        this.initialLoad = false
      }
		}
	}

</script>
