import { getFunctions, httpsCallable } from "firebase/functions";

const UploadsApi = function() {
}

UploadsApi.getUploads = ({limit=100, offset=0, sort='date_created', order='DESC'}={}) => {
	return httpsCallable(getFunctions(), 'getUploads')({limit, offset, sort, order})
}

UploadsApi.getUploadsByType = ({type='', limit=100, offset=0, sort='date_created', order='DESC'}={}) => {
	return httpsCallable(getFunctions(), 'getUploadsByType')({type, limit, offset, sort, order})
}

UploadsApi.newFinancialsUpload = ({investmentId, investmentName, fileName, url, uploadingUser, bucket, path}) => {
	return httpsCallable(getFunctions(), 'newFinancialsUpload')({investmentId, investmentName, fileName, url, uploadingUser, bucket, path})
}

UploadsApi.updateUploadStatus = ({uploadId, newStatus}) => {
	return httpsCallable(getFunctions(), 'updateUploadStatus')({uploadId, newStatus})
}

UploadsApi.getUploadsForObject = ({objectId, limit=100, offset=0, sort='date_created', order='DESC', status='%'}={}) => {
	return httpsCallable(getFunctions(), 'getUploadsForObject')({objectId, limit, offset, sort, order, status})
}


export {UploadsApi}
