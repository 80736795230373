<template>
	<div class="col p-0">
		<div class="mb-3 px-3 d-flex justify-content-between">
			<h3>Investment Owners</h3>
			<a href="#" v-on:click.stop v-on:click.prevent="showEditAssociates">Assign Owners</a>
		</div>
	</div>
	<div v-if="!loadingData && hasData">
		<div class="investments-container scrollable-sidenav">
			<template v-for="investment in investments" :key="investment.company_id">
				<investment-owner :investment="investment" v-on:edit="onEditOwners"/>
			</template>
		</div>
		<edit-associates-modal @close="closeEditAssociatesModal" ref="editAssociatesModal"></edit-associates-modal>
	</div>
	<div v-if="!loadingData && !hasData">
		<!-- NO DATA TODO -->
	</div>
	<ol class="list-unstyled" v-if="loadingData">
		<li class="ps-3 pt-1 pb-1 my-3" v-for="index in 8" :key="index">
			<div class="d-flex justify-content-between my-1 placeholder-wave" style="height: 23px;">
				<span class="placeholder col-7"></span>
				<span class="placeholder col-3"></span>
			</div>
			<div class="d-flex justify-content-between my-1 placeholder-wave">
				<span class="placeholder col-4"></span>
			</div>
			<div class="d-flex justify-content-between my-1 placeholder-wave">
				<span class="placeholder col-5"></span>
			</div>
		</li>
	</ol>
</template>

<script>

import EditAssociatesModal from "@/views/investments/companyInvestment/EditAssociatesModal";
import InvestmentOwner from "@/views/dataCollection/InvestmentOwner";
import {InvestmentsApi} from "@/api/investment_api";

export default {
	name: "InvestmentOwners",

	components: {
		EditAssociatesModal,
		InvestmentOwner
	},

	data() {
		return {
			investments: [],
			loadingData: true
		}
	},
	computed: {
		hasData() {
			return this.investments.length > 0
		}
	},
	mounted() {
		this.loadInvestments()
	},

	methods: {
		closeEditAssociatesModal(investmentId) {
			if(investmentId) {
				this.loadInvestments()
			}
			this.$refs.editAssociatesModal.hide()
		},
		showEditAssociates() {
			this.$refs.editAssociatesModal.show(null)
		},
		loadInvestments() {
			this.loadingData = true
			InvestmentsApi.getInvestmentsUsers().then(investments => {
				this.investments = investments.data
			}).finally(() => {
				this.loadingData = false
			})
		},
		onEditOwners(investment) {
			console.log(`investment clicked is ${investment}`)
			this.$refs.editAssociatesModal.show(investment.company_id)
		}
	}

}
</script>

<style lang="scss" scoped>
</style>