<template>
  <div class="row gx-lg-5 gx-md-0">
    <div class="col-lg-9 col-md-12">
      <section>
        <investments-chart :fund-data="fundsData" :data-loading="loadingFunds"></investments-chart>
      </section>
			<section class="mt-5" v-if="!loadingFunds">
				<div class="d-flex justify-content-between">
					<h3>Fund Performance</h3>
					<p class="text-alternate">As of <format-date :date="fundDataAsOf" to-format="MM/dd/yyyy"/></p>
				</div>
				<!-- pill-control
					:nav-options="[{name: 'All', value: null}, {name: 'Current', value: 'current'}, {name: 'Exited', value: 'exited'}]"
					@pill-nav-clicked="pillNavClicked"/ -->
				<EasyDataTable
					:alternating="true"
					:headers="fundTableHeadings"
					table-class-name="custom-table"
					:rows-per-page="rows_per_page"
					:hide-footer="hideFooter"
					:items="fundTableItems">
					<template #item-fund="{fund, fundId}">
						<span><router-link :to="{name: 'FundInvestment', params: {fundId}}">{{fund}}</router-link></span>
					</template>
				</EasyDataTable>
			</section>
				
			<section v-if="loadingFunds">
				<TablePlaceholder/>
			</section>
    </div>
    <div class="col-3 d-md-none d-lg-block border-start">
      <investments-sidebar
				sidebar-title="Active Investments"
				:investment-data="sidebarInvestmentData"/>
    </div>
  </div>
</template>

<script type="text/javascript">
  import InvestmentsChart from './InvestmentsChart'
	import InvestmentsSidebar from './InvestmentsSidebar'
	// put back when we have filtering by fund status import PillControl from '@/components/controls/PillControl'
  import {formatDollars} from "@/utils/currency_utils";
	import {formatPercentage} from "@/utils/percent_utils";
	import FormatDate from '@/views/utils/FormatDate'
  import FundDataService from "@/firebaseServices/FundDataService";
	import {formatNumber} from "@/utils/number_utils";
	import {FundsApi} from '@/api/funds_api'
	import {DateTime} from 'luxon'
	import TablePlaceholder from '../skeletonLoaders/TablePlaceholder.vue';

	export default {
		name: 'InvestmentsView',
		inheritAttrs: false,

		mixins: [],

		components: {
			InvestmentsChart,
			InvestmentsSidebar,
			//PillControl,
			FormatDate,
			TablePlaceholder
		},

		filters: {
		},

		provide: function() {
			return {
			}
		},

		inject: [],

		props: [],

		setup() {
		},

		data() {
			return {
				fundTableHeadings: [
					{text: 'Fund', value: 'fund'},
					{text: 'Invested', value: 'invested'},
					{text: 'Fees', value: 'fees'},
					{text: 'Expenses', value: 'expenses'},
					{text: 'Available', value: 'available'},
					{text: 'Gross IRR', value: 'grossIRR'},
					{text: 'Net IRR', value: 'netIRR'},
					{text: 'Gross MOIC', value: 'grossMOIC'},
					{text: 'Net MOIC', value: 'netMOIC'}
				],

				fundsData: [],
				sumRows: [],
				fundDataAsOf: null,
				loadingFunds: false,
				activeInvestments: [],
				rows_per_page: 25
			}
		},

		computed: {
			asOfDateString() {
				return this.fundDataAsOf || ''
			},

			fundTableItems() {
				const rows = []
				this.fundsData.forEach((fund) => {
					this.fundDataAsOf = DateTime.fromISO(fund.asOf).toJSDate()
					rows.push({
						fundId: fund.id,
						fund: fund.name,
						invested: formatDollars(fund.funds_data?.Invested, {scale: 'mm'}),
						fees: formatDollars(fund.funds_data?.Fees, {scale: 'mm'}),
						expenses: formatDollars(fund.funds_data?.Expenses, {scale: 'mm'}),
						available: formatDollars(fund.funds_data?.Available, {scale: 'mm'}),
						grossIRR: fund.fund_performance ? formatPercentage(fund.fund_performance.grossIRR) : '-',
						netIRR: fund.fund_performance ? formatPercentage(fund.fund_performance.netIRR ) : '-',
						grossMOIC: fund.fund_performance?.grossMOIC ? formatNumber(fund.fund_performance?.grossMOIC) : '-',
						netMOIC: fund.fund_performance?.netMOIC ? formatNumber(fund.fund_performance?.netMOIC) : '-'
					})
				})

				return rows
			},

			sidebarInvestmentData() {
				return this.activeInvestments.map(inv => ({
					id: inv.id,
					name: inv.name,
					overview: inv.overview,
					mostRecentFinancials: inv.mostRecentFinancials
				}))
			},

			hideFooter() {
				return this.fundTableItems.length <= this.rows_per_page
			}
		},

		watch: {

		},

		created() {
		},

		mounted() {
			return Promise.all([
				this.loadFundTable(),
				this.loadActiveInvestments()
			])
		},

		methods: {
			loadActiveInvestments() {
				console.log('loadActiveInvestments')
				return FundsApi.getFundInvestments({status: 'Active', limit: 1000, offset: 0})
					.then(resp => {
						console.log('loadActiveInvestments', resp)
						resp.data.forEach(inv => {
							if(inv.financials) {
								inv.financials = inv.financials.sort((fin1, fin2) => DateTime.fromISO(fin1.date) < DateTime.fromISO(fin2.date))
								inv.mostRecentFinancials = inv.financials[inv.financials.length-1]
							}
						})
						this.activeInvestments = resp.data
						return resp.data.investments
					})
			},

			loadFundTable() {
				this.loadingFunds = true
				return FundDataService.loadFunds().then(funds => {
					this.fundsData = funds
				}).catch(e => {
					console.error("error trying to fund performance data", e)
				})
				.finally(() => {
					this.loadingFunds = false
				})
			},
			pillNavClicked() {
			}
		}
	}
</script>

<style lang="scss">
</style>
