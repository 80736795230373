<template>
	<div class="pt-4">
		<div>
			<h2>Saved Reports</h2>
		</div>
		<ul id="companyInvestmentTabNav" class="list-inline" role="tablist">
			<li class="list-inline-item mb-2" role="presentation">
				<button class="btn pill active" id="all" data-bs-toggle="tab">All</button>
			</li>
			<li class="list-inline-item mb-2" role="presentation">
				<button class="btn pill" id="fundV" data-bs-toggle="tab">Fund V</button>
			</li>
			<li class="list-inline-item mb-2" role="presentation">
				<button class="btn pill" id="fundIV" data-bs-toggle="tab">Fund IV</button>
			</li>
			<li class="list-inline-item mb-2" role="presentation">
				<button class="btn pill" id="fundIII" data-bs-toggle="tab">Fund III</button>
			</li>
			<li class="list-inline-item mb-2" role="presentation">
				<button class="btn pill" id="fundII" data-bs-toggle="tab">Fund II</button>
			</li>
			<li class="list-inline-item mb-2" role="presentation">
				<button class="btn pill" id="fundIIA" data-bs-toggle="tab">Fund IIA</button>
			</li>
			<li class="list-inline-item mb-2" role="presentation">
				<button class="btn pill" id="fundI" data-bs-toggle="tab">Fund I</button>
			</li>
			<li class="list-inline-item mb-2" role="presentation">
				<button class="btn pill" id="fundIA" data-bs-toggle="tab">Fund I-Annex</button>
			</li>
		</ul>
		<div class="container p-0">
			<div class="grid" v-if="!loading">
				<div class="item my-2" v-for="(savedReport, index) in savedReportsData" :key="index">
					<div class="d-flex justify-space-between align-items-center">
						<img class="report-icon" :src="getReportIcon(savedReport.color)" :alt="savedReport.color">
						<span class="mx-2">{{ savedReport.title }}</span>
					</div>
					<div class="kebab-button">
						<span></span>
						<span></span>
						<span></span>
					</div>
				</div>
			</div>

			<div v-else>
				<div class="placeholder-wave">
					<div class="row mb-4" v-for="rowIndex in 5" :key="rowIndex">
						<div class="col-4 d-flex align-items-center justify-content-around placeholder-wave" v-for="colIndex in 3" :key="colIndex">
							<div class="placeholder circle"></div>
							<div class="placeholder long-rect"></div>
							<div class="placeholder short-rect"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">

	export default {
		name: 'SavedReports',
		inheritAttrs: false,
		
		mixins: [],
		
		components: {
		},
		
		props: {},
		
		data() {
			return {
				savedReportsData: [],
				loading: true
			}
		},
		
		computed: {
			
		},
		
		mounted() {
			this.loadReportsData()
		},
		
		methods: {
			getReportIcon(color) {
				return require(`@/assets/report_icons/${color}.svg`)
			},
			loadReportsData() {
				this.loading = true
				setTimeout(() => this.loading = false, 1200);
				this.savedReportsData = [
					{
						title: 'Detailed Investment Performance ',
						color: 'blue',
					},
					{
						title: 'Investments',
						color: 'green',
					},
					{
						title: 'YTD FUND IV',
						color: 'green',
					},
					{
						title: 'Fund III  EVAL IC',
						color: 'grey',
					},
					{
						title: 'FIV MOIC, IRR, Comp U, Ov.',
						color: 'blue',
					},
					{
						title: 'Fund II Acquisitions IRR',
						color: 'purple',
					},
					{
						title: 'LP Summary Fund IV',
						color: 'blue',
					},
					{
						title: 'YTD EBIDTA IV',
						color: 'green',
					},
					{
						title: 'YTD FUND IV',
						color: 'green',
					},
					{
						title: 'Fund IV  Net LP IRR, DPI',
						color: 'purple',
					},
					{
						title: 'YTD Fund III IRR, MOIC',
						color: 'blue',
					},
					{
						title: 'Fund II Acquisitions IRR',
						color: 'blue',
					},
					{
						title: 'Invesment C, D, Q; ESG',
						color: 'blue',
					},
					{
						title: 'YTD Fund III IRR, MOIC',
						color: 'blue',
					},
					{
						title: 'Fund II Acquisitions IRR',
						color: 'blue',
					},
				]
			}
		}
	}
</script>

<style lang="scss" scoped>
@import '../../styles/custom-variables.scss';
h5 {
	color: $darkblue;
}
.grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.item {
    display: flex;
    align-items: center;
    position: relative;
	justify-content: space-between;
	margin-right: 20px;
}
.report-icon {
	width: 36px;
	height: 36px;
}
.kebab-button {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 10px;
    height: 24px;
    cursor: pointer;
}

.kebab-button span {
    display: block;
    width: 4px;
    height: 4px;
    background-color: #878787;
    border-radius: 50%;
}
.circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: $zebra;
}

/* Long Rectangle Placeholder */
.long-rect {
  width: 70%;
  height: 30px;
  background-color: $zebra;
}

/* Short Rectangle Placeholder */
.short-rect {
  width: 3%;
  height: 30px;
  background-color: $zebra;
}
</style>