<template>
	<heading-bookmarks :headings="getHeadings()" />
	<div class="row gx-5">
		<div class="col-6">
			<div>
				<div class="d-flex justify-content-between mb-3">
					<h3 id="companyAttributes">Company Attributes</h3>
					<p class="as-of-date">As Of <format-date :date="asOf"/></p>
				</div>
				<dl class="row dl-rev-striped company-attributes ms-0" v-if="dataLoaded">
					<dt class="col-9 "><span>CEO</span></dt>
					<dd class="col-3 ">{{findCompanyAttribute('CEO')}}</dd>
					<dt class="col-9 "><span>CFO</span></dt>
					<dd class="col-3 ">{{findCompanyAttribute('CFO')}}</dd>
					<dt class="col-9 "><span>Seller Name (if Financial)</span></dt>
					<dd class="col-3 ">{{findCompanyAttribute('Seller Name (If Financial)')}}</dd>
					<dt class="col-9 "><span>Lead Bankers</span></dt>
					<dd class="col-3 ">{{findCompanyAttribute('Lead Ibanker(s) at Entry (Name of Person(s)')}}</dd>
					<dt class="col-9 "><span>Industry</span></dt>
					<dd class="col-3 ">{{findCompanyAttribute('Industry')}}</dd>
					<dt class="col-9 "><span>Company Description</span></dt>
					<dd class="col-3 ">{{findCompanyAttribute('Company Description')}}</dd>
					<dt class="col-9 "><span>Date of Initial Investment</span></dt>
					<dd class="col-3 "><format-date :date="findCompanyAttributeDate('Date of Initial Investment')" :utc-time="true"/></dd>
					<dt class="col-9 "><span>Company Description (Short)</span></dt>
					<dd class="col-3 ">{{findCompanyAttribute('Company Description (Short)')}}</dd>
					<dt class="col-9 "><span>Ownership % At Entry </span></dt>
					<dd class="col-3 "><format-percent :number="findCompanyAttribute('Ownership at entry (%)')" :precision="1"/></dd>
					<dt class="col-9 "><span>Location</span></dt>
					<dd class="col-3 ">{{findCompanyAttribute('Location (headquarters)')}}</dd>
					<dt class="col-9 "><span>HQ city, state</span></dt>
					<dd class="col-3 ">{{findCompanyAttribute('HQ city, state, country')}}</dd>
					<dt class="col-9 "><span>Legal form of company</span></dt>
					<dd class="col-3 ">{{findCompanyAttribute('Legal form of company')}}</dd>
					<dt class="col-9 "><span>Follow-on Equity, incl invested capital in add-ons</span></dt>
					<dd class="col-3 "><format-currency :number="findCompanyAttribute('Follow-on equity, incl. invested capital in add-ons')"/></dd>
					<dt class="col-9 "><span>Sourcing</span></dt>
					<dd class="col-3 ">{{findCompanyAttribute('Sourcing')}}</dd>
					<dt class="col-9 "><span>Attribution</span></dt>
					<dd class="col-3 ">{{findCompanyAttribute('Attribution')}}</dd>
					<dt class="col-9 "><span># Board Seats</span></dt>
					<dd class="col-3 ">{{findCompanyAttribute('# of board seats at the company')}}</dd>
					<dt class="col-9 "><span>Board members from fund</span></dt>
					<dd class="col-3 ">{{findCompanyAttribute('Board members from the fund')}}</dd>
					<dt class="col-9 "><span>Prior Ownership</span></dt>
					<dd class="col-3 ">{{findCompanyAttribute('Prior Ownership')}}</dd>
					<dt class="col-9 "><span>Deal type</span></dt>
					<dd class="col-3 ">{{findCompanyAttribute('Deal type')}}</dd>
					<dt class="col-9 "><span>Investment thesis</span></dt>
					<dd class="col-3 ">{{findCompanyAttribute('Investment thesis')}}</dd>
					<dt class="col-9 "><span>Number of GPs co-invested</span></dt>
					<dd class="col-3 ">{{findCompanyAttribute('Number of GPs co-invested')}}</dd>
					<dt class="col-9 "><span>Number of LPs co-invested</span></dt>
					<dd class="col-3 ">{{findCompanyAttribute('Number of LPs co-invested')}}</dd>
					<dt class="col-9 "><span>Lead/Co-lead</span></dt>
					<dd class="col-3 ">{{findCompanyAttribute('Lead/Co-lead')}}</dd>
					<dt class="col-9 "><span>Deal Team</span></dt>
					<dd class="col-3 ">{{findCompanyAttribute('Deal Team')}}</dd>
					<dt class="col-9 "><span>Covenant status</span></dt>
					<dd class="col-3 ">{{findCompanyAttribute('Covenant status')}}</dd>
					<dt class="col-9 "><span>Comment on covenant status</span></dt>
					<dd class="col-3 ">{{findCompanyAttribute('Comment on covenant status')}}</dd>
					<dt class="col-9 "><span>Security type</span></dt>
					<dd class="col-3 ">{{findCompanyAttribute('Security type')}}</dd>
					<dt class="col-9 "><span>Seller type</span></dt>
					<dd class="col-3 ">{{findCompanyAttribute('Seller type')}}</dd>
					<dt class="col-9 "><span>Mgmt team transition flag</span></dt>
					<dd class="col-3 ">{{findCompanyAttribute('Mgmt team transition flag (Y/N)')}}</dd>
					<dt class="col-9 "><span>Source/investment bank</span></dt>
					<dd class="col-3 ">{{findCompanyAttribute('Source / investment bank')}}</dd>
					<dt class="col-9 "><span>QOE/tax firm used for DD</span></dt>
					<dd class="col-3 ">{{findCompanyAttribute('Qoe / tax firm used for dd')}}</dd>
					<dt class="col-9 "><span>Current auditors</span></dt>
					<dd class="col-3 ">{{findCompanyAttribute('current auditors')}}</dd>
					<dt class="col-9 "><span>Legal advisor</span></dt>
					<dd class="col-3 ">{{findCompanyAttribute('legal advisor used')}}</dd>
					<dt class="col-9 "><span>Seller financing flag</span></dt>
					<dd class="col-3 ">{{findCompanyAttribute('Seller financing flag (Y / N)')}}</dd>
					<dt class="col-9 "><span>RWI insurance flag</span></dt>
					<dd class="col-3 ">{{findCompanyAttribute('RWI insurance flag (Y / N)')}}</dd>
					<dt class="col-9 "><span>RWI provider used</span></dt>
					<dd class="col-3 ">{{findCompanyAttribute('rwi provider used')}}</dd>
					<dt class="col-9 "><span>Indemnification cap</span></dt>
					<dd class="col-3 ">{{findCompanyAttribute('Indemnification cap')}}</dd>
					<dt class="col-9 "><span>General survival period</span></dt>
					<dd class="col-3 ">{{findCompanyAttribute('general survival period')}}</dd>
					<dt class="col-9 "><span>Basket</span></dt>
					<dd class="col-3 ">{{findCompanyAttribute('Basket')}}</dd>
					<dt class="col-9 "><span>Escrow holdback</span></dt>
					<dd class="col-3 ">{{findCompanyAttribute('Escrow/ Holdback')}}</dd>
					<dt class="col-9 "><span>Escrow/holdback period</span></dt>
					<dd class="col-3 ">{{findCompanyAttribute('Escrow/Holdback Period')}}</dd>
				</dl>
			</div>
			<div class="mt-5" >
				<h3 class="mb-3" id="valuationInformation">Valuation Information</h3>
				<dl class="row ms-0 dl-rev-striped company-attributes">
					<dt class="col-9 "><span>Valuation 3 Quarters Prior</span></dt>
					<dd class="col-3 "><format-currency :number="findValuationInfo('Valuation 3 Quarters Prior')"/></dd>
					<dt class="col-9 "><span>Valuation 2 Quarters Prior</span></dt>
					<dd class="col-3 "><format-currency :number="findValuationInfo('Valuation 2 Quarters Prior')"/></dd>
					<dt class="col-9 "><span>Valuation 1 Quarter Prior</span></dt>
					<dd class="col-3 "><format-currency :number="findValuationInfo('Valuation 1 Quarters Prior')"/></dd>
					<dt class="col-9 "><span>Valuation at Exit</span></dt>
					<dd class="col-3 "><format-currency :number="findValuationInfo('Valuation 1 Quarter \nValuation at Exit')"/></dd>
					<dt class="col-9 "><span>Valuation Change Exit vs 3 Quarters Prior</span></dt>
					<dd class="col-3 "><format-percent :number="findValuationInfo('Valuation Change Exit vs 3 Quarters Prior')"/></dd>
					<dt class="col-9 "><span>Valuation 1 Quarter-End Date/Exit Date</span></dt>
					<dd class="col-3 "><format-percent :number="findValuationInfo('Valuation 1 Quarter-End Date/Exit Date')"/></dd>
				</dl>
			</div>
			<div class="mt-5" >
				<h3 class="mb-3" id="valueCreation">Value Creation</h3>
				<dl class="row ms-0 dl-rev-striped company-attributes">
					<dt class="col-9 "><span>Debt Paydown / Other</span></dt>
					<dd class="col-3 "><format-percent :number="findValueCreation('Debt Paydown / Other')"/></dd>
					<dt class="col-9 "><span>EBITDA Growth</span></dt>
					<dd class="col-3 "><format-percent :number="findValueCreation('EBITDA Growth')"/></dd>
					<dt class="col-9 "><span>Multiple Expansion</span></dt>
					<dd class="col-3 "><format-percent :number="findValueCreation('Multiple Expansion')"/></dd>
					<dt class="col-9 "><span>Total</span></dt>
					<dd class="col-3 "><format-percent :number="findValueCreation('Total')"/></dd>
					<dt class="col-9 "><span>Leverage posture</span></dt>
					<dd class="col-3 "><span>{{ findValueCreation('Leverage Posture') }}</span></dd>
				</dl>
			</div>
			<div class="mt-5" >
				<h3 id="investmentRationale">Investment Rationale</h3>
				<div class="mt-3 card" v-if="dataLoaded">
					<span class="card-body multiline text" v-if="hasRationale">{{rationaleText}}</span>
					<span class="card-body text-alternate" v-else>n/a</span>
				</div>
			</div>
			<div class="mt-5">
				<h3 id="valueAdd">Value-Add Proposition at Entry</h3>
				<div class="mt-3 card" v-if="dataLoaded">
					<span class="card-body multiline text" v-if="hasValueAdd">{{valueAddText}}</span>
					<span class="card-body text-alternate" v-else>n/a</span>
				</div>
			</div>
		</div>
		<div class="col-6">
			<div>
				<div class="d-flex justify-content-between mb-3">
					<h3 id="entryFinancials">Financial Information (At Entry)</h3>
					<p class="as-of-date">As Of <format-date :date="findFinancialData('As of :')" :utc-time="true"/></p>
				</div>
				<dl class="row ms-0 dl-rev-striped company-attributes">
					<dt class="col-9 "><span>LTM Revenue</span></dt>
					<dd class="col-3 "><format-currency :number="findFinancialData('LTM Revenue')"/></dd>
					<dt class="col-9 "><span>LTM EBITDA</span></dt>
					<dd class="col-3 "><format-currency :number="findFinancialData('LTM EBITDA')" /></dd>
					<dt class="col-9 "><span>Enterprise Value</span></dt>
					<dd class="col-3 "><format-currency :number="findFinancialData('Enterprise Value')" /></dd>
					<dt class="col-9 "><span>Funds Equity Invested</span></dt>
					<dd class="col-3 "><format-currency :number="findFinancialData('Bertram Funds Equity Invested')"/></dd>
					<dt class="col-9 "><span>Funds Sub Debt Invested</span></dt>
					<dd class="col-3 "><format-currency :number="findFinancialData('Bertram Funds Sub Debt Invested')" /></dd>
					<dt class="col-9 "><span>Net Debt</span></dt>
					<dd class="col-3 "><format-currency :number="findFinancialData('Net Debt')" /></dd>
					<dt class="col-9 "><span>Purchase Multiple</span></dt>
					<dd class="col-3 "><format-number :number="findAtEntryAttribute('Purchase Multiple')"></format-number></dd>
					<dt class="col-9 "><span>Equity Value</span></dt>
					<dd class="col-3 "><format-currency :number="findFinancialData('Equity Value')" /></dd>
					<dt class="col-9 "><span>Senior Debt</span></dt>
					<dd class="col-3 "><format-currency :number="findAtEntryAttribute('Senior Debt')" /></dd>
					<dt class="col-9 "><span>Unitranche Debt</span></dt>
					<dd class="col-3 "><format-currency :number="findAtEntryAttribute('Unitranche Debt')" /></dd>
					<dt class="col-9 "><span>Subordinated Debt</span></dt>
					<dd class="col-3 "><format-currency :number="findAtEntryAttribute('Subordinated Debt')" :precision="1"/></dd>
					<dt class="col-9 "><span>Total Leverage</span></dt>
					<dd class="col-3 "><format-currency :number="findFinancialData('Total Leverage')" /></dd>
					<dt class="col-9 "><span>Rollover Equity %</span></dt>
					<dd class="col-3 "><format-percent :number="findAtEntryAttribute('Rollover Equity %')" :precision="1" /></dd>
					<dt class="col-9 "><span>Rollover Equity $</span></dt>
					<dd class="col-3 "><format-currency :number="findAtEntryAttribute('Rollover Equity $')" /></dd>
					<dt class="col-9 "><span>Co Invest Equity $</span></dt>
					<dd class="col-3 "><format-currency :number="findAtEntryAttribute('Co Invest Equity $')" /></dd>
					<dt class="col-9 "><span>Cash</span></dt>
					<dd class="col-3 "><format-currency :number="findFinancialData('Cash')" /></dd>
					<dt class="col-9 "><span>Buyer&apos;s Transaction Expenses</span></dt>
					<dd class="col-3 "><format-currency :number="findAtEntryAttribute('Buyer\'s Transaction Expenses')" /></dd>
				</dl>
			</div>
			<div v-show="isExited">
				<div class="mt-5 d-flex justify-content-between mb-3">
					<h3 id="exitFinancials">Financial Information (At Exit)</h3>
					<p class="as-of-date">As Of <format-date :date="findAtExitAttribute('As of :')" :utc-time="true"/></p>
				</div>
				<dl class="row ms-0 dl-rev-striped company-attributes">
					<dt class="col-9"><span>Sell Side Banker</span></dt>
					<dd class="col-3"><span>{{ findAtExitAttribute('Sell Side Banker') }}</span></dd>
					<dt class="col-9"><span>Lead Ibanker(s)</span></dt>
					<dd class="col-3"><span>{{ findAtExitAttribute('Lead Ibanker(s) (Name of Person(s)') }}</span></dd>
					<dt class="col-9"><span>Sell Side I Bank Fees Structure</span></dt>
					<dd class="col-3"><span>{{ findAtExitAttribute('Sell Side I Bank Fees Structure') }}</span></dd>
					<dt class="col-9"><span>Sell-Side Key Man Provision</span></dt>
					<dd class="col-3"><span>{{ findAtExitAttribute('Sell-Side Key Man Provision') }}</span></dd>
					<dt class="col-9"><span>Sell-Side Retainer</span></dt>
					<dd class="col-3"><span>{{ findAtExitAttribute('Sell-Side Retainer') }}</span></dd>
					<dt class="col-9"><span>Sell-Side IBank Aggregate Expense Limit</span></dt>
					<dd class="col-3"><format-currency :number="findAtExitAttribute('Sell-Side IBank Aggregate Expense Limit')" /></dd>
					<dt class="col-9"><span>Sell-Side IBank Tail</span></dt>
					<dd class="col-3"><format-currency :number="findAtExitAttribute('Sell-Side IBank Tail')" /></dd>
					<dt class="col-9"><span>Sell-Side IBank Fees Paid</span></dt>
					<dd class="col-3"><format-currency :number="findAtExitAttribute('Sell-Side IBank Fees Paid')" /></dd>
					<dt class="col-9"><span>Sell-Side IBank Additional Comments/Terms</span></dt>
					<dd class="col-3"><span>{{ findAtExitAttribute('Sell-Side IBank Additional Comments/Terms') }}</span></dd>
					<dt class="col-9"><span>Buyer Name</span></dt>
					<dd class="col-3"><span>{{ findAtExitAttribute('Buyer Name') }}</span></dd>
					<dt class="col-9"><span>CEO (At Exit)</span></dt>
					<dd class="col-3"><span>{{ findAtExitAttribute('CEO (At Exit)') }}</span></dd>
					<dt class="col-9"><span>CFO (At Exit)</span></dt>
					<dd class="col-3"><span>{{ findAtExitAttribute('CFO (At Exit)') }}</span></dd>
					<dt class="col-9"><span>Capital Markets Provider (If Applicable)</span></dt>
					<dd class="col-3"><span>{{ findAtExitAttribute('Capital Markets Provider (If Applicable) ') }}</span></dd>
					<dt class="col-9"><span>Valuation Firm</span></dt>
					<dd class="col-3"><span>{{ findAtExitAttribute('Valuation Firm') }}</span></dd>
					<dt class="col-9"><span>Company Accounting Firm</span></dt>
					<dd class="col-3"><span>{{ findAtExitAttribute('Company Accounting Firm') }}</span></dd>
					<dt class="col-9"><span>Company Counsel</span></dt>
					<dd class="col-3"><span>{{ findAtExitAttribute('Company Counsel') }}</span></dd>
					<dt class="col-9"><span>LTM Revenue</span></dt>
					<dd class="col-3"><format-currency :number="findAtExitAttribute('LTM Revenue')" /></dd>
					<dt class="col-9"><span>LTM EBITDA</span></dt>
					<dd class="col-3"><format-currency :number="findAtExitAttribute('LTM EBITDA')" /></dd>
					<dt class="col-9"><span>Enterprise Value</span></dt>
					<dd class="col-3"><format-currency :number="findAtExitAttribute('Enterprise Value')" /></dd>
					<dt class="col-9"><span>Bertram Funds Equity Invested</span></dt>
					<dd class="col-3"><format-currency :number="findAtExitAttribute('Bertram Funds Equity Invested')" /></dd>
					<dt class="col-9"><span>Bertram Funds Sub Debt Invested</span></dt>
					<dd class="col-3"><format-currency :number="findAtExitAttribute('Bertram Funds Sub Debt Invested')" /></dd>
					<dt class="col-9"><span>Net Debt</span></dt>
					<dd class="col-3"><format-currency :number="findAtExitAttribute('Net Debt')" /></dd>
					<dt class="col-9"><span>Exit Multiple</span></dt>
					<dd class="col-3"><format-number :number="findAtExitAttribute('Exit Multiple')" /></dd>
					<dt class="col-9"><span>Total FMV (Debt + Equity)</span></dt>
					<dd class="col-3"><format-currency :number="findAtExitAttribute('Total FMV (Debt + Equity)')" /></dd>
					<dt class="col-9"><span>Equity FMV</span></dt>
					<dd class="col-3"><format-currency :number="findAtExitAttribute('Equity FMV')" /></dd>
					<dt class="col-9"><span>Debt FMV</span></dt>
					<dd class="col-3"><format-currency :number="findAtExitAttribute('Debt FMV')" /></dd>
					<dt class="col-9"><span>Equity & Debt Gain / (Loss)</span></dt>
					<dd class="col-3"><format-currency :number="findAtExitAttribute('Equity & Debt Gain / (Loss)')" /></dd>
					<dt class="col-9"><span>Gross MOIC (Equity)</span></dt>
					<dd class="col-3"><format-number :number="findAtExitAttribute('Gross MOIC (Equity)')" /></dd>
					<dt class="col-9"><span>Gross MOIC (Debt)</span></dt>
					<dd class="col-3"><format-number :number="findAtExitAttribute('Gross MOIC (Debt)')" /></dd>
					<dt class="col-9"><span>Gross MOIC (Equity and Debt)</span></dt>
					<dd class="col-3"><format-number :number="findAtExitAttribute('Gross MOIC (Equity and Debt)')" /></dd>
					<dt class="col-9"><span>Transaction & Advisory Fees Paid to Mgmt Co not used to Offset Mgmt Fees</span></dt>
					<dd class="col-3"><format-currency :number="findAtExitAttribute('Transaction & Advisory Fees Paid to Mgmt Co not used to Offset Mgmt Fees')" /></dd>
					<dt class="col-9"><span>Transaction & Advisory Fees Applied to Mgmt Fee Offsets</span></dt>
					<dd class="col-3"><format-currency :number="findAtExitAttribute('Transaction & Advisory Fees Applied to Mgmt Fee Offsets')" /></dd>
					<dt class="col-9"><span>Total FMV + Mgmt Fee Offsets</span></dt>
					<dd class="col-3"><format-currency :number="findAtExitAttribute('Total FMV + Mgmt Fee Offsets')" /></dd>
					<dt class="col-9"><span>Gross IRR</span></dt>
					<dd class="col-3"><format-percent :number="findAtExitAttribute('Gross IRR')" /></dd>
					<dt class="col-9"><span>Buyer Type</span></dt>
					<dd class="col-3"><span>{{ findAtExitAttribute('Buyer Type') }}</span></dd>
	
				</dl>
			</div>
			<div class="mt-5">
				<h3 class="mb-3" id="valueDrivers">Prior Fund Value Drivers</h3>
				<dl class="row ms-0 dl-rev-striped company-attributes">
					<dt class="col-9"><span>Replace Management</span></dt>
					<dd class="col-3"><span>{{ findValueDriver('Replace Management') }}</span></dd>
					<dt class="col-9"><span>Improved Systems</span></dt>
					<dd class="col-3"><span>{{ findValueDriver('Improved Systems') }}</span></dd>
					<dt class="col-9"><span>New Product Lines</span></dt>
					<dd class="col-3"><span>{{ findValueDriver('New Product Lines') }}</span></dd>
					<dt class="col-9"><span>Organic Growth</span></dt>
					<dd class="col-3"><span>{{ findValueDriver('Organic Growth') }}</span></dd>
					<dt class="col-9"><span>Cost Reductions</span></dt>
					<dd class="col-3"><span>{{ findValueDriver('Cost Reductions') }}</span></dd>
					<dt class="col-9"><span>Add-On Acquisitions</span></dt>
					<dd class="col-3"><span>{{ findValueDriver('Add-on \nAcquisitions') }}</span></dd>
				</dl>
			</div>
			<div class="mt-5">
				<h3 class="mb-3" id="fundAllocation">Fund Allocation</h3>
				<dl class="row ms-0 dl-rev-striped company-attributes" v-if="dataLoaded">
					<dt class="col-9"><span>Allocated Funds</span></dt>
					<dd class="col-3"><span>{{ findFundAllocation('Allocated Funds') }}</span></dd>
					<dt class="mt-4 col-12"><span><i>Ownership %</i></span></dt>
					<template v-for="row in fundAllocationFunds" :key="row.name">
						<dt class="col-9"><span>{{ row.name }}</span></dt>
						<dd class="col-3" v-if="row.type == 'percent'"><format-percent :number="row.value == '' ? null : row.value" if-null="N/A" /></dd>
						<dd class="col-3" v-if="row.type == 'string'">{{row.value}}</dd>
					</template>
				</dl>
			</div>
			<div class="mt-5">
				<h3 class="mb-3" id="companyOwnership">Underlying Portfolio Company Ownership</h3>
				<dl class="row ms-0 dl-rev-striped company-attributes" v-if="dataLoaded">
					<dt class="col-9"><span>Allocated Funds</span></dt>
					<dd class="col-3"><span>{{ findCompanyOwnership('Allocated Funds') }}</span></dd>
					<dt class="col-9"><span>Total Underlying Portfolio Company Ownership</span></dt>
					<dd class="col-3"><format-percent :number="findCompanyOwnership('Total Underlying Portfolio Company Ownership')" /></dd>
					<dt class="mt-4 col-12"><span><i>Underlying Fund Ownership % in Portfolio Company</i></span></dt>
					<template v-for="row in companyOwnershipFunds" :key="row.name">
						<dt class="col-9"><span>{{ row.name }}</span></dt>
						<dd class="col-3" v-if="row.type == 'percent'"><format-percent :number="row.value == '' ? null : row.value" if-null="N/A" /></dd>
						<dd class="col-3" v-if="row.type == 'string'">{{row.value}}</dd>
					</template>
				</dl>
			</div>
			
		</div>
	</div>
</template>

<script type="text/javascript">
	import FormatDate from '@/views/utils/FormatDate'
	import {DateTime} from 'luxon'
	import FormatCurrency from '@/views/utils/FormatCurrency'
	import FormatPercent from '@/views/utils/FormatPercent'
	import FormatNumber from '@/views/utils/FormatNumber'
	import HeadingBookmarks from './HeadingBookmarks.vue'

	export default {
		inheritAttrs: false,
    name: 'CompanyOverview',

		mixins: [],

		components: {
			FormatDate,
			FormatCurrency,
			FormatPercent,
			FormatNumber,
			HeadingBookmarks
		},

		filters: {
		},

		provide: function() {
			return {
			}
		},

		inject: [],

		props: {
			sourceData: {
				type: Object,
				required: true
			}
		},

		data() {
			return {
			}
		},

		computed: {
			asOf() {
				if(this.dataLoaded) {
					return DateTime.fromISO(this.sourceData['asOf']).toJSDate()
				}
				return null
			},

			atEntryAttributes() {
				if(this.dataLoaded) {
					return this.sourceData['Financial Information (At Entry)']
				} else {
					return null
				}
			},

			atEntryKeyMappings() {
				return this.keyMappings(this.atEntryAttributes)
			},

			companyAttributes() {
				if(this.dataLoaded) {
					return this.sourceData['Company Attributes']
				} else {
					return null
				}
			},

			companyAttributeKeyMappings() {
				return this.keyMappings(this.companyAttributes)
			},

			atExitAttributes() {
				if(this.dataLoaded) {
					return this.sourceData['Financial Information (At Exit)']
				} else {
					return null
				}
			},

			atExitKeyMappings() {
				return this.keyMappings(this.atExitAttributes)
			},

			fundAllocationFunds() {
				let funds = structuredClone(this.sourceData['Bertram Fund Allocation'] ?? {})
				delete funds['Allocated Funds']
				return this.listAllocations(funds)
			},

			companyOwnershipFunds() {
				let funds = structuredClone(this.sourceData['Underlying Portfolio Company Ownership'] ?? {})
				delete funds['Allocated Funds']
				delete funds['Total Underlying Portfolio Company Ownership']
				delete funds['Underlying Fund Ownership % in Portfolio Company']
				return this.listAllocations(funds)
			},

			companyFinancials() {
				if(this.dataLoaded) {
					return this.sourceData['Financial Information (At Entry)']
				} else {
					return null
				}
			},

			companyFinancialsKeyMappings() {
				const mappings = {}
				if(this.dataLoaded) {
					for (const [key, value] of Object.entries(this.companyFinancials)) {
						mappings[key.replace(/[\W_]+/g,"").toLowerCase()] = value
					}
				}
				return mappings
			},

			valueDrivers() {
				if (this.dataLoaded) {
					return this.sourceData['Prior Fund Value Drivers']
				} else {
					return null
				}
			},

			valueDriversKeyMappings() {
				return this.keyMappings(this.valueDrivers)
			},

			valuationInfo() {
				if (this.dataLoaded) {
					return this.sourceData['Valuation Information']
				} else {
					return null
				}
			},

			valuationInfoKeyMappings() {
				return this.keyMappings(this.valuationInfo)
			},

			valueCreation() {
				if (this.dataLoaded) {
					return this.sourceData['Value Creation']
				} else {
					return null
				}
			},

			valueCreationKeyMappings() {
				return this.keyMappings(this.valueCreation)
			},

			fundAllocations() {
				if (this.dataLoaded) {
					return this.sourceData['Bertram Fund Allocation']
				} else {
					return null
				}
			},

			fundAllocationKeyMappings() {
				return this.keyMappings(this.fundAllocations)
			},

			companyOwnerships() {
				if (this.dataLoaded) {
					return this.sourceData['Underlying Portfolio Company Ownership']
				} else {
					return null
				}
			},	

			companyOwnershipKeyMappings() {
				return this.keyMappings(this.companyOwnerships ?? {})
			},

			isExited() {
				return (this.findAtExitAttribute('As of :') != null)
			},

			rationaleText() {
				let rationale = this.sourceData['Investment Rationale']
				return rationale ? rationale.text.join('\n') : ''
			},

			valueAddText() {
				let valueAdd = this.sourceData['Value-Add Proposition at Entry']
				return valueAdd ? valueAdd.text.join('\n') : ''
			},

			hasRationale() {
				return !(String(this.rationaleText).match(/^\s*$/))
			},

			hasValueAdd() {
				return !(String(this.valueAddText).match(/^\s*$/))
			},

			hasOwnerships() {
				return this.companyOwnershipFunds.length > 0
			},	
			
			dataLoaded() {
				return this.sourceData && typeof(this.sourceData['Company Attributes']) != 'undefined' && Object.keys(this.sourceData['Company Attributes']).length > 0
			},
		},

		watch: {

		},

		created() {
		},

		mounted() {
		},

		methods: {
			findCompanyAttributeDate(attr) {
				const val = this.findCompanyAttribute(attr)
				return DateTime.fromISO(val).toJSDate()
			},

			findCompanyAttribute(n) {
				const keys = this.companyAttributeKeyMappings
				const data = this.companyAttributes
				return this.findAttribute(n, keys, data)
			},

			findAtEntryAttribute(n) {
				const keys = this.atEntryKeyMappings
				const data = this.atEntryAttributes
				return this.findAttribute(n, keys, data)
			},

			findAtExitAttribute(n) {
				const keys = this.atExitKeyMappings
				const data = this.atExitAttributes
				return this.findAttribute(n, keys, data)
			},

			findFinancialData(n) {
				const keys = this.companyFinancialsKeyMappings
				const data = this.companyFinancials
				return this.findAttribute(n, keys, data)
			},

			findValueDriver(n) {
				const keys = this.valueDriversKeyMappings
				const data = this.valueDrivers
				return this.findAttribute(n, keys, data)
			},

			findValuationInfo(n) {
				const keys = this.valuationInfoKeyMappings
				const data = this.valuationInfo
				return this.findAttribute(n, keys, data)
			},

			findValueCreation(n) {
				const keys = this.valueCreationKeyMappings
				const data = this.valueCreation
				return this.findAttribute(n, keys, data)
			},

			findFundAllocation(n) {
				const keys = this.fundAllocationKeyMappings
				const data = this.fundAllocations
				return this.findAttribute(n, keys, data)
			},

			findCompanyOwnership(n) {
				const keys = this.companyOwnershipKeyMappings
				const data = this.companyOwnerships
				return this.findAttribute(n, keys, data)
			},

			findAttribute(n, keys, data) {
				let retVal
				if(data) {
					let value = data[n]
					if(typeof(value) == 'undefined') {
						const key = n.replace(/[\W_]+/g,"").toLowerCase()
						value = keys[key]
					}

					retVal = value == '' ? null : value
				}
				return retVal
			},

			keyMappings(data) {
				const mappings = {}
				if(this.dataLoaded) {
					for (const [key, value] of Object.entries(data)) {
						mappings[key.replace(/[\W_]+/g,"").toLowerCase()] = value
					}
				}
				return mappings
			},

			listAllocations(data) {
				const allocations = []
				if(this.dataLoaded) {
					for (const [key, value] of Object.entries(data)) {
						// eslint-disable-next-line use-isnan
						if (isNaN(value) || value != 0) {
							allocations.push({name: key, type: isNaN(value) ? 'string' : 'percent', value: value})
						}
					}
					allocations.sort((a, b) => {
						return a.name.localeCompare(b.name)
					})
				}
				return allocations
			},
			getHeadings() {
				const ids = [
					'companyAttributes',
					'entryFinancials',
					'valuationInformation',
					'exitFinancials',
					'valueCreation',
					'valueDrivers',
					'investmentRationale',
					'fundAllocation',
					'valueAdd',
					'companyOwnership',
				]
				const headings = []
				for (const id of ids) {
					if (id == 'exitFinancials' && !this.isExited) {
						continue
					}
					let obj = {id}
					obj.text = document.getElementById(id)?.textContent
					headings.push(obj)
				}
				return headings
			}
		}
	}
</script>

<style lang="scss">
.multiline {
	white-space: pre-wrap;
}
</style>
