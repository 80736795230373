<template>
	<div>
		<h3>Financial Information (Monthly)</h3>
		<div class="row mt-3">
			<div class="col-12">
				<EasyDataTable
					table-class-name="custom-table no-shadow"
					:rows-per-page="rows_per_page"
					:hide-footer="hideFooter"
					:alternating="true"
					:headers="tableHeadings"
					:items="tableData"/>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import {DateTime} from "luxon";
	import {formatDollars} from '@/utils/currency_utils'
	import {formatPercentage} from "@/utils/percent_utils";

	const tableDollarFormatter = (amt) => {
		return formatDollars(amt, {scale: 1, precision: 0 })
	},
				tblMultipleFormatter = (num) => {
					if(num == null) return '-'
					return `${Number(num).toFixed(1)}x`
				}
	const rowCategories = [
		{text: 'Revenue', valueField: 'Revenue', formatter: tableDollarFormatter},
		{text: 'Revenue (Adjusted)', valueField: 'Revenue (Adjusted)', formatter: tableDollarFormatter},
		{text: 'Gross Profit', valueField: 'Gross Profit', formatter: tableDollarFormatter},
		{text: 'Gross Margin', valueField: 'Gross Margin', formatter: formatPercentage},
		{text: 'EBITDA', valueField: 'EBITDA', formatter: tableDollarFormatter},
		{text: 'EBITDA (Adjusted)', valueField: 'EBITDA (Adjusted)', formatter: tableDollarFormatter},
		{text: 'EBITDA Margin', valueField: 'EBITDA Margin', formatter: formatPercentage},
		{text: 'Capex', valueField: 'Capex', formatter: tableDollarFormatter},
		{text: 'Enterprise Value', valueField: 'Enterprise Value', formatter: tableDollarFormatter},
		{text: 'Fund Equity Invested', valueField: 'Bertram Funds Equity Invested', formatter: tableDollarFormatter},
		{text: 'Fund Sub Debt Invested', valueField: 'Bertram Funds Sub Debt Invested', formatter: tableDollarFormatter},
		{text: 'Total Equity Invested', valueField: 'Total Equity Invested', formatter: tableDollarFormatter},
		{text: 'Net Debt', valueField: 'Net Debt', formatter: tableDollarFormatter},
		{text: 'TEV / EBITDA Multiple', valueField: 'TEV / EBITDA Multiple', formatter: tblMultipleFormatter},
		{text: 'Total FMV (Debt + Equity)', valueField: 'Total FMV (Debt + Equity)', formatter: tableDollarFormatter},
		{text: 'Total Equity FMV', valueField: 'Total Equity FMV', formatter: tableDollarFormatter},
		{text: 'Debt FMV', valueField: 'Debt FMV', formatter: tableDollarFormatter},
		{text: 'Equity FMV', valueField: 'Bertram Equity FMV', formatter: tableDollarFormatter},
		{text: 'Equity & Debt Gain / (Loss)', valueField: 'Bertram Equity & Debt Gain / (Loss)', formatter: tableDollarFormatter},
		{text: 'Gross MOIC (Equity, Exc. Fees)', valueField: 'Bertram Gross MOIC (Equity, Exc. Fees)', formatter: tblMultipleFormatter},
		{text: 'Transaction & Advisory Fees Paid to Mgmt Co not used to Offset Mgmt Fees', valueField: 'Transaction & Advisory Fees Paid to Mgmt Co not used to Offset Mgmt Fees', formatter: tableDollarFormatter},
		{text: 'Transaction & Advisory Fees Applied to Mgmt Fee Offsets', valueField: 'Transaction & Advisory Fees Applied to Mgmt Fee Offsets', formatter: tableDollarFormatter},
		{text: 'Equity FMV + Mgmt Fee Offsets', valueField: 'Bertram Equity FMV + Mgmt Fee Offsets', formatter: tableDollarFormatter},
		{text: 'Total Realized Equity Value', valueField: 'Total Realized Equity Value', formatter: tableDollarFormatter},
		{text: 'Realized Equity Value', valueField: 'Bertram Realized Equity Value', formatter: tableDollarFormatter},
		{text: 'Total Unrealized FMV Equity Value', valueField: 'Total Unrealized FMV Equity Value', formatter: tableDollarFormatter},
		{text: 'Unrealized FMV Equity Value', valueField: 'Bertram Unrealized FMV Equity Value', formatter: tableDollarFormatter},
		{text: 'Total leverage ratio', valueField: 'Total leverage ratio', formatter: (val) => val == null ? '-' : Number(val).toFixed(2)},
		{text: 'Cost Basis (Ownership Multiple)', valueField: 'Cost Basis (Ownership Multiple)', formatter: tblMultipleFormatter},
		{text: 'Cash', valueField: 'Cash', formatter: tableDollarFormatter},
		{text: 'Total Leverage', valueField: 'Total Leverage', formatter: tableDollarFormatter},
		{text: 'LTM Revenue', valueField: 'LTM Revenue', formatter: tableDollarFormatter},
		{text: 'LTM Gross Profit', valueField: 'LTM Gross Profit', formatter: tableDollarFormatter},
		{text: 'LTM Gross Margin', valueField: 'LTM Gross Margin', formatter: formatPercentage},
		{text: 'LTM EBITDA', valueField: 'LTM EBITDA', formatter: tableDollarFormatter},
		{text: 'LTM EBITDA Margin', valueField: 'LTM EBITDA Margin', formatter: formatPercentage},
		{text: 'LTM Capex', valueField: 'LTM Capex', formatter: tableDollarFormatter},
		{text: 'LTM Interest Charges', valueField: 'LTM Interest Charges', formatter: tableDollarFormatter},
		{text: 'EBITDA/Interest Expense', valueField: 'EBITDA/Interest Expense', formatter: tableDollarFormatter},
		{text: 'EBITDA-CAPEX/Interest Expense', valueField: 'EBITDA-CAPEX/Interest Expense', formatter: tableDollarFormatter},
		{text: 'Net Debt/EBITDA', valueField: 'Net Debt/EBITDA', formatter: tableDollarFormatter},
		{text: 'LTM Free Cash Flow', valueField: 'LTM Free Cash Flow', formatter: tableDollarFormatter},
		{text: 'Notes', valueField: 'Notes', formatter: null}
	]

	export default {
		inheritAttrs: false,

		mixins: [],

		components: {
		},

		filters: {
		},

		provide: function() {
			return {
			}
		},

		inject: [],

		props: {
			sourceData: {
				type: Object,
				required: true
			}
		},

		data() {
			return {
				rows_per_page: 60
			}
		},

		computed: {
			months() {
				if (this.dataLoaded()) {
					const months = []
					for(const data of this.sourceData) {
						const month = data.date
						months.push(month)
					}

					// sort the months
					months.sort((a, b) => {
						const aDate = DateTime.fromFormat(a, 'yyyy-MM-dd')
						const bDate = DateTime.fromFormat(b, 'yyyy-MM-dd')
						return aDate < bDate ? -1 : 1
					} )

					return months
				} else {
					return []
				}
			},

			tableHeadings() {
				return [{value: 'category', text: '', fixed: true, width: 250}].concat(
					this.months.map(month => ({
						value: month, text: DateTime.fromFormat(month, 'yyyy-MM-dd').toFormat('MMM yy').toLocaleUpperCase(), width: 120
					}))
				)
			},

			tableData() {
				if(!this.sourceData || !this.sourceData.length) return []
				const rows = []
				let ordered = this.sourceData.slice().reverse()
				rowCategories.forEach(category => {
					const rowData = {category: category.text}
					ordered.forEach(data => {
						rowData[data.date] = category.formatter ? category.formatter(data.data[category.valueField]) : data.data[category.valueField]
					})

					rows.push(rowData)
				})

				return rows
			},

			hideFooter() {
				return this.tableData.length <= this.rows_per_page
			}
		},

		watch: {

		},

		created() {
		},

		mounted() {
		},

		methods: {
			dataLoaded() {
				return this.sourceData && typeof(this.sourceData) != 'undefined' && Object.keys(this.sourceData).length > 0
			}
		}
	}
</script>

<style lang="scss">

</style>
