<template>
	<div>
		<div class="row">
			<div class="col">
				<div class="ps-3 d-flex justify-content-between mb-3">
					<h3>Run Reports</h3>
				</div>
				<input type="text" placeholder="   Search" class="search-box">
				<ol class="list-unstyled" v-if="!loading">
					<li class="p-3" v-for="(report, idx) in reportsData" v-bind:key="idx" :class="{'alt-bg': isOdd(idx)}">
						<div class="d-flex justify-content-between">
							<h5>{{ report.title }}</h5>
							<p class="text-end graphite sidebar-body">{{ report.runDate }}</p>
						</div>

						<dl class="row investment-info mb-0 sidebar-body">
							<dt class="col-12 graphite">{{ report.description }}</dt>
						</dl>
					</li>
				</ol>
				<ol class="list-unstyled" v-else>
					<li class="ps-3 pt-1 pb-1 mb-2" v-for="index in 9" :key="index">
						<div class="d-flex justify-content-between my-1 placeholder-wave" style="height: 23px;">
							<span class="placeholder col-5"></span>
							<span class="placeholder col-3"></span>
						</div>
						<div class="d-flex justify-content-between my-1 placeholder-wave" style="height: 45px;">
							<span class="placeholder col-7"></span>
						</div>
					</li>
				</ol>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">

	export default {
		name: 'ReportSidebar',
		inheritAttrs: false,
		
		mixins: [],
		
		components: {
		},
		
		props: {},
		
		data() {
			return {
				reportsData: [],
				loading: true
			}
		},
		
		computed: {
			
		},
		
		mounted() {
			this.loadReportsData()
		},
		
		methods: {
			isOdd(index) {
				return (index % 2) === 1
			},
			
			loadReportsData() {
				this.loading = true
				setTimeout(() => this.loading = false, 1000);
				this.reportsData = [
					{
						title: 'Custom Report',
						runDate: '9/20/24 08:30AM',
						description: 'Fund IV, Investment H, 01/01/22-07/01/22, Gross MOIC, Net MOIC, Net IRR, EBITDA, Net IRR, Revenue, Capex'
					},
					{
						title: 'Custom Report',
						runDate: '9/18/24 10:30AM',
						description: 'Fund IV, Investment A, 01/01/22-07/01/22, Gross MOIC, Net MOIC, Net IRR, EBITDA, Net IRR, Revenue, Capex'
					},
					{
						title: 'YTD Fund IV',
						runDate: '9/17/24 10:30AM',
						description: 'Fund IV, AFC, 01/01/22-07/01/22, Gross MOIC, Net MOIC, Net IRR, EBITDA, Net IRR, Revenue, Capex'
					},
					{
						title: 'YTD Fund IV IRR, MOIC',
						runDate: '9/04/24 10:30AM',
						description: 'Fund IV, Investment B, 01/01/22-07/01/22, Gross MOIC, Net MOIC, Net IRR, EBITDA, Net IRR, Revenue, Capex'
					},
					{
						title: 'Custom Report',
						runDate: '8/23/24 08:30AM',
						description: 'Fund IV, Investment D, 01/01/22-07/01/22, Gross MOIC, Net MOIC, Net IRR, EBITDA, Net IRR, Revenue, Capex'
					},
					{
						title: 'YTD Fund IV',
						runDate: '8/20/24 10:30AM',
						description: 'Fund IV, Investment J, 01/01/22-07/01/22, Gross MOIC, Net MOIC, Net IRR, EBITDA, Net IRR, Revenue, Capex'
					},
					{
						title: 'Custom Report',
						runDate: '8/20/24 10:30AM',
						description: 'Fund IV, Investment C, 01/01/22-07/01/22, Gross MOIC, Net MOIC, Net IRR, EBITDA, Net IRR, Revenue, Capex'
					},
				]
			}
		}
	}
</script>

<style lang="scss" scoped>
@import '../../styles/custom-variables.scss';
h5 {
	color: $darkblue;
}
.search-box {
	width: 95%;
	height: 34px; 
	background: white; 
	border-radius: 34px; 
	border-color: #CBCBCB;
	border: 1px solid #CBCBCB;
	display: flex; 
	align-items: center; 
	justify-content: flex-start;
	margin-left: 10px;
}
.search-box:focus {
	border-color: #CBCBCB;
	border: 1px solid #CBCBCB;
}
</style>