<template>
	<div class="row gx-5">
		<div class="col-12">
			<div>
				<h3>Lenders Data</h3>
				<table class="table table-striped" v-if="hasLenders">
					<thead>
						<tr>
							<th class="ps-3">Lender</th>
							<th>Lender Type</th>
							<th>Dollars Invested</th>
							<th>Pricing</th>
							<th>Allocation</th>
						</tr>
					</thead>
					<tbody>
						<tr class="ps-3 text" v-for="(lender, idx) in lenders" :key="idx">
							<td class="ps-3">{{ lender.data.Lender }}</td>
							<td>{{ lender.data['Lender Type'] }}</td>
							<td><format-currency :number="lender.data['Dollars Invested']" /></td>
							<td>{{ lender.data.Pricing }}</td>
							<td><format-percent :number="lender.data['Alloc.']" /></td>
						</tr>
					</tbody>
				</table>
			</div>
			<div class="mt-5">
				<h3>Co-Invest Summary</h3>
				<dl class="row company-attributes zebra my-3">
					<dt class="col-9 "><span>Equity</span></dt>
					<dd class="col-3 "><format-currency :number="equity" /></dd>
				</dl>
			</div>
			<div class="mt-5">
				<h3>Co-Invest Equity</h3>
				<table class="table table-striped">
					<thead>
						<tr class="top-aligned">
							<th class="ps-3" width="40%">Co-investor</th>
							<th width="15%">Acq.</th>
							<th width="15%">Ownership % at Acq.</th>
							<th width="15%">Current</th>
							<th width="15%">Current Ownership %</th>
						</tr>
					</thead>
					<tbody>
					<tr class="ps-3 text" v-for="coInvest in coInvestData" :key="coInvest.key">
						<td class="ps-3">{{ coInvest.coInvestor }}</td>
						<td><format-currency :number="coInvest.acq" /></td>
						<td><format-percent :number="coInvest['Ownership % at ACQ']" /></td>
						<td><format-currency :number="coInvest.current" /></td>
						<td><format-percent :number="coInvest['Current Ownership %']" /></td>
					</tr>
					</tbody>
					<tfoot>
						<tr class="bold">
							<td class="ps-3">Total</td>
							<td><format-currency :number="totalCoInvestData.acq" /></td>
							<td></td>
							<td><format-currency :number="totalCoInvestData.current" /></td>
							<td></td>
						</tr>
						<tr class="bold">
							<td class="ps-3">Non-LP Co-invest</td>
							<td><format-currency :number="nonLPCoInvestData.acq" /></td>
							<td></td>
							<td><format-currency :number="nonLPCoInvestData.current" /></td>
							<td></td>
						</tr>
					</tfoot>
				</table>
			</div>
			<div class="mt-5">
				<h3>LP Mezz Debt</h3>
				<table class="table table-striped" v-if="hasMezzDebt">
					<thead>
						<tr>
							<th class="ps-3" width="40%">Debt Holder</th>
							<th width="30%">Acq.</th>
							<th width="30%">Current</th>
						</tr>
					</thead>
					<tbody>
						<tr class="text" v-for="lender in mezzDebtHolders" :key="lender.debtHolder">
							<td class="ps-3">{{lender.debtHolder}}</td>
							<td><format-currency :number="lender.acq" /></td>
							<td><format-currency :number="lender.current" /></td>
						</tr>
					</tbody>
					<tfoot>
						<tr class="bold">
							<td class="ps-3">Total</td>
							<td><format-currency :number="totalMezzDebtData.acq" /></td>
							<td><format-currency :number="totalMezzDebtData.current" /></td>
						</tr>
					</tfoot>
				</table>
				<p class="fst-italic" v-else>None Available</p>
			</div>
			<div class="mt-5">
				<h3>LP Senior Debt</h3>
				<table class="table table-striped" v-if="hasSeniorDebt">
					<thead>
						<tr>
							<th class="ps-3" width="40%">Debt Holder</th>
							<th width="30%">Acq.</th>
							<th width="30%">Current</th>
						</tr>
					</thead>
					<tbody>
						<tr class="text" v-for="lender in seniorDebtHolders" :key="lender.debtHolder">
							<td class="ps-3">{{lender.debtHolder}}</td>
							<td><format-currency :number="lender.acq" /></td>
							<td><format-currency :number="lender.current" /></td>
						</tr>
					</tbody>
					<tfoot>
						<tr class="bold">
							<td class="ps-3">Total</td>
							<td><format-currency :number="totalSeniorDebtData.acq" /></td>
							<td><format-currency :number="totalSeniorDebtData.current" /></td>
						</tr>
					</tfoot>
				</table>
				<p class="fst-italic" v-else>None Available</p>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import FormatCurrency from '@/views/utils/FormatCurrency'
	import FormatPercent from '@/views/utils/FormatPercent'

	export default {
		inheritAttrs: false,

		mixins: [],

		components: {
			FormatCurrency,
			FormatPercent
		},

		filters: {
		},

		provide: function() {
			return {
			}
		},

		inject: [],

		props: {
			sourceData: {
				type: Object,
				required: true
			},
			lenders: {
				type: Array,
				required: true
			}
		},

		data() {
			return {
			
			}
		},

		computed: {
			hasCoInvest() {
				return this.lenderData.coInvest?.length > 0
			},
	
			hasMezzDebt() {
				return this.lenderData.mezzDebt?.length > 0
			},

			mezzDebtHolders() {
				return this.lenderData.mezzDebt
			},

			hasSeniorDebt() {
				return this.lenderData.seniorDebt?.length > 0
			},

			seniorDebtHolders() {
				return this.lenderData.seniorDebt
			},

			hasLenders() {
				return this.lenders?.length
			},

			equity() {
				if(this.hasCoInvest) {
					return this.summaryData['BCAP Equity']
				}
				return null
			},

			summaryData() {
				const lender = this.lenderData
				return lender.summary.length ? lender.summary[0] : {}
			},

			lenderData() {
				if(this.dataLoaded) {
					return this.sourceData[0].data
				} else {
					return {
						coInvest: [],
						mezzDebt: [],
						seniorDebt: [],
						lendersData: [],
						summary: []
					}
				}
			},
			
			coInvestData() {
				if(this.hasCoInvest) {
					const rows = []
					let index = 0
					for(const coInvest of this.lenderData.coInvest) {
						if(coInvest['coInvestor'] != 'Non-LP Co-Invest (Lender, Non-LP)') {
							// HACK! Non-LP Co-Invest should not appear unthe the coInvest block when parsed
							rows.push({key: index, acq: coInvest['acq'], 
										coInvestor: coInvest['coInvestor'], current: coInvest['current'],
										'Ownership % at ACQ': coInvest['Ownership % at ACQ'],
										'Current Ownership %': coInvest['Current Ownership %']})
							index++
						}
					}
					return rows
				} else {
					return []
				}
			},
			totalCoInvestData() {
				return this.totalData(this.coInvestData)
			},
			totalMezzDebtData() {
				return this.totalData(this.mezzDebtHolders)
			},
			totalSeniorDebtData() {
				return this.totalData(this.seniorDebtHolders)
			},
			nonLPCoInvestData() {
				if(this.hasCoInvest) {
					const nonLPCoInvest = {current: 0}
					for(const coInvest of this.lenderData.coInvest) {
						if(coInvest['coInvestor'] == 'Non-LP Co-Invest (Lender, Non-LP)') {
							// HACK! Non-LP Co-Invest should not appear unthe the coInvest block when parsed
							nonLPCoInvest.current = coInvest['current']
							nonLPCoInvest.acq = coInvest['acq']
						}
					}
					return nonLPCoInvest
				} else {
					return {}
				}
			},
			lendersData() {
				return this.sourceData.lendersData
			},

			dataLoaded() {
				return this.sourceData?.length > 0
			}
		},

		watch: {

		},

		created() {
		},

		mounted() {
		},

		methods: {
			totalData(data) {
				const total = {acq: 0, current: 0}
				for (const entry of data) {
					total.acq += entry.acq
					total.current += entry.current
				}
				return total
			}
		}
	}
</script>

<style lang="scss">
	@import '../../../styles/custom-variables.scss';
	th {
		color: $navy;
	}
	td {
		font-size: 14px;
	}
	.bold {
		color: $navy;
	}
	.zebra {
		background-color: $zebra;
	}
	.top-aligned {
		vertical-align: top;
	}
</style>
