<template>
	<tr>
		<td class="bigger text">
			<format-date  v-bind:date="uploadDate"></format-date>
		</td>
		<td class="graphite cut-off"><format-user-name v-bind:name="upload.upload_data.uploadingUser"></format-user-name></td>
		<td class="graphite"><router-link :to="{name: 'CompanyInvestment', params: {investmentId: upload.EquivateObjectId}}">{{ upload.upload_data.investmentName }}</router-link></td>
		<td v-if="canDownload"><a href="#" class="file-name-col" v-on:click.stop v-on:click.prevent="downloadFile" v-bind:data-url="upload.upload_data.url" v-bind:data-filename="upload.upload_data.fileName">{{ upload.upload_data.fileName }}</a></td>
		<td v-else class="file-name-col graphite">{{ upload.upload_data.fileName }}</td>

		<td class="graphite">{{ formatStatus(upload.upload_data.status) }}</td>
		<td class="graphite cut-off"><format-user-name v-bind:name="upload.upload_data.statusChangedBy" v-if="isApproved"></format-user-name></td>
		<td>
			<div class="btn-group">
				<button v-on:click.stop class="btn btn-secondary btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
					Actions
				</button>
				<ul class="dropdown-menu">
					<li v-if="canApprove"><a class="dropdown-item" v-on:click="approveFile">Approve</a></li>
					<li v-if="canReject"><a class="dropdown-item" v-on:click="rejectFile">Reject</a></li>
					<li v-if="canDownload"><a class="dropdown-item" v-on:click.stop v-on:click.prevent="downloadFile" v-bind:data-url="upload.upload_data.url" v-bind:data-filename="upload.upload_data.fileName">Download</a></li>
				</ul>
			</div>
		</td>
	</tr>
</template>

<script type="text/javascript">
	import { useUserStore } from '@/stores/user'
	import { useFundStore } from '@/stores/fund'
	import {formatStatus} from "@/utils/string_utils"
	import FormatDate from '../utils/FormatDate'
	import FileDownload from "@/mixins/FileDownload";
	import FormatUserName from "@/views/utils/FormatUserName";
	import {UploadsApi} from '@/api/uploads_api'
	import {DateTime} from 'luxon'

	export default {
		name: 'DataCollectionUpload',
		props: ['upload'],
		inheritAttrs: false,

		mixins: [FileDownload],

		components: {
			FormatUserName,
			FormatDate
		},

		filters: {
		},

		provide: function() {
			return {
			}
		},

		inject: [],

		data() {
			return {
				companies: []
			}
		},

		computed: {
			isApproved() {
				const curStatus = this.upload.upload_data.status
				return ['APPROVED', 'COMPLETE', 'ERROR'].find(s => s === curStatus) != null
			},

			canApprove() {
				return this.upload.upload_data?.status == 'PENDING'
			},

			canReject() {
				return this.upload.upload_data?.status == 'PENDING'
			},

			canDownload() {
				return this.upload.upload_data?.status != 'DELETED' && this.upload.upload_data?.status != 'REQUESTED'
			},

			uploadDate() {
				return this.upload ? DateTime.fromISO(this.upload.date_created).toJSDate() : null
			}
		},

		watch: {

		},

		setup() {
			const userStore = useUserStore()
			const fundStore = useFundStore()
			return {
				userStore,
				fundStore
			}
		},

		created() {
		},

		mounted() {
		},

		methods: {
			formatStatus,
			approveFile() {
				UploadsApi.updateUploadStatus({uploadId: this.upload.UploadId, newStatus: 'APPROVED'})
					.then(resp => {
						console.log('got response to approve', resp.data)
						this.$emit('uploadApproved', this.upload, resp.data)
					})
			},
			rejectFile() {
				UploadsApi.updateUploadStatus({uploadId: this.upload.UploadId, newStatus: 'REJECTED'})
					.then(resp => {
						this.$emit('uploadRejected', this.upload, resp.data)
					})
			},
			lookupFundName(companyId) {
				if(companyId) {
					const fund = this.fundStore.funds.find(f => f.companies.indexOf(companyId) > -1)
					return fund ? fund.name : ''
				} else {
					return ''
				}
			},
			lookupCompanyName(companyId) {
				if(companyId) {
					const company = this.companies.find(c => c.id == companyId.toString())
					return company ? company.name : ''
				} else {
					return ''
				}
			},
		}
	}
</script>

<style lang="scss" scoped>
	@import '../../styles/custom-variables.scss';
	.file-name-col {
		line-break: anywhere;
	}
	.graphite {
		color: $graphite;
	}
	.cut-off {
		max-width: 120px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis !important;
	}
</style>
