<template>
	<div class="row gx-lg-5 gx-md-0">
		<div class="col-lg-8 col-md-12">
			<div class="d-flex justify-content-between align-items-center">
				<h2>Report Builder</h2>
			</div>
			<div class="row report-options mt-4 p-3">
				<fund-investments-selector v-model:fundsSelected="fundsSelected" v-model:investmentsSelected="investmentsSelected" ref="fundInvestmentsSelector" :include-date-range=true></fund-investments-selector>
				<div class="mt-4">
					<div class="form-label">Data Inputs</div>
					<div class="container">
						<div class="grid">
							<div class="item" v-for="(item, index) in dataInputs" :key="index">
								<input type="checkbox" :id="'cb' + index" class="custom-checkbox">
								<label :for="'cb' + index" class="custom-checkbox-label">{{ item }}</label>
							</div>
						</div>
					</div>
				</div>
				<div class="d-flex flex-row-reverse mt-4">
					<router-link :to="{ name: 'ReportView', params: {funds: fundParameters, investments: investmentParameters}}" custom v-slot="{ href }">
						<a :href="href" target="_blank" class="button btn btn-primary">Run Report</a>
					</router-link>
				</div>
			</div>
			<div class="row">
				<saved-reports></saved-reports>
			</div>
			
		</div>
		<div class="col-lg-4 col-md-12 d-md-none d-lg-block border-start">
			<report-sidebar></report-sidebar>
		</div>
	</div>
</template>

<script type="text/javascript">
	import FundInvestmentsSelector from "@/views/shared/FundInvestmentsSelector";
	import ReportSidebar from "./ReportSidebar.vue";
	import SavedReports from "./SavedReports.vue";

	export default {
		name: 'ReportsView',
		inheritAttrs: false,

		mixins: [],

		components: {
			FundInvestmentsSelector,
			ReportSidebar,
			SavedReports
		},

		filters: {
		},

		provide: function() {
			return {
			}
		},

		inject: [],

		props: [],

		data() {
			return {
				fundsSelected: [],
				investmentsSelected: [],
				dataInputs: [
					'Gross MOIC',
					'Gross Profit', 
					'Company Updates',
					'Net MOIC',
					'Gross Margin', 
					'Co-invest/Lender Data',
					'Gross IRR',
					'Capex',
					'Financials/Accruals',
					'Net IRR',
					'Enterprise Value',
					'Add on Acquisitions',
					'EBITDA',
					'Bertram Funds Equity Invested',
					'ESG Data',
					'EBITDA Margin',
					'Company Overview',
					'ESG Scorecard'
				]
			}
		},

		computed: {
			fundParameters() {
				if(this.fundsSelected.length > 0 && this.investmentsSelected.length == 0) {
					return this.fundsSelected.map(fund => fund.id).join(',')
				}
				return '-'
			},
			investmentParameters() {
				if(this.investmentsSelected.length > 0) {
					return this.investmentsSelected.map(company => company.id).join(',')
				} else {
					return '-'
				}
			}
		},

		watch: {

		},

		created() {
		},

		mounted() {
		},

		methods: {
		}
	}
</script>

<style lang="scss">
@import '../../styles/custom-variables';
@import '../../styles/equivate/checkboxes';
.report-options {
	background-color: $zebra;
}
.grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.item {
    display: flex;
    align-items: center;
    position: relative;
}
</style>
