<template>
	<div class="row gx-lg-5 gx-md-0">
		<div class="col-lg-8 col-md-12">
			<section>
				<capital-status-of-funds />
			</section>
			<section class="mt-5 mb-4">
				<fund-performance />
			</section>
			<section class="mt-5">
				<investment-performance />
			</section>
		</div>
		<div class="col-4 d-md-none d-lg-block border-start">
			<dashboard-sidebar />
		</div>
	</div>
</template>

<script>
	
	import CapitalStatusOfFunds from './CapitalStatusOfFunds.vue'
	import FundPerformance from './FundPerformance.vue'
	import InvestmentPerformance from './InvestmentPerformance.vue'
	import DashboardSidebar from './DashboardSidebar.vue'
	
	export default {
		name: "DashboardView",
		components: {
			CapitalStatusOfFunds,
			FundPerformance,
			InvestmentPerformance,
			DashboardSidebar},
		data() {
			return {
				files: 0,
				companies: 0,
				recentActivities: []
			}
		},
		methods: {
		},
		mounted() {
		},
	}
</script>

<style scoped lang="scss">
	
</style>
