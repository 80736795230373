import {getDownloadURL, getStorage, ref} from "firebase/storage";
import axios from "axios";
import {downloadFile} from '@/utils/file_utils'

export default {
	methods: {
		/**
		 * Common functionality to download a file from clicking on a link
		 * @param event the target of the event must have dataset.url... or data-url property set
		 * @returns {Promise<void>}
		 */
		async downloadFile(event) {
			console.log('downloading', event)
			let downloadUrl = event.currentTarget.dataset.url
			const filename = event.currentTarget.dataset.filename || 'download';
			const storage = getStorage();
			getDownloadURL(ref(storage, downloadUrl))
				.then(async (url) =>  {
					const response = await axios.get(url, { responseType: "blob" });
					const blob = new Blob([response.data], { type: "application/octet-stream" });
					downloadFile({fileName: filename, url: URL.createObjectURL(blob)})
				})
				.catch((error) => {
					console.error("error " + error)
					switch (error.code) {
						case 'storage/object-not-found':
							break;
						case 'storage/unauthorized':
							break;
						case 'storage/canceled':
							break;
						case 'storage/unknown':
							break;
					}
				});
		}
	}
}
