<template>
    <div class="skeleton-loader placeholder-wave">
      <!-- Top Row with Content -->
      <div class="top-row">
        <!-- Top Left Content -->
        <div class="top-left">
          <div class="short-medium-thick placeholder"></div>
          <div class="long-thicker placeholder"></div>
        </div>
        
        <!-- Top Right Content -->
        <div class="top-right">
          <div class="medium-skinny placeholder"></div>
          <div class="short-medium placeholder"></div>
        </div>
      </div>
  
      <!-- Thick Rectangle Below -->
      <div class="full-length-thick placeholder"></div>
  
      <!-- Large Rectangle Representing Loading Table -->
      <div class="loading-table placeholder"></div>
    </div>
  </template>
  
  <script>
  export default {
    name: "SkeletonLoaderTable",
  };
  </script>
  
  <style lang="scss" scoped>

  .skeleton-loader {
    width: 100%;
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  
  .top-row {
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }
  
  .top-left {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .short-medium-thick {
    width: 100px;
    height: 20px;
  }
  
  .long-thicker {
    width: 200px;
    height: 25px;
  }
  
  .top-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 10px;
  }
  
  .medium-skinny {
    width: 150px;
    height: 10px;
  }
  
  .short-medium {
    width: 100px;
    height: 20px;
  }
  
  .full-length-thick {
    width: 100%;
    height: 20px;
  }
  
  .loading-table {
    width: 100%;
    height: 300px;
  }
  </style>
  