<template>
	<div class="row">
		<div class="col-12" v-if="hasOutstandingRequests">
			<div class="d-flex justify-content-between align-items-center">
        <h3>Outstanding Requests</h3>
			</div>
			<div class="d-flex mt-4">
        <table class="table">
          <thead>
						<tr>
							<th>Requested</th>
							<th>Requested By</th>
							<th>Investment</th>
							<th>Notes</th>
							<th scope="col">Actions</th>
						</tr>
          </thead>
          <tbody>
						<template v-for="request in requests" :key="request.EquivateObjectId">
							<outstanding-request :request="request" v-on:request-updated="onRequestUpdated"/>
						</template>
          </tbody>
        </table>
      </div>
		</div>
		<spanner-paging-control :page-size="limit" v-bind:rows="requests" v-on:page-load="loadPage" ref="pagingControl" />
	</div>
</template>

<script type="text/javascript">

	import {RequestDataApi} from "@/api/request_data_api";
	import SpannerPagingControl from "@/components/table/SpannerPagingControl";
	import OutstandingRequest from "@/views/dataCollection/OutstandingRequest";

	export default {
		name: 'OutstandingRequests',
		inheritAttrs: false,
		
		mixins: [],
		
		components: {
			OutstandingRequest,
			SpannerPagingControl
		},
		
		filters: {
		},
		
		provide: function() {
			return {
			}
		},
		
		inject: [],
		
		props: [],
		
		data() {
			return {
				limit: 3,
				requests: []
			}
		},
		
		computed: {
			hasOutstandingRequests() {
				return this.requests.length > 0
			}
		},
		
		watch: {
			
		},
		
		created() {
		},
		
		mounted() {
			this.loadPage(0);
		},
		
		methods: {
			loadPage(offset) {
				RequestDataApi.getRequests({userId: this.$store.state.user.uid, status:'REQUESTED', limit: this.limit, offset: offset, order: this.sortOrder, sort: this.sortColumn})
					.then(response => {
						const totalRows = response.data.totalRows
						this.requests = response.data.requests
						this.$refs.pagingControl.loadComplete(offset, totalRows)
					}).catch(e => {
						console.error("error trying to list requests", e)
					});
			},
			onRequestUpdated() {
				this.loadPage(0)
			}
		}
	}
</script>

<style lang="scss" scoped>
	@import '../../styles/custom-variables';
	h3 {
		color: $red;
	}
</style>
