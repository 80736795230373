<template>
	<span>{{formattedNumber}}</span>
</template>

<script type="text/javascript">

	import {formatNumber} from "@/utils/number_utils";

	export default {
		inheritAttrs: false,
    name: 'FormatNumber',

		props: {
			number: {
			},

			precision: {
				type: Number,
				default() {
					return 2
				}
			},

			separator: {
				type: String,
				default() {
					return ','
				}
			},

			ifNull: {
				type: String,
				default() {
					return '-'
				}
			}
		},
		
		computed: {
			formattedNumber() {
				return formatNumber(this.number, {precision: this.precision, sep: this.separator, ifNull: this.ifNull})
      }
		}
	}
</script>

<style lang="scss">
</style>
