<template>
	<div>
		<div class="drop-zone"
			:data-status="dropStatus"
			@dragenter.prevent="setActive"
			@dragover.prevent="setActive"
			@dragleave.prevent="onDragLeave"
			@drop.prevent="onDrop">
			<div v-if="dropStatus == 'inactive' || dropStatus == 'active'" class="d-flex flex-column justify-content-center align-items-center">
				<img src="@/assets/spreadsheet.svg" width="37">
				<div class="mt-3">Drag and Drop File Here</div>
				<div class="d-block w-100">
					<h5><span>or</span></h5>
				</div>
				<label for="file-input">
					<div class="btn btn-primary">Browse Files</div>
					<input type="file" id="file-input" @change="onInputChange"/>
				</label>
			</div>
			<div v-if="dropStatus == 'valid'" class="d-flex flex-column justify-content-center align-items-center">
				<img src="@/assets/spreadsheet_valid.svg" width="52">
				<div class="mt-3">Looks Good! Ready to Upload</div>
				<div class="mt-3 ms-4 me-4 position-relative" v-if="files.length > 0">
					<div>{{files[0].name}}
						<button @click="removeFile" class="close-icon">&times;</button>
					</div>
				</div>
			</div>
			<div v-if="dropStatus == 'validating'" class="d-flex flex-column justify-content-center align-items-center ">
				<img src="@/assets/spreadsheet_checking.svg" width="52" class="mt-3">
				<div class="checking mt-3 ms-4 me-4 position-relative" v-if="files.length > 0">
					<div>Checking</div>
					<div>{{files[0].name}}
						<button @click="removeFile" class="close-icon">&times;</button>
					</div>
				</div>
			</div>
			<div v-if="dropStatus == 'validation-errors'" class="d-flex flex-column justify-content-center align-items-center ">
				<img src="@/assets/spreadsheet_error.svg" width="52" class="mt-3">
				<div class="checking mt-3 ms-4 me-4 position-relative" v-if="files.length > 0">
					<div>{{files[0].name}}
						<button @click="removeFile" class="close-icon">&times;</button>
					</div>
					<div class="mt-2">{{errorsSummary()}}</div>
					<div class="validation-errors">
						<div v-for="err in validationErrors.slice(0,3)" :key="err.msg">{{formatErrorMessage(err)}}</div>
						<a href="#" v-if="hasMoreErrors" @click="showErrorsModal">View All</a>
					</div>
				</div>
			</div>
		</div>
	</div>
	<validation-errors-modal :validation-errors="validationErrors" :visible="isModalVisible" @close="closeErrorsModal"></validation-errors-modal>
</template>

<script type="text/javascript">
	import ValidationErrorsModal from "@/views/shared/ValidationErrorsModal";
	const events = ['dragenter', 'dragover', 'dragleave', 'drop']

	class UploadableFile {
		constructor(file) {
			this.file = file
			this.name = file.name
			this.id = `${file.name}-${file.size}-${file.lastModified}-${file.type}`
			this.url = URL.createObjectURL(file)
			this.status = null
		}
	}

	export default {
		components: {ValidationErrorsModal},
		inheritAttrs: false,
		emits: ['filesDropped', 'removeFile'], // To perform some validations
		name: 'DropZone',
		data() {
			return {
				dropStatus: 'inactive',
				inActiveTimeout: null,
				validationErrors: [],
				files: [],
				isModalVisible: false
			}
		},

		mounted() {
			events.forEach((eventName) => {
				document.body.addEventListener(eventName, this.preventDefaults)
			})
		},

		unmounted() {
			events.forEach((eventName) => {
				document.body.removeEventListener(eventName, this.preventDefaults)
			})
		},

		computed: {
			hasMoreErrors() {
				return this.validationErrors.length > 3
			},
		},

		methods: {
			errorsSummary() {
				if(this.validationErrors.length == 1) {
					return `1 Error`
				} else if (this.validationErrors.length > 1) {
					return `${this.validationErrors.length} Errors`
				} else {
					return ''
				}
			},

			formatErrorMessage(err) {
				let msg = err.msg
				if(err.sheetName) {
					msg = `${msg} (${err.sheetName})`
				}
				return msg
			},

			addFiles(newFiles) {
				let newUploadableFiles = [...newFiles].map((file) => new UploadableFile(file)).filter((file) => !this.fileExists(file.id))
				this.files = this.files.concat(newUploadableFiles)
				this.dropStatus = 'validating'
				this.$emit('filesDropped', newFiles)
			},

			validationComplete(success, errors) {
				console.log(`validationComplete ${success} ${errors}`)
				if(success == true) {
					this.validationErrors = []
					this.dropStatus = 'valid'
				} else {
					this.validationErrors = errors
					this.dropStatus = 'validation-errors'
				}
			},

			reset() {
				this.dropStatus = 'inactive'
				this.files = []
				this.validationErrors = []
			},

			fileExists(otherId) {
				return this.files.some(({ id }) => id === otherId)
			},

			removeFile() {
				console.log("remove")
				this.files = []
				this.validationErrors = []
				this.dropStatus = 'inactive'
				this.$emit('removeFile')
			},

			onInputChange(e) {
				this.addFiles(e.target.files)
			},

			setActive() {
				this.dropStatus = 'active'
				clearTimeout(this.inActiveTimeout)
			},

			setInactive() {
				this.inActiveTimeout = setTimeout(() => {
					this.dropStatus = 'inactive'
				}, 50)
			},

			onDrop(e) {
				this.addFiles([...e.dataTransfer.files])
			},

			onDragLeave() {
				if(this.files.length == 0) {
					this.setInactive()
				}
			},

			preventDefaults(e) {
				e.preventDefault()
			},

			closeErrorsModal() {
				this.isModalVisible = false
			},

			showErrorsModal() {
				this.isModalVisible = true
			}
		}
	}
</script>

<style lang="scss" scoped>
	@import '../../styles/custom-variables';
	.drop-zone {
		background-color: $lightblue;
		padding: 41px 0 51px;
		border-style: dashed;
		border-color: $darkblue;
		border-width: 1px;
		font-size: 16px;
		color: $navy;
		&[data-status='active'] {
			border-width: 3px;
		}
		&[data-status='validating'] {
			background-color: $pillgreen;
			.checking {
				color: $darkgreen;
				font-size: 18px;
				text-align: center;
			}
		}
		&[data-status='validation-errors'] {
			background-color: $lightred;
			border-color: $red;
			.checking {
				color: $red;
				font-size: 18px;
				text-align: center;
			}
		}
		h5 {
			text-align:center;
			border-bottom: 1px solid $midblue;
			line-height:0.1em;
			margin:25px 75px 25px;
			span {
				background:$lightblue;
				padding:0 20px;
			}
		}
	}
	label {
		input[type=file]:not(:focus-visible) {
			// Visually Hidden Styles taken from Bootstrap 5
			position: absolute !important;
			width: 1px !important;
			height: 1px !important;
			padding: 0 !important;
			margin: -1px !important;
			overflow: hidden !important;
			clip: rect(0, 0, 0, 0) !important;
			white-space: nowrap !important;
			border: 0 !important;
		}
	}

	.close-icon {
		--size: 20px;
		line-height: var(--size);
		height: var(--size);
		border-radius: var(--size);
		box-shadow: 0 0 5px currentColor;
		right: 0.25rem;
		appearance: none;
		border: 0;
		padding: 0;
		width: var(--size);
		font-size: var(--size);
		background: #933;
		color: #fff;
		top: 0.25rem;
		cursor: pointer;
	}
	.validation-errors {
		font-size: 14px;
	}
</style>