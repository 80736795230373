<template>
	<div>
		<div class="row">
			<div class="col">
				<bread-crumb
					:breadcrumbData="breadcrumbData" />
			</div>
		</div>
		<div class="row gx-lg-5 gx-md-0">
			<div class="col-lg-9 col-md-12">
				<div class="row mb-5 mt-5">
					<div class="col-12">
						<div class="row" v-if="!dataLoading && hasData">
							<div class="col-3">
								<div class="list-inline-item fund-chart">
									<fund-chart
										fund-name="Capital Status"
										:amt-invested="fundAmtInvested"
										:amt-fees="fundFees"
										:amt-expenses="fundAmtExpenses"
										:amt-available="fundAmtAvailable" />
								</div>
							</div>
							<div class="col-9">
								<h3>Investment Performance - EBITDA</h3>
								<div>
									<Bar :data="investmentPerformanceChartData" :options="investmentPerformanceChartConfig" :height="300"/>
								</div>
							</div>
						</div>
						<div class="row align-items-center" v-if="dataLoading">
							<div class="col-3">
								<PieChartPlaceholder/>
							</div>
							<div class="col-9">
								<BarGraphPlaceholder/>
							</div>
						</div>
					</div>
				</div>
				<div class="row mt-5" v-if="!dataLoading && hasData">
					<h3 class="title">{{ fund.name }}</h3>
					<div class="col-12">
						<pill-control
							:nav-options="[{name: 'All', value: null}, {name: 'Active', value: 'Active'}, {name: 'Exited', value: 'Exited'}]"
							@pill-nav-clicked="pillNavClicked"/>
						<EasyDataTable
							:alternating="true"
							:headers="tableHeadings"
							:items="fundTableItems"
							table-class-name="custom-table"
							:rows-per-page="rows_per_page"
							:hide-footer="hideFooter"
							>
							<template #item-investment="{investment, investmentId}">
								<router-link :to="{name: 'CompanyInvestment', params: {investmentId}}">{{investment}}</router-link>
							</template>
						</EasyDataTable>
						<h1 v-if="!dataLoading && !hasData">NO DATA TODO</h1>
					</div>
				</div>
				<div class="row mt-5" v-if="dataLoading">
					<div class="col-12">
						<TablePlaceholder/>
					</div>
				</div>
			</div>
			<div class="col-3 d-md-none d-lg-block border-start">
				<investments-sidebar
					:sidebar-title="fundSidebarTitle"
					:investment-data="activeFundInvestments"/>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import { useFundStore } from '@/stores/fund'
	import FundChart from '@/components/financials/FundChart.vue'
	import BreadCrumb from '@/views/nav/BreadCrumb.vue'
	import InvestmentsSidebar from './InvestmentsSidebar'
	import PillControl from '@/components/controls/PillControl'
	import {formatDollars} from "@/utils/currency_utils"
	import {formatPercentage} from "@/utils/percent_utils"
	import {FundsApi} from '@/api/funds_api'
	import {DateTime} from 'luxon'
	import { Bar } from 'vue-chartjs'
	import { pieChartColorPalette } from '@/utils/chart_utils'
	import { Ticks } from 'chart.js'
	import TablePlaceholder from '../skeletonLoaders/TablePlaceholder.vue'
	import PieChartPlaceholder from '../skeletonLoaders/PieChartPlaceholder.vue'
	import BarGraphPlaceholder from '../skeletonLoaders/BarGraphPlaceholder.vue'
	
	export default {
		inheritAttrs: false,
		
		mixins: [],
		
		components: {
			Bar,
			FundChart,
			BreadCrumb,
			InvestmentsSidebar,
			PillControl,
			TablePlaceholder,
			PieChartPlaceholder,
			BarGraphPlaceholder
		},

		setup() {
			const fundStore = useFundStore()
			return {
				fundStore
			}
		},

		filters: {
		},
		
		provide: function() {
			return {
			}
		},
		
		inject: [],
		
		props: [],
		
		data() {
			return {
				tableHeadings: [{
					text: 'Investment',
					value: 'investment'
				}, {
					text: 'Status',
					value: 'status'
				}, {
					text: '# Add-ons',
					value: 'numAddons',
				}, {
					text: 'Equity FMV',
					value: 'equityFmv'
				}, {
					text: 'LTM Revenue',
					value: 'ltmRevenue',
				}, {
					text: 'LTM EBITDA',
					value: 'ltmEbitda'
				}, {
					text: 'LTM Gross Margin',
					value: 'ltmGrossMargin'
				}, {
					text: 'LTM EBITDA Margin',
					value: 'ltmEbitdaMargin'
				}],
				fundData: {},
				fundInvestments: [],
				investmentFilter: null,
				dataLoading: true,
				rows_per_page: 25
			}
		},
		
		computed: {
			hasData() {
				return this.fundData !== null && this.fundData.funds_data != null
			},

			hideFooter() {
				return this.fundTableItems.length <= this.rows_per_page
			},

			breadcrumbData() {
				// TODO before fund name?
				return [
					{
						linkText: 'Investments',
						routerLinkName: 'InvestmentsView'
					},
					{
						linkText: this.fund.name
					}
				]
			},

			valueStatusChartData() {
				return {
					labels: ['Realized', 'Unrealized'],
					datasets: [
						{
							backgroundColor: pieChartColorPalette(2),
							data: [this.realizedAmount, this.unrealizedAmount]
						}
					]
				}
			},

			investmentPerformanceChartData() {
				console.log('fundInvestments', this.fundInvestments)
				return {
					labels: this.fundInvestments.map(d => d.name),
					datasets: [
						{
							label: 'Entry',
							data: this.fundInvestments.map(d => (d.performance_values?.ebitdaAtEntry / 1000000)),
							backgroundColor: '#257456'
						},
						{
							label: 'Latest',
							data: this.fundInvestments.map(d => (d.performance_values?.ebitdaLatest / 1000000)),
							backgroundColor: '#71c7a3'
						}
					]
				}
			},

			investmentPerformanceChartConfig() {
				return {
					responsive: true,
					maintainAspectRatio: false,
					plugins: {
						legend: {
							position: 'top',
							align: 'end',
							labels: {
								boxWidth: 28
							}
						}
					},
					scales: {
						y: {
							ticks: {
								callback: function (value, index, ticks) {
									return Ticks.formatters.numeric.apply(this, [value, index, ticks]) + 'mm';
								}
							}
						}
					}
				}
			},

			realizedAmount() {
				return this.fundData?.equity_value ? this.fundData.equity_value.bertram_realized_equity_value : 0
			},

			unrealizedAmount() {
				return this.fundData?.equity_value ? this.fundData.equity_value.bertram_unrealized_fmv_equity_value : 0
			},

			realizedAmountDollars() {
				return formatDollars(this.realizedAmount, {scale: 'mm'})
			},

			unrealizedAmountDollars() {
				return formatDollars(this.unrealizedAmount, {scale: 'mm'})
			},

			realizedPercent() {
				return ( (this.realizedAmount / (this.realizedAmount + this.unrealizedAmount)) * 100).toFixed(1)
			},

			unrealizedPercent() {
				return ( (this.unrealizedAmount / (this.realizedAmount + this.unrealizedAmount)) * 100).toFixed(1)
			},

			valueStatusConfig() {
				return {
					responsive: true,
					maintainAspectRatio: false,
					plugins: {
						legend: {
							display: false
						}
					}
				}
			},

			activeFundInvestments() {
				return this.fundInvestments?.filter(i => i.status === 'Active') || []
			},

			fundAmtInvested() {
				return this.fundData?.funds_data?.Invested || null
			},

			fundFees() {
				return this.fundData?.funds_data?.Fees || null
			},

			fundAmtAvailable() {
				return this.fundData?.funds_data?.Available || null
			},

			fundAmtExpenses() {
				return this.fundData?.funds_data?.Expenses || null
			},
			
			fund() {
				if(!this.fundData) {
					return null
				} else {
					return {
						id: this.fundData.id,
						name: this.fundData.name,
						...this.fundData.funds_data
					}
				}
			},

			fundSidebarTitle() {
				if(this.hasData) {
					return `Active ${this.fund.name} Investments`
				} else {
					return ''
				}
			},

			fundTableItems() {
				return this.fundInvestments.filter(inv => this.investmentFilter ? this.investmentFilter === inv.status : true).map(inv => {
					// get financials sorted so most recent is first
					const mostRecent = inv.financials ? inv.financials[0].data : {}

					return {
						investmentId: inv.id,
						investment: inv.name || '-',
						status: inv.status || '-',
						numAddons: (inv.acquisitions?.length ? inv.acquisitions.length - 1 : 0) || '-',  // exclude the initial investment as an add-on, for display purposes
						equityFmv: formatDollars(mostRecent['Bertram Equity FMV']),
						ltmRevenue: formatDollars(mostRecent['LTM Revenue']),
						ltmEbitda: formatDollars(mostRecent['LTM EBITDA']),
						ltmGrossMargin: formatPercentage(mostRecent['LTM Gross Margin'], {precision: 1}),
						ltmEbitdaMargin: formatPercentage(mostRecent['LTM EBITDA Margin'], {precision: 1})
					}
				})
			}
		},
		
		watch: {
			
		},
		
		created() {
		},
		
		mounted() {
			this.loadFundData()
		},
		
		methods: {
			loadFundData() {
				this.dataLoading = true
				const fundsApi = new FundsApi()
				Promise.all([
					fundsApi.getFund(this.$route.params.fundId),
					fundsApi.getFundInvestments({fundId: this.$route.params.fundId, limit: 1000, offset: 0}),
				])
					.then(([fund, investments]) => {
						this.fundData = fund.data

						investments.data.forEach(inv => {
							if(inv.financials) {
								inv.financials = inv.financials.sort((fin1, fin2) => DateTime.fromISO(fin2.date).toJSDate() - DateTime.fromISO(fin1.date).toJSDate())
								inv.mostRecentFinancials = inv.financials[0]
							}
						})
						this.fundInvestments = investments.data
					}).finally(() => {
						this.dataLoading = false
					})
					.catch(err => {
						console.error('error getting fund', err)
					})
			},
			
			pillNavClicked(statusFilter) {
				console.log('pillNavClicked', arguments)
				this.investmentFilter = statusFilter
			}
		}
	}
</script>

<style lang="scss">
.title {
	margin-left: 10px;
	margin-bottom: 16px
}
</style>
