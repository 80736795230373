<template>
	<span>{{ formatName }}</span>
</template>

<script type="text/javascript">
import {formatUserName} from '@/utils/user_utils'

export default {
	inheritAttrs: false,
	name: 'FormatUserName',

	props: ['name'],

	computed: {
		formatName: {
			get() {
				return formatUserName(this.name)
			}
		}
	}
}
</script>

<style lang="scss">
</style>
