import { createApp } from 'vue'
import 'bootstrap'
import App from './App.vue'
import router from '../../router'
import store from '../../stores'
import {getAuth, signOut} from 'firebase/auth'
import "@/styles/equivate.scss";
import { getFunctions, httpsCallable } from "firebase/functions";

// initialize the firebase app
import '@/firebaseServices/firestore'

// font-awesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {createPinia} from "pinia/dist/pinia";
import { useUserStore } from '@/stores/user'
import { useFundStore } from '@/stores/fund'

// data table
import Vue3EasyDataTable from 'vue3-easy-data-table'
import 'vue3-easy-data-table/dist/style.css'

getAuth().onAuthStateChanged(async (user) => {
	console.log("authSTATE CHANGED")
	const pinia = createPinia()
	if (user) {
		store.commit("setUser",user)
		store.commit("setTenantId", user.tenantId);

		const userStore = useUserStore(pinia)
		const fundStore = useFundStore(pinia)

		// No tenantId?!.. gotta log them out
		if(!user.tenantId) {
			const auth = getAuth();
			signOut(auth).then(() => {
				location.reload()
			}).catch((error) => {
				console.log("error on signout: " + error);
			});
		}

		await Promise.all([
			userStore.initializeUsers(),
			fundStore.initializeFunds(),
			getAuth().currentUser.getIdTokenResult()
				// Store whether the user is an admin
				.then((idTokenResult) => {
					store.commit("setAdmin", idTokenResult.claims.admin);
					if(idTokenResult.claims.firebase && idTokenResult.claims.firebase.tenant) {
						// This calls a cloud function to keep our users collection sync'd with the auth provider
						const functions = getFunctions();
						const onUserLogIn = httpsCallable(functions, 'onUserLogIn');
						onUserLogIn({});
					}
				})
				.catch((error) => {
					store.commit("setAdmin", false);
					console.log(`error in getting user token: ${error}`);
				})
		]);
	} else {
		store.commit("setUser",null)
		store.commit("setAdmin", false);
	}

	const app = createApp(App);
	library.add(faCheck)
	library.add(faPlus)
	library.add(faUser)
	app.component('font-awesome-icon', FontAwesomeIcon)
	app.component('EasyDataTable', Vue3EasyDataTable)

	app.config.globalProperties.$userCan = function(subject, action) {
		console.log(`userCan: ${subject},${action}`);
		return this.$store.state.isAdmin;
	}

	app.config.globalProperties.$tenantId = function() {
		return this.$store.state.tenantId;
	}
	app.use(pinia)
	app.use(router);
	router.beforeEach((to, from, next) => {
		if (to.meta && to.meta.title) {
			document.title = to.meta.title;
		} else {
			document.title = 'Equivate';
		}
		next();
	});
	app.use(store);
	app.mount('#app')
});
