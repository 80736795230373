<template>
	<div>
		<div class="row">
			<div class="col p-0">
				<div class="mb-3 px-3 d-flex justify-content-between">
					<h3>Data Import History</h3>
					<router-link :to="{name: 'DataCollectionView'}" class="d-inline-block" v-if="dataImports.length > 0">View All</router-link>
				</div>
				<ol class="list-unstyled" v-if="dataImports.length > 0">
					<li class="p-3" v-for="(dataImport, index) in dataImports" :key="dataImport.id" :class="{'alt-bg': isOdd(index)}">
						<data-import-listing
							:import-id="dataImport.id"
							:title="dataImport.title"
							:imported-date="dataImport.importedDate"
							:imported-by="dataImport.importedBy"
							:status="dataImport.status"
							:download-url="dataImport.url" />
					</li>
				</ol>
				<ol class="list-unstyled" v-else>
					<li class="px-3 pt-1 pb-1 my-4" v-for="index in 15" :key="index">
						<div class="placeholder-wave d-flex justify-content-between mb-2">
							<span class="placeholder col-8"></span>
							<span class="placeholder col-3"></span>
						</div>
						<div class="placeholder-wave d-flex justify-content-between">
							<span class="placeholder col-6"></span>
							<span class="placeholder col-2"></span>
						</div>

					</li>
				</ol>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	
	import DataImportListing from '@/views/dashboard/sidebar/DataImportListing.vue'
	import {UploadsApi} from '@/api/uploads_api'
	import {DateTime} from 'luxon'

	export default {
		name: 'DashboardSidebar',
		inheritAttrs: false,
		
		mixins: [],
		
		components: {
			// ReportListing,
			DataImportListing
		},
		
		filters: {
		},
		
		provide: function() {
			return {
			}
		},
		
		inject: [],
		
		props: {},
		
		data() {
			return {
				reportsData: [],
				dataImports: {imports:[]}
			}
		},
		
		computed: {
			
		},
		
		watch: {
			
		},
		
		created() {
		},
		
		mounted() {
			//this.loadReportsData()
			this.loadDataImportData()
		},
		
		methods: {
			isOdd(index) {
				return (index % 2) === 1
			},
			
			loadDataImportData() {
				/*
				const dtConverter = (seconds) => {
					return DateTime.fromSeconds(seconds).toJSDate()
					}
					*/

				UploadsApi.getUploads({limit: 12})
					.then(resp => {
						console.log('uploads resp', resp)
						this.dataImports = resp.data.map(uploadRec => ({
							id: uploadRec.UploadId,
							title: uploadRec.upload_data.fileName,
							importedDate: DateTime.fromISO(uploadRec.date_created).toJSDate(),
							importedBy: uploadRec.upload_data.uploadingUser,
							status: uploadRec.upload_data.status,
							url: uploadRec.upload_data.url
						}))
					})
			},
			
			loadReportsData() {
				return [
					{
						id: 1,
						title: 'Q4 Report Title',
						scheduledDate: new Date(),
						description: 'Shows revenue, gross IRR, and EBITDA of all active investments'
					},
					{
						id: 2,
						title: 'Q4 Report Title',
						runDate: new Date(),
						description: 'Shows revenue, gross IRR, and EBITDA of all active investments'
					},
					{
						id: 3,
						title: 'Q4 Report Title',
						runDate: new Date(),
						description: 'Shows revenue, gross IRR, and EBITDA of all active investments'
					},
					{
						id: 4,
						title: 'Q4 Report Title',
						runDate: new Date(),
						description: 'Shows revenue, gross IRR, and EBITDA of all active investments'
					},
					{
						id: 5,
						title: 'Q4 Report Title',
						runDate: new Date(),
						description: 'Shows revenue, gross IRR, and EBITDA of all active investments'
					},
				]
			}
		}
	}
</script>

<style lang="scss" scoped>

</style>