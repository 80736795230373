<template>
	<modal-dialog :visible="visible" @close="close">
		<template v-slot:header>Download a Template</template>
		<template v-slot:body>
			<p class="text">The format of the financial data must follow a specified Excel template.</p>
			<hr/>
			<h3>New Investment</h3>
			<p class="text">Start 'from scratch' with a blank template</p>
			<div class="d-flex justify-content-end">
				<a class="btn btn-secondary" href="#" v-on:click.prevent="downloadTemplate">Download Blank Template</a>
			</div>
			<hr/>
			<h3>Existing Investment</h3>
			<p class="text">Download the latest spreadsheet for an existing investment and add the appropriate columns to the financials sheet</p>

			<div class="d-flex justify-content-md-between">
				<drop-down :options="companies" v-on:option-selected="onCompanySelected"></drop-down>
				<a class="btn btn-secondary" href="#" v-on:click.stop v-on:click.prevent="downloadFile" :class="{disabled: noSelectedCompany}" v-bind:data-url="companyFinancialsUrl" v-bind:data-filename="companyFinancialsFileName">Download Last Spreadsheet</a>
			</div>
			<div class="field-error" v-if="showNoDownloadsError"><p>Company does not have any uploaded spreadsheets</p></div>
		</template>
	</modal-dialog>

</template>

<script type="text/javascript">

	import ModalDialog from "@/views/shared/ModalDialog";
	import DropDown from "@/views/shared/DropDown";
	import {getDownloadURL, getStorage, ref} from "firebase/storage";
	import StorageConstants from "@/utils/storage_constants";
	import {downloadFile} from "@/utils/file_utils";
	import FileDownload from "@/mixins/FileDownload";
	import {UploadsApi} from "@/api/uploads_api";

	export default {
		name: 'DownloadTemplateModal',
		inheritAttrs: false,
		emits: ['close'],
		mixins: [FileDownload],

		components: {
			ModalDialog,
			DropDown
		},

		filters: {
		},

		provide: function() {
			return {
			}
		},

		inject: [],

		props: ['visible','companies'],

		data() {
			return {
				selectedCompanyId: null,
				companyFinancialsUrl: null,
				companyFinancialsFileName: null
			}
		},

		computed: {
			noSelectedCompany() {
				return this.companyFinancialsUrl == null
			},
			showNoDownloadsError() {
				return this.selectedCompanyId != null && this.companyFinancialsUrl == null
			}
		},

		watch: {

		},

		created() {
		},

		mounted() {
		},

		methods: {
			close() {
				this.$emit('close');
			},

			async onCompanySelected(companyId) {
				// Fetch the latest uploaded spreadsheet for the company selected
				const uploads = await UploadsApi.getUploadsForObject({objectId: companyId, limit: 1, status: 'COMPLETE'})
				if(uploads.data.totalRows > 0) {
					const latestUpload = uploads.data.uploads[0]
					this.companyFinancialsFileName = latestUpload.upload_data.fileName
					this.companyFinancialsUrl = latestUpload.upload_data.url
				} else {
					this.companyFinancialsFileName = null
					this.companyFinancialsUrl = null
				}
			},
			async downloadTemplate() {
				const storage = getStorage(),
						storeRef = ref(storage, StorageConstants.FINANCIALS_UPLOAD_TEMPLATE_PATH),
						dlUrl = await getDownloadURL(storeRef)

				downloadFile({fileName: StorageConstants.FINANCIALS_UPLOAD_TEMPLATE_FILENAME, url: dlUrl, immediate: false}).download()
			},
			async downloadLastTemplate() {

			}
    }
	}
</script>

<style lang="scss">
</style>
