<template>
	<span>{{formattedPercentage}}</span>
</template>

<script type="text/javascript">

	import {formatPercentage} from '@/utils/percent_utils'

	export default {
		inheritAttrs: false,
    name: 'FormatPercent',

		props: {
			number: {
				type: Number
			},

			precision: {
				type: Number,
				default() {
					return 0
				}
			},

			separator: {
				type: String,
				default() {
					return ''
				}
			},

			ifNull: {
				type: String,
				default() {
					return '-%'
				}
			}
		},
		
		computed: {
			formattedPercentage() {
				return formatPercentage(this.number, {precision: this.precision, separator: this.separator, ifNull: this.ifNull})
      }
		}
	}
</script>

<style lang="scss">
</style>
