<template>
	<modal-dialog :visible="visible" @close="close">
		<template v-slot:header>Errors</template>
		<template v-slot:body>
			<div class="p-3">
				<div class="errors-summary mb-2">{{errorsSummary()}}</div>
				<div class="errors-container p-1">
					<div v-for="err in validationErrors" :key="err.msg">{{formatErrorMessage(err)}}</div>
				</div>
			</div>
		</template>
	</modal-dialog>
</template>

<script type="text/javascript">

	import ModalDialog from "@/views/shared/ModalDialog";

	export default {
		name: 'ValidationErrorsModal',
		inheritAttrs: false,
		emits: ['close'],
		mixins: [],
		components: {
			ModalDialog
		},

		filters: {
		},

		provide: function() {
			return {
			}
		},

		inject: [],

		props: ['visible','validationErrors'],

		data() {
			return {
			}
		},

		computed: {
		},

		watch: {

		},

		created() {
		},

		mounted() {
		},

		methods: {
			errorsSummary() {
				if(this.validationErrors.length == 1) {
					return `1 Error`
				} else if (this.validationErrors.length > 1) {
					return `${this.validationErrors.length} Errors`
				} else {
					return ''
				}
			},

			formatErrorMessage(err) {
				let msg = err.msg
				if(err.sheetName) {
					msg = `${msg} (${err.sheetName})`
				}
				return msg
			},

			close() {
				this.$emit('close')
			}
    }
	}
</script>

<style lang="scss">
@import '../../styles/custom-variables';
.errors-summary {
	font-size: 13px;
	color: $black;
}
.errors-container {
	border: 1px solid $midgray;
	height: 10rem;
	overflow: auto;
	color: $red;
}
</style>
