<template>
	<div>
		<h1>Todo</h1>
	</div>
</template>

<script type="text/javascript">
	

	export default {
		name: 'MappingView',
		inheritAttrs: false,

		mixins: [],

		components: {
		},

		filters: {
		},

		provide: function() {
			return {
			}
		},

		inject: [],

		props: [],

		data() {
			return {
			
			}
		},

		computed: {

		},

		watch: {

		},

		created() {
		},

		mounted() {
		},

		methods: {
		}
	}
</script>

<style lang="scss">
</style>
