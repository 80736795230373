<template>
	<modal-dialog :visible="visible" @close="close">
		<template v-slot:header>Request Updated Financials</template>
		<template v-slot:body>
			<form v-if="configuringRequest" v-on:submit.prevent="onFormSubmit" class="p-3">
				<div class="alert alert-danger mb-3" v-if="errorOnSubmit">
						{{ errorOnSubmit }}
				</div>
				<div class="alert alert-danger mb-3" v-if="hasSelectedNoAssociates">
					The following selected investments have no assigned associates: {{ selectedNoAssociatesNames }}
				</div>
				<div class="mb-3">
					<fund-investments-selector :investment-id="investmentId" v-model:fundsSelected="fundsSelected" v-model:investmentsSelected="investmentsSelected" ></fund-investments-selector>
				</div>
				<div class="row">
					<div class="col-12">
						<label class="form-label">Notes on Request</label>
						<textarea rows="5" class="form-control" v-model="message"/>
					</div>
				</div>
			</form>
			<div v-if="hasRequestResult" class="request-sent">
				<h2 class="mt-4 mb-4">Request Sent!</h2>
				<div class="request-result mb-3" v-for="result in this.requestResult.emails" :key="result.investment">
					<div>Requested:</div>
					<div>{{result.investment}}</div>
					<div class="mt-2">Sent to:</div>
					<div>{{result.recipients}}</div>
				</div>
			</div>
		</template>
		<template v-slot:footer>
			<button v-if="configuringRequest" class="btn btn-primary" v-on:click="onFormSubmit" :class="addButtonClass">Request</button>
		</template>
	</modal-dialog>
</template>

<script type="text/javascript">

	import {RequestDataApi} from "@/api/request_data_api";
	import {InvestmentsApi} from "@/api/investment_api";
	import ModalDialog from "@/views/shared/ModalDialog";
	import FundInvestmentsSelector from "@/views/shared/FundInvestmentsSelector";

	export default {
		name: 'RequestUpdateModal',
		inheritAttrs: false,
		emits: ['close'],
		mixins: [],
		components: {
			FundInvestmentsSelector,
			ModalDialog,
		},

		filters: {
		},

		provide: function() {
			return {
			}
		},

		inject: [],

		props: {
			visible: {
				type: Boolean
			},
			investmentId: {
				type: String
			}
		},

		setup() {
		},

		data() {
			return {
				name: '',
				errorOnSubmit: null,
				fundsSelected: [],
				investmentsSelected: [],
				sending: false,
				requestResult: null,
				message: '',
				selectedNoAssociates: [], // selected investments with no associates assigned (ids)
				processingAssociates: false,
			}
		},

		computed: {
			configuringRequest() {
				return this.requestResult == null
			},

			hasRequestResult() {
				return this.requestResult != null
			},

			addButtonClass() {
				return (this.sending == true || this.investmentsSelected.length == 0 || this.processingAssociates || this.selectedNoAssociates.length != 0) ? 'disabled' : ''
			},

			hasSelectedNoAssociates() {
				return this.selectedNoAssociates.length > 0
			},

			selectedNoAssociatesNames() {
				let names = ''
				for (const investment of this.investmentsSelected) {
					let index = this.selectedNoAssociates.indexOf(investment.id)
					if (index >= 0) {
						if (names.length > 0) {
							names += ', '
						}
						names += investment.name
					}
				}
				return names
			}
		},

		watch: {
			investmentsSelected: async function(newInvestments, oldInvestments) {
				// since investments have updated, we need to check if the change means that we should update if all investments have associates.
				if (newInvestments.length == 0) {
					// data may have been cleared by changing selected fund
					this.selectedNoAssociates = []
				}
				else if (newInvestments.length > oldInvestments.length) {
					// adding an investment, we need to track if new investment has no associates
					let newInvestment = newInvestments.filter(x => !oldInvestments.includes(x))[0]
					console.log("adding investment", newInvestment)
					this.processingAssociates = true
					await InvestmentsApi.getInvestmentUsers(newInvestment.id).then(users => {
						if (users.data.investment_financials_uploader == null) {
							this.selectedNoAssociates.push(newInvestment.id)
						}
					})
					this.processingAssociates = false
				} else if (oldInvestments.length > newInvestments.length && this.selectedNoAssociates.length != 0) {
					// removing an investment, is this investment in the no associates list? If so we can remove it
					let removedInvestment = oldInvestments.filter(x => !newInvestments.includes(x))[0]
					console.log("removing investment", removedInvestment)
					let index = this.selectedNoAssociates.indexOf(removedInvestment.id)
					if (index > -1) {
						this.selectedNoAssociates.splice(index, 1)
					}
				}
				console.log("selected investments with no associates:", this.selectedNoAssociates)
			}
		},

		created() {
		},

		mounted() {
		},

		methods: {
			reset() {
				this.requestResult = null
				this.sending = false
				this.errorOnSubmit = null
				this.fundsSelected = []
				this.investmentsSelected = []
				this.message = ''
				this.selectedNoAssociates = []
				this.processingAssociates = false
			},

			async onFormSubmit() {
				this.errorOnSubmit = null
				this.sending = true


				const result = await RequestDataApi.requestData({
					requestorId: this.$store.state.user.uid,
					requestorDisplayName: this.$store.state.user.displayName,
					investments: this.investmentsSelected,
					message: this.message
				})

				// const result = {success: true, msg: '', emails: [
				// 		{
				// 			investment: 'Burke',
				// 			recipients: 'Bob Whiton'
				// 		},
				// 		{
				// 			investment: 'Lectric',
				// 			recipients: 'Bob Whiton, Jeremy Crosbie'
				// 		}
				// 	]}
				console.log(`requestResult: ${result}`)

				this.sending = false
				if(result.data && result.data.success == true) {
					this.requestResult = result
					this.close()
				} else {
					this.errorOnSubmit = result.msg
					this.requestResult = null
				}
			},

			close() {
				this.reset()
				this.$emit('close')
			}
    }
	}
</script>

<style lang="scss">
@import '../../styles/custom-variables';
.request-sent {
	text-align: center;
}
</style>
