
function downloadFile({fileName, url, immediate=true}={}) {
	if(!fileName) {
		throw new Error('fileName is required')
	}
	if(!url) {
		throw new Error('url is required')
	}
	const dlAnchor = document.createElement('a')

	dlAnchor.href = url
	dlAnchor.download = fileName
	document.body.appendChild(dlAnchor)

	const dlObj = {
		download: () => {
			dlAnchor.click()
			document.body.removeChild(dlAnchor)
		}
	}

	if(immediate) {
		dlObj.download()
	} else {
		return dlObj
	}
}

export {downloadFile}
