import db from "@/firebaseServices/firestore";
import {
	collection,
	getDocs,
	deleteDoc
} from "firebase/firestore";
import {buildCollectionPath} from './firestore'
import {getDownloadURL, getStorage, ref} from "firebase/storage";
import FinancialsDataService from "@/firebaseServices/FinancialsDataService";

export default {
	async reset() {
		console.log(`reset`);

		const collectionsToDeleted = [
			'funds_data',
			'fund_returns',
			'investment_financials_over_time',
			'investment_financials_data',
			'investment_latest_financials_data',
			'investment_latest_acquisitions_data',
			'investment_latest_coinvest_data',
			'investment_latest_company_data',
			'investment_latest_financials_data',
			'investment_latest_lenders_data'
		]

		for(const collectionName of collectionsToDeleted) {
			console.log(`deleting ${collectionName}`)
			const querySnapshot = await getDocs(collection(db, buildCollectionPath(collectionName)));
			querySnapshot.forEach(async (doc) => {
				await deleteDoc(doc.ref)
			});
		}
	},

	async regnerateDownloadURLs() {
		const storage = getStorage();
		const querySnapshot = await getDocs(collection(db, buildCollectionPath('financials')));
		querySnapshot.forEach(async (doc) => {
			console.log(`working on financials doc ${doc.id} with path ${doc.data().fullPath}`)
			let storageRef = ref(storage, doc.data().fullPath)
			let downloadUrl = await getDownloadURL(storageRef);
			console.log(`... downloadUrl is now ${downloadUrl} (was ${doc.data().url})`)
			await FinancialsDataService.update(doc.id, {'url': downloadUrl})
		});
	}
}