<template>
	<modal-dialog :visible="visible" @close="close">
		<template v-slot:header>Investment Data Ownership</template>
		<template v-slot:body>
			<form v-on:submit.prevent="onFormSubmit" class="p-3">
				<div class="alert alert-danger mb-3" v-if="errorOnSubmit">
					{{ errorOnSubmit }}
				</div>

				<div class="mb-3 p-3">
					<fund-investments-selector
							:multi-select="false"
							:read-only="false"
							funds-placeholder="Select Fund"
							investments-placeholder="Select Investment"
							:investment-id="investmentId"
							v-model:investmentsSelected="investmentsSelected"
							@investments-changed="investmentsChanged">
					</fund-investments-selector>
				</div>
				<div class="mb-3 p-3">
					<label class="form-label">Data Collection Owners</label>
					<VueMultiselect
							v-model="internalUploaders"
							:multiple="true"
							placeholder="Select"
							label="name"
							:close-on-select="false"
							track-by="name"
							:options="users">
					</VueMultiselect>
				</div>
				<div class="mb-3 p-3">
					<label class="form-label">Data Verification Owners</label>
					<VueMultiselect
							v-model="internalVerifiers"
							:multiple="true"
							placeholder="Select"
							label="name"
							:close-on-select="false"
							track-by="name"
							:options="users">
					</VueMultiselect>
				</div>
			</form>
		</template>
		<template v-slot:footer>
			<button class="btn btn-primary" v-on:click="onFormSubmit" :class="saveButtonClass">Save</button>
		</template>
	</modal-dialog>

</template>

<script type="text/javascript">

	import ModalDialog from "@/views/shared/ModalDialog";
	import FundInvestmentsSelector from "@/views/shared/FundInvestmentsSelector"
	import {useUserStore} from "@/stores/user";
	import {InvestmentsApi} from "@/api/investment_api";
	import VueMultiselect from 'vue-multiselect'

	export default {
		name: 'EditAssociatesModal',
		inheritAttrs: false,
		emits: ['close'],
		mixins: [],
		components: {
			FundInvestmentsSelector,
			VueMultiselect,
			ModalDialog
		},

		filters: {
		},

		provide: function() {
			return {
			}
		},

		inject: [],

		props: [],

		setup() {
			const userStore = useUserStore()
			return {
				userStore
			}
		},

		data() {
			return {
				visible: false,
				saving: false,
				errorOnSubmit: null,
				users: [],
				investmentId: null,
				investmentsSelected: [],
				internalUploaders: [],
				internalVerifiers: []
			}
		},

		computed: {
			saveButtonClass() {
				return this.saving == true || this.investmentId == null ? 'disabled' : ''
			}
		},

		watch: {

		},

		created() {
		},

		mounted() {
		},

		methods: {
			show(investmentId) {
				this.investmentId = investmentId
				this.loadOwners()
				this.visible = true

				this.users = this.userStore.$state.users.map((user) => {
					return {id: user.id, name: user.data().name}
				})
			},

			hide() {
				this.visible = false
				this.saving = false
				this.errorOnSubmit = null
				this.investmentId = null
				this.users = []
				this.internalUploaders = []
				this.internalVerifiers = []
			},

			async investmentsChanged(investmentsSelected) {
				if(investmentsSelected != null && investmentsSelected.length > 0) {
					this.investmentId = investmentsSelected[0].id
				} else {
					this.investmentId = null
				}
				return this.loadOwners()
			},

			async loadOwners() {
				this.internalUploaders = []
				this.internalVerifiers = []
				if(this.investmentId) {
					return InvestmentsApi.getInvestmentUsers(this.investmentId).then((response) => {
						if(response.data.investment_financials_uploader) {
							this.internalUploaders = response.data.investment_financials_uploader.map( (user) => {
								return {id: user.user_id, name: user.name}
							}) ?? []
						}

						if(response.data.investment_financials_verifier) {
							this.internalVerifiers = response.data.investment_financials_verifier.map( (user) => {
								return {id: user.user_id, name: user.name}
							}) ?? []
						}
					})
				}
			},

			async onFormSubmit() {
				this.errorOnSubmit = null
				this.saving = true
				const payload = {
					investmentId: this.investmentId,
					users: []
				}
				for(const user of this.internalUploaders) {
					payload.users.push({userId: user.id, roleCode: 'investment_financials_uploader'})
				}
				for(const user of this.internalVerifiers) {
					payload.users.push({userId: user.id, roleCode: 'investment_financials_verifier'})
				}

				return InvestmentsApi.setInvestmentUsers(payload).then(() => {
					this.saving = false
					this.$emit('close', this.investmentId)
				}).catch((error) => {
					this.errorOnSubmit = error
				});
			},

			close() {
				this.$emit('close')
			}
    }
	}
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style lang="scss">
@import '../../../styles/custom-variables';
.multiselect__select::before {
	border-color: $black transparent transparent transparent;
}
.multiselect__tags {
	border-radius: 0px;
	.multiselect__placeholder {
		color: $black
	}
}
.multiselect, .multiselect__input, .multiselect__single {
	font-size: 14px;
}
</style>