<template>
	<modal-dialog :visible="visible" @close="close">
		<template v-slot:header>About Equivate</template>
		<template v-slot:body>
			<div>Version: {{version}}</div>
			<div>Commit: {{commitHash}}</div>
		</template>
	</modal-dialog>

</template>

<script type="text/javascript">

	import ModalDialog from "@/views/shared/ModalDialog";

	const version = process.env.VUE_APP_VERSION
	const commitHash = process.env.VUE_APP_COMMIT_HASH.toString()

	export default {
		name: 'AboutModal',
		inheritAttrs: false,
		emits: ['close'],
		mixins: [],
		components: {
			ModalDialog
		},

		props: ['visible'],

		data() {
			return {
				version: version,
				commitHash: commitHash
			}
		},

		methods: {
			close() {
				this.$emit('close')
			}
    },

		created() {
		}
	}
</script>

<style lang="scss">
@import '../../styles/custom-variables';
</style>
