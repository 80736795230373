<template>
	<div>
		<div class="d-flex justify-content-between m-1">
			<h5 v-if="canDownload" class="m-0"><a href="#" v-on:click.stop v-on:click.prevent="downloadFile" v-bind:data-url="downloadUrl" v-bind:data-filename=title>{{ title }}</a></h5>
			<h5 v-else class="m-0 graphite">{{title}}</h5>
			<p class="m-0"><format-date :date="importedDate" from-format=""/></p>
		</div>
		<div class="d-flex justify-content-between m-1">
			<h6 class="m-0">{{importedByString}}</h6>
			<h6 class="m-0"><p class="m-0">{{ formatStatus(status) }}</p></h6>
		</div>
	</div>
</template>

<script type="text/javascript">
	import FormatDate from '@/views/utils/FormatDate'
	import {formatUserName} from '@/utils/user_utils'
	import {formatStatus} from "@/utils/string_utils"
	import FileDownload from "@/mixins/FileDownload";

	export default {
		inheritAttrs: false,

		mixins: [FileDownload],

		components: {
			FormatDate
		},

		filters: {
		},

		provide: function() {
			return {
			}
		},

		inject: [],

		props: {
			importId: {
				type: String,
				required: true
			},
			title: {
				type: String,
				required: true
			},
			importedBy: {
				type: String,
				required: true
			},
			importedDate: {
				type: Date,
				required: false
			},
			status: {
				type: String,
				required: true
			},
			downloadUrl: {
				type: String,
				required: true
			}
		},

		data() {
			return {
			
			}
		},

		computed: {
			importedByString() {
				return `Imported by ${formatUserName(this.importedBy)}`
			},
			canDownload() {
				return this.status != 'DELETED' && this.status != 'REQUESTED'
			},
		},

		watch: {

		},

		created() {
		},

		mounted() {
		},

		methods: {
			formatStatus
		}
	}
</script>

<style lang="scss" scoped>
	@import '../../../styles/custom-variables';
	h5 {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		max-width: 222px;
	}
	p {
		color: $graphite;
		padding-right: 10px;
		padding-left: 5px;
	}
	h6 {
		color: $text-alternate;
	}
</style>
