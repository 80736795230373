import { getFunctions, httpsCallable } from "firebase/functions";

const InvestmentsApi = function() {
}

InvestmentsApi.getInvestment = (invId) => {
	return httpsCallable(getFunctions(), 'getInvestment')(invId)
}

InvestmentsApi.getInvestmentUsers = (invId) => {
	return httpsCallable(getFunctions(), 'getInvestmentUsers')(invId)
}

InvestmentsApi.getInvestmentsUsers = (data) => {
	return httpsCallable(getFunctions(), 'getInvestmentsUsers')(data)
}

InvestmentsApi.setInvestmentUsers = (data) => {
	return httpsCallable(getFunctions(), 'setInvestmentUsers')(data)
}

InvestmentsApi.updateInvestment = (data) => {
	return httpsCallable(getFunctions(), 'updateInvestment')(data)
}

InvestmentsApi.createInvestment = (data) => {
	return httpsCallable(getFunctions(), 'createInvestment')(data)
}

InvestmentsApi.deleteInvestment = (invId) => {
	return httpsCallable(getFunctions(), 'deleteInvestment')(invId)
}

InvestmentsApi.getInvestments = ({limit=100, offset=0, status=null, sort='name', direction='asc'}={}) => {
	return httpsCallable(getFunctions(), 'getInvestments')({limit, offset, status, sort, direction})
}

InvestmentsApi.getPerformance = () => {
	return httpsCallable(getFunctions(), 'getPerformance')()
}

InvestmentsApi.validateUpload = (data) => {
	return httpsCallable(getFunctions(), 'validateUpload')(data)
}

export {InvestmentsApi}
