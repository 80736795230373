import db from "@/firebaseServices/firestore";
import {
	addDoc,
	collection,
	doc,
	getDoc,
	getDocs,
	runTransaction,
	query,
	updateDoc,
	where
} from "firebase/firestore";
import {buildCollectionPath} from './firestore'

const COLLECTION_NAME = 'financials'

export default {

	async create(company, fileName, downloadURL, uploadedBy, bucket, fullPath) {
		console.log(`Creating file ${company} ${fileName} ${downloadURL}`);

		let data = {primaryFileName: fileName, url: downloadURL, company: company, bucket: bucket, fullPath: fullPath}

		// If existing, update previous currentVersion to false
		let activeDocument = await this.getFile(company);
		try {
			let docRefId = null
			await runTransaction(db, async (transaction) => {
				let revision = 1
				if(activeDocument != undefined) {
					console.log(`have previous active document... ${activeDocument.id}`)
					revision = activeDocument.data().revision + 1
					await transaction.update(doc(db, buildCollectionPath(COLLECTION_NAME), activeDocument.id), {currentVersion: false});
				}

				data.dateCreated = new Date()
				data.uploadedBy = uploadedBy
				data.status = 'Pending Approval'
				data.revision = revision
				data.currentVersion = true
				const docRef = await addDoc(collection(db, buildCollectionPath(COLLECTION_NAME)), data);
				console.log("New document written with ID: " + docRef.id);

				console.log("docRef.id " + docRef.id)
				docRefId = docRef.id
			});
			console.log("Transaction successfully committed!");
			return Promise.resolve(docRefId)
		} catch (e) {
			console.log("Transaction failed: ", e);
		}
	},

	async update(id, data) {
		console.log("Updating file " + JSON.stringify(data));
		await updateDoc(doc(db, buildCollectionPath(COLLECTION_NAME), id), data);
	},

	async delete(id) {
		console.log("Deleting file " + id);
		let docRef = await this.get(id)
		let doc = docRef.data()
		doc.status = 'Deleted'
		await this.update(id, doc)
	},

	async get(id) {
		console.log("Getting file " + id);
		const docRef = await getDoc(doc(db, buildCollectionPath(COLLECTION_NAME), id));
		return docRef;
	},

	async getFile(company) {
		const filesRef = collection(db, buildCollectionPath(COLLECTION_NAME));
		let q = query(filesRef,
			where("currentVersion", "==", true),
			where("company", "==", company));
		const querySnapshot = await getDocs(q);
		let activeDocument;
		querySnapshot.forEach((doc) => {
			activeDocument = doc;
		});
		return activeDocument;
	}
}