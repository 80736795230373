import {DateTime} from 'luxon'

function formatDateFromISO(isoDateStr, {format='MM/dd/yy', ifNull='-/-'}={}) {
	if(!isoDateStr) return ifNull
	return DateTime.fromISO(isoDateStr).toFormat(format)
}

function convertDateFromUnixTimestamp(timestamp) {
	const secondsInDay = 24 * 60 * 60;
	const excelEpoch = new Date(1899, 11, 31);
	const excelEpochAsUnixTimestamp = excelEpoch.getTime();
	const missingLeapYearDay = secondsInDay * 1000;
	const delta = excelEpochAsUnixTimestamp - missingLeapYearDay;
	const timestampAsUnixTimestamp = timestamp * secondsInDay * 1000;
	const parsed = timestampAsUnixTimestamp + delta;
	console.log('parsed', parsed)
	console.log('new Date', new Date(parsed))
	return isNaN(parsed) ? null : new Date(parsed).toLocaleDateString('en-US');
}

export {formatDateFromISO, convertDateFromUnixTimestamp}
