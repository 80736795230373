
const StorageConstants = {
	get TEMPLATE_DIR() {
		return 'templates'
	},
	
	get FINANCIALS_UPLOAD_TEMPLATE_FILENAME() {
		return process.env.VUE_APP_FIREBASE_FINANCIAL_UPLOAD_TEMPLATE
	},

	get FINANCIALS_UPLOAD_TEMPLATE_PATH() {
		return `${StorageConstants.TEMPLATE_DIR}/${StorageConstants.FINANCIALS_UPLOAD_TEMPLATE_FILENAME}`
	},
}

module.exports = StorageConstants
