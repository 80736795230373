<template>
	<modal-dialog :visible="visible" @close="close">
		<template v-slot:header>Add New</template>
		<template v-slot:body>
			<form v-on:submit.prevent="onFormSubmit" class="p-3">
				<div class="alert alert-danger mb-3" v-if="errorOnSubmit">
						{{ errorOnSubmit }}
				</div>
				<div class="mb-3">
					<label class="form-label">Select what to add</label>
					<drop-down :options="objectTypes" :value="selectedObjectType" v-on:option-selected="onObjectTypeSelected"></drop-down>
					<span class="field-error" v-if="v$.selectedObjectType.$error">{{ v$.selectedObjectType.$errors[0].$message }}</span>
				</div>
				<div class="mb-3">
					<label class="form-label">Name</label>
					<input type="text" class="form-control" id="name" v-model="name">
					<span class="field-error" v-if="v$.name.$error">{{ v$.name.$errors[0].$message }}</span>
				</div>
				<div class="mb-3" v-if="addingInvestment">
					<label class="form-label">Funds</label>
					<VueMultiselect
							v-model="fundsSelected"
							:multiple="true"
							placeholder="Select fund(s)"
							label="name"
							:close-on-select="false"
							track-by="name"
							:options="funds">
					</VueMultiselect>
					<span class="field-error" v-if="v$.fundsSelected.$error">{{ v$.fundsSelected.$errors[0].$message }}</span>
				</div>
			</form>
		</template>
		<template v-slot:footer>
			<button class="btn btn-primary" v-on:click="onFormSubmit" :class="addButtonClass">Add</button>
		</template>
	</modal-dialog>

</template>

<script type="text/javascript">

	import ModalDialog from "@/views/shared/ModalDialog";
	import DropDown from "@/views/shared/DropDown";
	import useVuelidate from "@vuelidate/core";
	import {required, requiredIf} from "@vuelidate/validators";
	import {FundsApi} from "@/api/funds_api";
	import {InvestmentsApi} from "@/api/investment_api";
	import FundDataService from "@/firebaseServices/FundDataService";
	import VueMultiselect from "vue-multiselect";

	export default {
		name: 'AddObjectModal',
		inheritAttrs: false,
		emits: ['close'],
		mixins: [],
		components: {
			ModalDialog,
			DropDown,
			VueMultiselect
		},

		filters: {
		},

		provide: function() {
			return {
			}
		},

		inject: [],

		props: ['visible'],

		setup() {
			return {
				v$: useVuelidate()
			}
		},

		validations () {
			return {
				selectedObjectType: {required},
				name: {required},
				fundsSelected: {required: requiredIf(this.addingInvestment)}
			}
		},

		data() {
			return {
				objectTypes: [{id: 'fund', name: 'Fund'},{id: 'investment', name: 'Investment'}],
				objectType: null,
				name: '',
				errorOnSubmit: null,
				fundsSelected: [],
				funds: [],
				adding: false
			}
		},

		computed: {
			selectedObjectType() {
				return this.objectType
			},
			addingInvestment() {
				return this.objectType == 'investment'
			},
			addButtonClass() {
				return this.adding == true ? 'disabled' : ''
			}
		},

		watch: {

		},

		created() {
		},

		mounted() {
			this.loadFunds()
		},

		methods: {
			loadFunds() {
				FundDataService.loadFunds().then(response => {
					this.funds = response.map(fund => (
							{
								id: fund.id,
								name: fund.name
						}))
				})
			},

			reset() {
				this.v$.$reset()
				this.objectType = null
				this.name = ''
				this.errorOnSubmit = null
				this.fundsSelected = []
				this.adding = false
			},

			async onFormSubmit() {
				this.errorOnSubmit = null
				this.adding = false
				this.v$.$validate()
				if (!this.v$.$error) {
					this.adding = true
					if(this.objectType == 'fund') {
						return FundsApi.createFund({name: this.name}).then(async () =>{
							this.$emit('close');
							this.reset()
							await FundDataService.clearCache()
							this.loadFunds()
						}).catch((reason) => {
							console.log(`Error on creating fund: ${reason}`)
							this.errorOnSubmit = `Error on creating Fund: ${reason.message}`
						})
					} else if(this.objectType == 'investment') {
						return InvestmentsApi.createInvestment({name: this.name, funds: this.fundsSelected}).then(async () =>{
							this.$emit('close')
							this.reset()
							await FundDataService.clearCache()
						}).catch((reason) => {
							console.log(`Error on creating fund: ${reason}`)
							this.errorOnSubmit = `Error on creating Investment: ${reason.message}`
						})
					} else {
						throw new Error(`Unhandled object type ${this.objectType}`)
					}
				} else {
					console.log(`got an error`)
				}
			},
			close() {
				this.reset()
				this.$emit('close')
			},
			onObjectTypeSelected(optionId) {
				this.objectType = optionId
			}
    }
	}
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style lang="scss">
@import '../../styles/custom-variables';
.multiselect__select::before {
	border-color: $black transparent transparent transparent;
}
.multiselect__tags {
	border-radius: 0px;
	.multiselect__placeholder {
		color: $black
	}
}
.multiselect, .multiselect__input, .multiselect__single {
	font-size: 14px;
}
</style>
