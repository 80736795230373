<template>
  <div class="row gx-lg-5 gx-md-0">
    <div class="col-lg-8 col-md-12">
      <section>
        <outstanding-requests/>
      </section>
      <section>
        <data-collection-history/>
      </section>
    </div>
    <div class="col-lg-4 col-md-12 d-md-none d-lg-block border-start px-3">
		<investment-owners></investment-owners>
    </div>
  </div>
</template>

<script type="text/javascript">

	import OutstandingRequests from './OutstandingRequests'
  import DataCollectionHistory from './DataCollectionHistory.vue'
  import InvestmentOwners from './InvestmentOwners.vue';

	export default {
		name: 'DataCollectionView',
		inheritAttrs: false,

		mixins: [],

		components: {
			OutstandingRequests,
			DataCollectionHistory,
			InvestmentOwners
		},

		filters: {
		},

		provide: function() {
			return {
			}
		},

		inject: [],

		props: [],

		data() {
			return {
			}
		},

		computed: {

		},

		watch: {

		},

		created() {
		},

		mounted() {
		},

		methods: {
    }
	}
</script>

<style lang="scss">
</style>
