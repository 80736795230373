<template>
  <div>
    <router-view></router-view>
  </div>
  <footer></footer>
</template>

<style scoped>
footer {
  height: 120px;
}
</style>
<script>export default {
  components: {}
}
</script>