<template>
	<tbody>
		<template v-for="row in uploads" :key="row.UploadId">
			<data-collection-upload :upload="row"
															v-on:upload-approved="onUploadApproved"
															v-on:upload-rejected="onUploadRejected"/>
		</template>
	</tbody>
</template>

<script type="text/javascript">
	import { useUserStore } from '@/stores/user'
	import { useFundStore } from '@/stores/fund'
  import FileDownload from "@/mixins/FileDownload";
  import CompanyDataService from "@/firebaseServices/CompanyDataService";
	import {UploadsApi} from '@/api/uploads_api'
	import DataCollectionUpload from './DataCollectionUpload'

	export default {
		name: 'DataCollectionUploads',
    props: ['uploads'],
		inheritAttrs: false,

		mixins: [FileDownload],

		components: {
			DataCollectionUpload
		},

		filters: {
		},

		provide: function() {
			return {
			}
		},

		inject: [],

		data() {
			return {
				companies: []
			}
		},

		computed: {
		},

		watch: {

		},

		setup() {
			const userStore = useUserStore()
			const fundStore = useFundStore()
			return {
				userStore,
				fundStore
			}
		},

		created() {
		},

		mounted() {
			this.loadCompanies()
		},

		methods: {

			onUploadApproved(upload, approvedUploadResponse) {
				this.$emit('uploadApproved', upload, approvedUploadResponse)
			},

			onUploadRejected(upload, resp) {
				this.$emit('uploadRejected', upload, resp)
			},

			approveFile(file) {
				console.log(file)
				UploadsApi.updateUploadStatus({uploadId: file.UploadId, newStatus: 'APPROVED'})
					.then(resp => {
						console.log('got response to approve', resp)
					})
			},
			rejectFile(file) {
				console.log('file', file)
			},
			deleteFile(file) {
				console.log('file', file)
			},
			lookupFundName(companyId) {
				if(companyId) {
					const fund = this.fundStore.funds.find(f => f.companies.indexOf(companyId) > -1)
					return fund ? fund.name : ''
				} else {
					return ''
				}
			},
			lookupCompanyName(companyId) {
				if(companyId) {
					const company = this.companies.find(c => c.id == companyId.toString())
					return company ? company.name : ''
				} else {
					return ''
				}
			},
			loadCompanies() {
				CompanyDataService.list(undefined, undefined, 1000).then(response => {
					console.log("!!! loading companies again!!!!")
					response.companies.forEach((doc) => {
						this.companies.push({id: doc.id, name: doc.name})
					});
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	.file-name-col {
		line-break: anywhere;
	}
</style>
