<template>
	<div>
		<div class="row">
			<div class="col p-0">
				<div class="mb-3 px-3">
					<h3 v-if="sidebarTitle != ''">{{sidebarTitle}}</h3>
					<h3 v-else>Active Investments</h3>
				</div>
				<ol class="list-inline scrollable-sidenav" v-if="investmentData.length > 0">
					<li class="padded" v-for="(inv, idx) in investmentData" v-bind:key="idx" :class="{'alt-bg': isOdd(idx)}">
						<div class="d-flex justify-content-between">
							<h5><router-link :to="{name: 'CompanyInvestment', params: {investmentId: inv.id}}">{{inv.name}}</router-link></h5>
							<p class="text-end graphite sidebar-body">{{updatedDateString(inv.overview?.asOf)}}</p>
						</div>

						<dl class="row investment-info mb-0 sidebar-body">
							<dt class="col-12 graphite">Revenue (entry) <format-currency :number="entryValue(inv.overview, 'LTM Revenue')" scale="mm" /></dt>
							<dt class="col-9 graphite">Revenue (latest) <format-currency :number="mapValue(inv.mostRecentFinancials?.data, 'LTM Revenue')" scale="mm" /></dt>
							<dd class="col-3 text-end mb-0"><span :class="amtChangeClass(entryValue(inv.overview, 'LTM Revenue'), mapValue(inv.mostRecentFinancials?.data, 'LTM Revenue'))"><format-percent :number="amtChangePercent(entryValue(inv.overview, 'LTM Revenue'), mapValue(inv.mostRecentFinancials?.data, 'LTM Revenue'))"/></span></dd>
						</dl>
						<dl class="row investment-info sidebar-body mb-0">
							<dt class="col-12 graphite">EBITDA (entry) <format-currency :number="entryValue(inv.overview, 'LTM EBITDA')" scale="mm" /></dt>
							<dt class="col-9 graphite">EBITDA (latest) <format-currency :number="mapValue(inv.mostRecentFinancials?.data, 'LTM EBITDA')" scale="mm" /></dt>
							<dd class="col-3 text-end"><span :class="amtChangeClass(entryValue(inv.overview, 'LTM EBITDA'), mapValue(inv.mostRecentFinancials?.data, 'LTM EBITDA'))"><format-percent :number="amtChangePercent(entryValue(inv.overview, 'LTM EBITDA'), mapValue(inv.mostRecentFinancials?.data, 'LTM EBITDA'))"/></span></dd>
						</dl>
					</li>
				</ol>
				<ol class="list-unstyled" v-else>
					<li class="ps-3 pt-1 pb-1 mb-2" v-for="index in 7" :key="index">
						<div class="d-flex justify-content-between my-1 placeholder-wave" style="height: 23px;">
							<span class="placeholder col-5"></span>
							<span class="placeholder col-3"></span>
						</div>
						<div class="d-flex justify-content-between my-1 placeholder-wave">
							<span class="placeholder col-7"></span>
						</div>
						<div class="d-flex justify-content-between my-1 placeholder-wave">
							<span class="placeholder col-5"></span>
							<span class="placeholder col-2"></span>
						</div>
						<div class="d-flex justify-content-between my-1 placeholder-wave">
							<span class="placeholder col-7"></span>
						</div>
						<div class="d-flex justify-content-between my-1 placeholder-wave">
							<span class="placeholder col-5"></span>
							<span class="placeholder col-2"></span>
						</div>
					</li>
				</ol>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import {formatDateFromISO} from '@/utils/date_utils'
	import FormatCurrency from '@/views/utils/FormatCurrency'
	import FormatPercent from '@/views/utils/FormatPercent'

	export default {
		name: 'InvestmentsSidebar',
		inheritAttrs: false,
		
		mixins: [],
		
		components: {
			FormatCurrency,
			FormatPercent
		},
		
		filters: {
		},
		
		provide: function() {
			return {
			}
		},
		
		inject: [],
		
		props: {
			sidebarTitle: {
				type: String,
				required: true,
				default: "Active Investments"
			},
			investmentData: {
				type: Array,
				required: true
			}
		},
		
		data() {
			return {
			}
		},
		
		computed: {
		},
		
		watch: {
			
		},
		
		created() {
		},
		
		mounted() {
		},
		
		methods: {
			amtChangeClass(firstVal, latestVal) {
				const diff = latestVal - firstVal

				if(diff < 0) {
					return 'rev-decline'
				} else {
					return 'rev-grew'
				}
			},

			amtChangePercent(firstVal, latestVal) {
				if(!firstVal || (latestVal == null)) return null
				const diff = latestVal - firstVal

				return (diff / firstVal)
			},

			updatedDateString(lastUpdated) {
				return `updated ${formatDateFromISO(lastUpdated, {format: 'MM/dd/yy'})}`
			},

			entryFinancialInfo(overview) {
				return overview.data['Financial Information (At Entry)'] || {}
			},

			mapValue(map, field) {
				if(!map) return null
				return map[field]
			},

			entryValue(overview, key) {
				if(!overview) return null
				return this.entryFinancialInfo(overview)[key] || null
			},
			isOdd(index) {
				return (index % 2) === 1
			}		
		}
	}
</script>

<style lang="scss" scoped>
	@import '../../styles/custom-variables';
	@import '../../styles/equivate/variables';
	.investment-info {
		dt, dd {
			color: $graphite;
			font-weight: normal;
		}
	}

	.rev-grew {
		color: $success;
	}

	.rev-decline {
		color: $danger;
	}
	.padded {
		padding-top: 15px;
		padding-bottom: 28px;
		padding-right: 25px;
		padding-left: 13px;
	}
</style>
