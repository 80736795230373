
function downloadData({fileName, data}={}) {
	if(!fileName) {
		throw new Error('fileName is required')
	}
	if(!data) {
		throw new Error('data is required')
	}
	const dlAnchor = document.createElement('a')

	const url = window.URL.createObjectURL(new Blob([data]))
	dlAnchor.href = url
	dlAnchor.download = fileName
	document.body.appendChild(dlAnchor)

	const dlObj = {
		download: () => {
			dlAnchor.click()
			document.body.removeChild(dlAnchor)
		}
	}

	dlObj.download()
}

export {downloadData}