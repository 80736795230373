import { getFunctions, httpsCallable } from "firebase/functions";

const ReportApi = function() {
}

ReportApi.getFundLPSummary = (fundId) => {
    return httpsCallable(getFunctions(), 'getFundLPSummary')(fundId)
}

ReportApi.getInvestmentLPSummary = (investmentIds) => {
    return httpsCallable(getFunctions(), 'getInvestmentLPSummary')(investmentIds)
}

ReportApi.getFullLPSummary = () => {
    return httpsCallable(getFunctions(), 'getFullLPSummary')()
}

export {ReportApi}