const SCALES = {
	1: 1,
	K: 1000.0,
	M: 1000000.0,
	mm: 1000000.0,
	B: 1000000000.0,
}

function findScale(num) {
	return ['B', 'M', 'K', 1].find(scale => Math.floor(num / SCALES[scale]) > 0)
}

function scaledNumber(num, scale) {
	const scaleVal = () => {
		if(!scale) {
			return findScale(num)
		} else {
			return SCALES[scale]
		}
	}

	return num / scaleVal()
}

function formatNumber(number, {scale=null, precision= 2, sep=',', ifNull= '-'}={}) {
	if(number == null || Number.isNaN(number)) {
		return ifNull
	}

	if(scale) {
		number = scaledNumber(number, scale)
	}

	const newVal = number.toFixed(precision),
				parts = newVal.split('.'),
				intPart = parts[0],
				numParts = intPart.length / 3,
				partsArr = []

	for(let i = 0 ; i < numParts ; ++i) {
		partsArr.push(intPart.slice(-3 * (i + 1), intPart.length - (i * 3)))
	}

	return `${partsArr.reverse().join(sep)}${parts.length > 1 ? `.${parts[1]}` : ''}${scale && scale != 1 ? scale : ''}`
}


export {formatNumber, scaledNumber, findScale}
