<template>
    <div class="skeleton-loader placeholder-wave">
      <!-- Title skeleton -->
      <div class="skeleton-title placeholder"></div>
  
      <!-- Pie chart skeleton -->
      <div class="skeleton-pie-chart placeholder"></div>
  
      <!-- Value skeletons -->
      <div class="skeleton-value1 placeholder"></div>
      <div class="skeleton-value2 placeholder"></div>
    </div>
</template>
  
<script>
export default {
    name: "PieChartPlaceholder",
};
</script>
  
<style scoped>
@import '../../styles/equivate/_placeholders.scss';
.skeleton-loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 120px; /* Adjust the width as needed */
  margin: 0px;
}

.skeleton-title {
  width: 100px;
  height: 20px;
  margin-bottom: 20px;
}

.skeleton-pie-chart {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  margin-bottom: 20px;
}

.skeleton-value1 {
  width: 140px;
  height: 20px;
  margin-bottom: 10px;
}

.skeleton-value2 {
  width: 70px;
  height: 20px;
  margin-bottom: 10px;
}
</style>
  