import {InvestmentsApi} from "@/api/investment_api";

export default {
	async list(sort, order, limit, offset) {
		console.log(`loading companies: sort:${sort}, order:${order}, limit:${limit}, offset:${offset}`);
		return InvestmentsApi.getInvestments({limit: limit, offset: offset, sort: sort, order:order}).then( (investmentsData) => {
			const rtn = { totalRows: investmentsData.data.totalRows, companies: investmentsData.data.investments }
			return rtn
		})
	},

	async create(data) {
		console.log("Creating company " + JSON.stringify(data));
		return InvestmentsApi.createInvestment(data)
	},

	async update(id, formData) {
		console.log(`Updating company ${id}, JSON.stringify(formData)`);
		const data = {id: id, data: formData}
		return InvestmentsApi.updateInvestment(data)
	},

	async delete(id) {
		console.log("Deleting company " + id);
		return InvestmentsApi.deleteInvestment(id)
	},

	async get(id) {
		console.log("Getting company " + id);
		return InvestmentsApi.getInvestment(id).then( (investmentData) => {
			return investmentData.data
		})
	}
}