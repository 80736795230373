import { createStore } from 'vuex'

export default createStore({
	state () {
		return {
			user: [],
			username: "",
			isAdmin: false,
			tenantId: undefined
		}
	},
	mutations: {
		setUser(state, user) {
			state.user = user;
			if(user != null)
				state.username = user.email
		},
		setAdmin(state, value) {
			state.isAdmin = value;
		},
		setTenantId(state, value) {
			state.tenantId = value;
		}
	}
})