<template>
	<ol class="list-inline eq-breadcrumb">
		<li class="list-inline-item" v-for="(linkData, idx) in breadcrumbData" v-bind:key="idx">
			<router-link :to="{name: 'InvestmentsView'}" v-if="linkData.routerLinkName=='InvestmentsView'"><span v-if="idx > 0">&gt; </span>{{linkData.linkText}}</router-link>
			<router-link :to="{name: 'ReportsView'}" v-else-if="linkData.routerLinkName=='ReportsView'"><span v-if="idx > 0">&gt; </span>{{linkData.linkText}}</router-link>
			<div class="current-page" v-else><span v-if="idx > 0">&gt; </span>{{linkData.linkText}}</div>
		</li>
		
	</ol>
</template>

<script type="text/javascript">
	

	export default {
		inheritAttrs: false,

		mixins: [],

		components: {
		},

		filters: {
		},

		provide: function() {
			return {
			}
		},

		inject: [],

		props: {
			breadcrumbData: {
				type: Array,
				required: true
			}
		},

		data() {
			return {
			
			}
		},

		computed: {

		},

		watch: {

		},

		created() {
		},

		mounted() {
		},

		methods: {
		}
	}
</script>

<style lang="scss">
.current-page {
	color: #878787;
}
</style>
