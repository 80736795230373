<template>
	<nav class="navbar navbar-expand-lg bg-white my-3">
		<div class="container-fluid ps-0">
			<a class="navbar-brand" href="/">
				<img src="@/assets/equivate_logo.svg" class="" alt="Equivate logo">
			</a>
			<button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navHeaderContent" aria-controls="navHeaderContent" aria-expanded="false" aria-label="Toggle navigation">
				<span class="navbar-toggler-icon"></span>
			</button>
			<div class="collapse navbar-collapse" id="navHeaderContent">
				<ul class="navbar-nav me-auto mb-2 mb-lg-0">
					<li class="nav-item">
						<router-link class="nav-link green-hover" :class="{active: isDashboardHome}" :to="{name: 'DashboardView'}" aria-current="page">
							<svg width="17" height="12" viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M16.125 10H2.5V0.375C2.5 0.1875 2.3125 0 2.125 0H0.875C0.65625 0 0.5 0.1875 0.5 0.375V11.625C0.5 11.8438 0.65625 12 0.875 12H16.125C16.3125 12 16.5 11.8438 16.5 11.625V10.375C16.5 10.1875 16.3125 10 16.125 10ZM12.125 3L9.5 4.75L6.8125 1.21875C6.65625 1 6.34375 1 6.1875 1.25L3.5 5.75V9H15.5L12.6875 3.15625C12.5625 2.9375 12.3125 2.875 12.125 3Z" :fill="isDashboardHome ? '#05A662' : '#005CB9'"/>
							</svg> <span>Dashboard</span>
						</router-link>
					</li>
					<li class="nav-item">
						<router-link class="nav-link green-hover" :class="{active: isInvestments}" :to="{name: 'InvestmentsView'}" aria-current="page">
							<svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M6.82617 6.73047C6.44531 7.08203 6.44531 7.69727 6.82617 8.04883C7.17773 8.42969 7.79297 8.42969 8.14453 8.04883C8.52539 7.69727 8.52539 7.08203 8.14453 6.73047C7.79297 6.34961 7.17773 6.34961 6.82617 6.73047ZM7.5 0.109375C3.48633 0.109375 0.234375 3.39062 0.234375 7.375C0.234375 11.3887 3.48633 14.6406 7.5 14.6406C11.4844 14.6406 14.7656 11.3887 14.7656 7.375C14.7656 3.39062 11.4844 0.109375 7.5 0.109375ZM11.1914 4.47461L9.25781 8.69336C9.14062 8.89844 8.99414 9.04492 8.78906 9.16211L4.57031 11.0957C4.07227 11.3008 3.57422 10.8027 3.7793 10.3047L5.71289 6.08594C5.83008 5.88086 5.97656 5.73438 6.18164 5.61719L10.4004 3.68359C10.8984 3.47852 11.3965 3.97656 11.1914 4.47461Z" :fill="isInvestments ? '#05A662' : '#005CB9'"/>
							</svg> <span>Investments</span>
						</router-link>
					</li>
					<li class="nav-item">
						<router-link class="nav-link green-hover" :class="{active: isReports}" :to="{name: 'ReportsView'}" aria-current="page">
							<svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M7.5 4.25V0H1.25C0.8125 0 0.5 0.34375 0.5 0.75V15.25C0.5 15.6875 0.8125 16 1.25 16H11.75C12.1562 16 12.5 15.6875 12.5 15.25V5H8.25C7.8125 5 7.5 4.6875 7.5 4.25ZM2.5 2.25C2.5 2.125 2.59375 2 2.75 2H5.25C5.375 2 5.5 2.125 5.5 2.25V2.75C5.5 2.90625 5.375 3 5.25 3H2.75C2.59375 3 2.5 2.90625 2.5 2.75V2.25ZM2.5 4.25C2.5 4.125 2.59375 4 2.75 4H5.25C5.375 4 5.5 4.125 5.5 4.25V4.75C5.5 4.90625 5.375 5 5.25 5H2.75C2.59375 5 2.5 4.90625 2.5 4.75V4.25ZM8.5 12H10C10.25 12 10.5 12.25 10.5 12.5C10.5 12.7812 10.25 13 10 13H8.5C8 13 7.53125 12.7188 7.3125 12.2812C7.21875 12.0938 7.0625 12.0625 7 12.0625C6.90625 12.0625 6.75 12.0938 6.65625 12.25L6.4375 12.75C6.34375 12.9062 6.1875 13.0312 6 13.0312C5.96875 13.0312 6 13 6 13C5.96875 13 5.96875 13 5.9375 13C5.75 13 5.5625 12.875 5.5 12.6875L5 11.0938L4.65625 12.0938C4.46875 12.6562 3.96875 13 3.375 13H3C2.71875 13 2.5 12.7812 2.5 12.5C2.5 12.25 2.71875 12 3 12H3.375C3.53125 12 3.65625 11.9062 3.71875 11.7812L4.28125 10.0625C4.375 9.75 4.65625 9.5625 5 9.5625C5.3125 9.5625 5.59375 9.75 5.6875 10.0625L6.125 11.375C6.75 10.875 7.8125 11.0625 8.1875 11.8125C8.25 11.9375 8.375 12 8.5 12ZM12.2812 3.28125L9.21875 0.21875C9.0625 0.09375 8.875 0 8.6875 0H8.5V4H12.5V3.8125C12.5 3.625 12.4062 3.4375 12.2812 3.28125Z" :fill="isReports ? '#05A662' : '#005CB9'"/>
							</svg> <span>Reports</span>
						</router-link>
					</li>
					<li class="nav-item">
						<router-link class="nav-link green-hover" :class="{active: isDataCollection}" :to="{name: 'DataCollectionView'}" aria-current="page">
							<svg width="11" height="15" viewBox="0 0 11 15" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M6.375 4.46875V0.75H0.90625C0.523438 0.75 0.25 1.05078 0.25 1.40625V14.0938C0.25 14.4766 0.523438 14.75 0.90625 14.75H10.0938C10.4492 14.75 10.75 14.4766 10.75 14.0938V5.125H7.03125C6.64844 5.125 6.375 4.85156 6.375 4.46875ZM8.01562 7.39453L6.375 9.9375L8.01562 12.5078C8.15234 12.7266 7.98828 13 7.74219 13H6.78516C6.64844 13 6.53906 12.9453 6.48438 12.8359C5.9375 11.8516 5.5 10.9492 5.5 10.9492C5.30859 11.3594 5.22656 11.4961 4.48828 12.8359C4.43359 12.9453 4.32422 13.0273 4.1875 13.0273H3.25781C2.98438 13.0273 2.82031 12.7266 2.95703 12.5078L4.625 9.96484L2.95703 7.39453C2.82031 7.17578 2.98438 6.90234 3.25781 6.90234H4.1875C4.32422 6.90234 4.43359 6.95703 4.48828 7.06641C5.19922 8.40625 5.03516 7.96875 5.5 8.92578C5.5 8.92578 5.66406 8.625 6.48438 7.06641C6.53906 6.95703 6.64844 6.90234 6.78516 6.90234H7.74219C7.98828 6.875 8.15234 7.17578 8.01562 7.39453ZM10.75 4.08594C10.75 3.92188 10.668 3.75781 10.5586 3.62109L7.87891 0.941406C7.74219 0.832031 7.57812 0.75 7.41406 0.75H7.25V4.25H10.75V4.08594Z" :fill="isDataCollection ? '#05A662' : '#005CB9'"/>
							</svg> <span>Data Collection</span>
						</router-link>
					</li>
					<!-- <li class="nav-item">
						<router-link class="nav-link green-hover" :class="{active: isMapping}" :to="{name: 'MappingView'}" aria-current="page">
							<svg width="19" height="15" viewBox="0 0 19 15" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M4.25 10.375H1.625C1.13281 10.375 0.75 10.7852 0.75 11.25V13.875C0.75 14.3672 1.13281 14.75 1.625 14.75H4.25C4.71484 14.75 5.125 14.3672 5.125 13.875V11.25C5.125 10.7852 4.71484 10.375 4.25 10.375ZM3.59375 8.1875H8.84375V9.5H10.1562V8.1875H15.4062V9.5H16.7188V7.94141C16.7188 7.36719 16.2266 6.875 15.6523 6.875H10.1562V5.125H11.25C11.7148 5.125 12.125 4.74219 12.125 4.25V1.625C12.125 1.16016 11.7148 0.75 11.25 0.75H7.75C7.25781 0.75 6.875 1.16016 6.875 1.625V4.25C6.875 4.74219 7.25781 5.125 7.75 5.125H8.84375V6.875H3.32031C2.74609 6.875 2.28125 7.36719 2.28125 7.94141V9.5H3.59375V8.1875ZM10.8125 10.375H8.1875C7.69531 10.375 7.3125 10.7852 7.3125 11.25V13.875C7.3125 14.3672 7.69531 14.75 8.1875 14.75H10.8125C11.2773 14.75 11.6875 14.3672 11.6875 13.875V11.25C11.6875 10.7852 11.2773 10.375 10.8125 10.375ZM17.375 10.375H14.75C14.2578 10.375 13.875 10.7852 13.875 11.25V13.875C13.875 14.3672 14.2578 14.75 14.75 14.75H17.375C17.8398 14.75 18.25 14.3672 18.25 13.875V11.25C18.25 10.7852 17.8398 10.375 17.375 10.375Z" :fill="isMapping ? '#05A662' : '#005CB9'"/>
							</svg> <span>Mapping</span>
						</router-link>
					</li> -->
				</ul>
				<div class="nav-item pe-0">
					<a class="nav-link round-nav-link d-flex justify-content-center align-items-center" href="#" role="button" v-on:click.prevent="showAddModal">
						<font-awesome-icon icon="plus" size="lg"/>
					</a>
				</div>
				<div class="nav-item dropdown pe-0" id="userIdentity">
					<a class="nav-link round-nav-link d-flex justify-content-center align-items-center" href="#" id="usernameNavDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
						<font-awesome-icon icon="user" size="lg"/>
          </a>
          <ul class="dropdown-menu dropdown-menu-dark dropdown-menu-end" aria-labelledby="usernameNavDropdown">
            <li><a class="dropdown-item" href="/admin" v-if="this.$userCan('admin', 'index')">Admin</a></li>
            <li><a class="dropdown-item" href="/admin/user" v-if="this.$userCan('user', 'index')">Admin - Users</a></li>
            <li><a class="dropdown-item" href="/admin/company" v-if="this.$userCan('company', 'index')">Admin - Companies</a></li>
            <li><a class="dropdown-item" href="/logout" v-on:click.prevent="logout">Sign out</a></li>
            <li><hr class="dropdown-divider"></li>
            <li><a class="dropdown-item" href="#" v-on:click.prevent="showAboutModal">About</a></li>
          </ul>
        </div>
			</div>
		</div>
	</nav>
	<about-modal :visible="isAboutModalVisible" @close="closeAboutModal"></about-modal>
	<add-object-modal :visible="isModalVisible" @close="closeAddModal"></add-object-modal>
</template>

<script type="text/javascript">
	import { getAuth, signOut } from "firebase/auth";
	import AddObjectModal from "./AddObjectModal";
	import AboutModal from "@/views/nav/AboutModal.vue";

	// maps to speed up figuring out which link to highlight
	const INVESTMENT_PAGES = {
		InvestmentsView: true,
		FundInvestment: true,
		CompanyInvestment: true
	}

	export default {
		inheritAttrs: false,

		mixins: [],

		components: {
			AboutModal,
			AddObjectModal
		},

		filters: {
		},

		provide: function() {
			return {
			}
		},

		inject: [],

		props: [],

		data() {
			return {
				isModalVisible: false,
				isAboutModalVisible: false
			}
		},

		computed: {
			username() {
				console.log('this.store', this.$store.state)
				return this.$store.state.username
			},
			
			isDashboardHome() {
				return this.$route.name === 'DashboardView'
			},
			isInvestments() {
				return INVESTMENT_PAGES[this.$route.name] === true
			},
			isReports() {
				return this.$route.name === 'ReportsView'
			},
			isDataCollection() {
				return this.$route.name === 'DataCollectionView'
			},
			isMapping() {
				return this.$route.name === 'MappingView'
			}
		},

		watch: {

		},

		created() {
		},

		mounted() {
		},

		methods: {
			logout(){	
				const auth = getAuth();
				signOut(auth).then(() => {
					location.reload()
				}).catch((error) => {
					console.log("error on signout: " + error );
				});
			},
			closeAddModal() {
				this.isModalVisible = false
			},
			showAddModal() {
				this.isModalVisible = true
			},
			closeAboutModal() {
				this.isAboutModalVisible = false
			},
			showAboutModal() {
				this.isAboutModalVisible = true
			}
		}
	}
</script>

	<style lang="scss" scoped>
	@import '../../styles/custom-variables.scss';

	.navbar-brand img {
		width: 152px;
		height: 37px;
		flex-shrink: 0;
	}
	.nav-item span {
		padding-left: 4px;
		vertical-align: text-top;
	}
	
	.nav-item {
		font-size: 16px;
		padding: 0 12px;
		a {
			color: #005CB9;
		}
		a.active {
			color: #05a662;
			border-bottom: 1px solid #05a662;
		}
		.green-hover:hover {
			color: #05a662;
			path{
				fill: #05a662;
			}
		}
		
		.round-nav-link {
			width: 38px;
			height: 38px;
			border-radius: 19px;
			&:hover {
				background-color: $hoverblue;
			}
		}
	}
	
	#userIdentity {
		.nav-link.dropdown-toggle {
			display: inline;
			color: #2d2d2d;
		}
		
		.dropdown-item {
			color: #ffffff;
		}
	}
	svg {
		display: none;
	}
	@media (min-width: 1200px) {
		svg {
			display: inline;
		}
	}
</style>
