<template>
	<span>{{formatDate}}</span>
</template>

<script type="text/javascript">
  import {DateTime} from "luxon";

	export default {
		inheritAttrs: false,
		name: 'FormatDate',

		props: {
			date: {
			},

			fromFormat: {
				type: String
			},

			toFormat: {
				type: String,
				default() {
					return 'MM/dd/yyyy'
				}
			},

			ifNull: {
				type: String,
				default() {
					return '-/-'
				}
			},

			utcTime: {
				type: Boolean,
				default() {
					return false
				}
			}
		},

		computed: {
			formatDate: {
				get() {
					if (!this.date) return this.ifNull
					let parsedDate
					try {
						if(this.fromFormat) {
							parsedDate = DateTime.fromFormat(this.date, this.fromFormat)
						}
						if (!parsedDate || !parsedDate.isValid) {
							parsedDate = DateTime.fromJSDate(this.date)
							if (!parsedDate.isValid) {
								parsedDate = DateTime.fromISO(this.date)
							}
						}
					} catch (e) {
						return this.ifNull
					}
					if (!parsedDate.isValid) {
						return this.ifNull
					}
					if (this.utcTime) {
						return parsedDate.toUTC().toFormat(this.toFormat)
					}
					return parsedDate.toFormat(this.toFormat)
				}
			}
		}
	}
</script>

<style lang="scss">
</style>
