function formatPercentage(number, {precision= 0, separator='', ifNull= '- %'}={}) {
	if(number == null) {
		return ifNull
	}

	let retPerc = Number(number * 100).toFixed(precision)

	if(separator) {
		const parts = retPerc.split('.'),
			intPart = parts[0],
			numParts = intPart.length / 3,
			partsArr = []

		for(let i = 0 ; i < numParts ; ++i) {
			partsArr.push(intPart.slice(-3 * (i + 1), intPart.length - (i * 3)))
		}

		retPerc = `${partsArr.reverse().join(separator)}${parts.length > 1 ? `.${parts[1]}` : ''}`
	}

	return `${retPerc}%`
}


export {formatPercentage}
