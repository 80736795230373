<template>
	<div class="row">
		<div class="col-12">
			<div class="d-flex justify-content-between">
				<div>
					<h3>Capital Status of Funds</h3>
					<p class="text-alternate" v-if="(!loadingData && hasData)">As of <format-date v-bind:date="asOfDateMonthYear"
							to-format="MMMM yyyy"></format-date></p>
				</div>
				<router-link :to="{ name: 'InvestmentsView' }" class="d-inline-block" v-if="(!loadingData && hasData)">View
					More</router-link>
			</div>
			<div class="d-flex justify-content-between mt-4" v-if="(!loadingData && hasData)">
				<div class="list-inline-item fund-chart" v-for="fundInf in fundsWithData" :key="fundInf.id"
					v-on:click.prevent="gotoFund(fundInf.id)">
					<div>
						<fund-chart :fund-name="fundInf.name" :amt-invested="fundInf.amtInvested"
						:amt-fees="fundInf.amtFees" :amt-expenses="fundInf.amtExpenses"
						:amt-available="fundInf.amtAvailable" />
					</div>
				</div>
			</div>

			<div class="alert alert-secondary" v-if="(!loadingData && !hasData)">
				<p>No fund data available.</p>
			</div>
			<div class="d-flex justify-content-between mt-4" v-if="loadingData">
				<div class="list-inline-item fund-chart" v-for="index in 5" :key="index">
					<PieChartPlaceholder/>
				</div>
			</div>	
		</div>
	</div>
</template>

<script type="text/javascript">
import FundChart from '@/components/financials/FundChart.vue'
import FundDataService from "@/firebaseServices/FundDataService";
import FormatDate from '../utils/FormatDate'
import { useFundStore } from "@/stores/fund";
import { DateTime } from 'luxon'
import PieChartPlaceholder from '../skeletonLoaders/PieChartPlaceholder.vue';

export default {
	name: 'CapitalStatusOfFunds',
	inheritAttrs: false,

	mixins: [],

	components: {
		FundChart,
		FormatDate,
		PieChartPlaceholder
	},

	filters: {
	},

	provide: function () {
		return {
		}
	},

	inject: [],

	props: [],

	setup() {
		const fundStore = useFundStore()
		return {
			fundStore
		}
	},

	data() {
		return {
			fundsData: [],
			asOfDateMonthYear: '',
			loadingData: true
		}
	},

	computed: {
		hasData() {
			return this.fundsData && (this.fundsData.length > 0)
		},

		// filter funds that are empty
		fundsWithData() {
			if (this.fundsData) {
				return this.fundsData.filter(f => (f.amtInvested > 0) || (f.amtFees > 0) || (f.amtExpenses > 0) || (f.amtAvailable))
			} else {
				return []
			}

		}
	},

	watch: {

	},

	created() {
	},

	mounted() {
		this.loadFundsData()
	},

	methods: {
		gotoFund(fundName) {
			const fund = this.fundStore.funds.find(f => f.name == fundName)
			this.$router.push({ name: 'FundInvestment', params: { fundId: fund.id } })
		},

		loadFundsData() {
			this.loadingData = true
			FundDataService.loadFundsData().then(response => {
				this.fundsData = response.sort((a, b) => b.name.localeCompare(a.name));
				if (this.fundsData && this.fundsData[0]) {
					this.asOfDateMonthYear = DateTime.fromISO(this.fundsData[0].asOf).toJSDate()
				}
			}).catch(e => {
				console.error("error trying to load fund status data", e)
			})
				.finally(() => {
					this.loadingData = false
				})
		}
	}
}
</script>

<style lang="scss" scoped>
.fund-chart {
	cursor: pointer;
}
</style>
