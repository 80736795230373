<template>
	<div>
		<h2>Redirecting...</h2>
	</div>
</template>

<script>

	export default {
		created() {
			console.log('in LoginRedirect', process.env)
			window.location.href = '/login.html'
		}
	}
</script>

<style lang="scss">
</style>
