<template>
	<span>{{formattedCurrency}}</span>
</template>

<script type="text/javascript">
	import {formatDollars} from '@/utils/currency_utils'

	export default {
		inheritAttrs: false,
    name: 'FormatCurrency',

		props: {
			number: {
				type: Number
			},

			scale: {
				type: String,
				default() {
					return '1'
				}
			},

			precision: {
				type: Number,
				default() {
					return 2
				}
			},

			separator: {
				type: String,
				default() {
					return ','
				}
			},

			ifNull: {
				type: String,
				default() {
					return '$ -'
				}
			}
		},
		
		computed: {
			formattedCurrency() {
				return formatDollars(this.number, {scale: this.scale, precision: this.precision, sep: this.separator, ifNull: this.ifNull})
      }
		}
	}
</script>

<style lang="scss">
</style>
