import db from "@/firebaseServices/firestore";
import {
	collection,
	documentId,
	getDocs,
	query,
	orderBy,
	limit,
	limitToLast,
	startAfter,
	endBefore,
	where, addDoc
} from "firebase/firestore";
import {buildCollectionPath} from './firestore'

const COLLECTION_NAME = 'users'

export default {
	async list(sortColumn, sortOrder, maxResults, startAtDoc, endAtDoc) {
		console.log(`loading users: sortColumn:${sortColumn}, sortOrder:${sortOrder}, maxResults:${maxResults}, startAtDoc:${startAtDoc}, endAtDoc:${endAtDoc}"`);
		let users = []
		const usersRef = collection(db, buildCollectionPath(COLLECTION_NAME));
		const sortColumnVal = sortColumn || "name";
		const sortOrderVal = sortOrder || "asc";
		const maxResultsVal = maxResults || 10;
		let q = query(usersRef,  orderBy('deleted'));
		if (startAtDoc) {
			q = query(q, orderBy(sortColumnVal, sortOrderVal), startAfter(startAtDoc), limit(maxResultsVal));
		} else if (endAtDoc) {
			q = query(q, orderBy(sortColumnVal, sortOrderVal), endBefore(endAtDoc), limitToLast(maxResultsVal));
		} else {
			q = query(q, orderBy(sortColumnVal, sortOrderVal), limit(maxResultsVal));
		}
		q = query(q, where("deleted", "!=", true))
		const querySnapshot = await getDocs(q);
		console.log(`Retrieved: ${querySnapshot.size} users`)
		querySnapshot.forEach((doc) => {
			users.push(doc);
		});

		return users;
	},

	async listByIds(userIds) {
		console.log(`loading users by Ids: userIds:${userIds}`);
		let users = []
		if(userIds != undefined && userIds.length > 0) {
			let q = query(collection(db, buildCollectionPath(COLLECTION_NAME)), limit(1000), where(documentId(), "in", userIds));
			const querySnapshot = await getDocs(q);
			querySnapshot.forEach((doc) => {
				users.push(doc);
			});
		}
		return users;
	},

	async getByEmail(email) {
		console.log(`getByEmail ${email}`);
		let q = query(collection(db, buildCollectionPath(COLLECTION_NAME)), where("email", "==", email));
		const querySnapshot = await getDocs(q);
		if(querySnapshot.size == 1) {
			return querySnapshot.docs.pop();
		}
		return null;
	},

	async createUser(email, name) {
		const data = {
			deleted: false,
			email: email,
			name: name,
			status: 'Active'
		}
		await addDoc(collection(db, buildCollectionPath(COLLECTION_NAME)), data);
		// await MailService.sendMail(email, "Invitation to Equivate", `You can login to equivate at ${process.env.VUE_APP_FIREBASE_EQUIVATE_URL}`)
	}
}