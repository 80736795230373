<template>
	<div class="row">
		<div class="col-12" v-if="!loadingUploads && hasUploads">
			<div class="d-flex justify-content-between align-items-center" >
				<h2>Data Collection History</h2>
				<div>
					<a href="#" v-on:click.prevent="showTemplateModal">Download Template</a>
					<button class="btn btn-primary" v-on:click="showUploadModal">Upload Data</button>
					<button class="btn btn-primary" v-on:click="showRequestUpdateModal">Request Update</button>
				</div>
			</div>
			<div>
				<div class="d-flex mt-4">
					<table class="table">
					<thead>
									<tr>
										<sort-header column-name="Date Uploaded" sort-by="dateCreated" initial-sort-direction="desc" v-on:sort="sort"></sort-header>
										<th>Uploaded By</th>
										<th>Investment</th>
										<th>Name</th>
										<th>Status</th>
										<th>Approved By</th>
										<th scope="col">Actions</th>
									</tr>
					</thead>
					<data-collection-uploads :uploads="uploads" v-on:upload-approved="onUploadStatusChanged" v-on:upload-rejected="onUploadStatusChanged"/>
					</table>
				</div>
			</div>
		</div>
		<spanner-paging-control :page-size="limit" v-bind:rows="uploads" v-on:page-load="loadPage" ref="pagingControl" v-show="!loadingUploads"/>
		<div class="col-12" v-if="!loadingUploads && !hasUploads">
			<!-- NO DATA TODO -->
		</div>
		<div class="col-12" v-if="loadingUploads">
			<div class="d-flex justify-content-between my-1 placeholder-wave" style="height: 35px;">
				<span class="placeholder col-4"></span>
				<span class="col-6 d-flex justify-content-between">
					<span class="placeholder col-4"></span>
					<span class="placeholder col-3"></span>
					<span class="placeholder col-3"></span>
				</span>
				
			</div>
			<TablePlaceholder/>
		</div>
	</div>
	<request-update-modal :visible="isModalVisible" @close="closeRequestUpdateModal"></request-update-modal>
	<download-template-modal :visible="isTemplateModalVisible" :companies="companies" @close="closeTemplateModal"></download-template-modal>
	<data-collection-upload-modal :visible="isUploadModalVisible" :companies="companies" @close="closeUploadModal"></data-collection-upload-modal>
</template>

<script type="text/javascript">
	
	import DataCollectionUploads from './DataCollectionUploads.vue'
	import {UploadsApi} from "@/api/uploads_api";
	import {InvestmentsApi} from '@/api/investment_api';
	import SortHeader from "@/components/table/SortHeader";
	import SpannerPagingControl from "@/components/table/SpannerPagingControl";
	import RequestUpdateModal from './../shared/RequestUpdateModal'
	import DownloadTemplateModal from './DownloadTemplateModal.vue';
	import DataCollectionUploadModal from './DataCollectionUploadModal.vue';
	import TablePlaceholder from '../skeletonLoaders/TablePlaceholder.vue';

	export default {
		name: 'DataCollectionHistory',
		inheritAttrs: false,
		
		mixins: [],
		
		components: {
			SpannerPagingControl,
			DataCollectionUploads,
			SortHeader,
			RequestUpdateModal,
			DownloadTemplateModal,
			DataCollectionUploadModal,
			TablePlaceholder
		},
		
		filters: {
		},
		
		provide: function() {
			return {
			}
		},
		
		inject: [],
		
		props: [],
		
		data() {
			return {
				limit: 10,
				sortColumn: 'date_created',
				sortOrder: 'desc',
				uploads: [],
				isModalVisible: false,
				isTemplateModalVisible: false,
				isUploadModalVisible: false,
				loadingUploads: true,
				companies: []
			}
		},
		
		computed: {
			hasUploads() {
				return this.uploads.length > 0
			},
		},
		
		watch: {
			
		},
		
		created() {
		},
		
		mounted() {
			this.loadPage(0);
			this.loadCompanies();
		},
		
		methods: {
			showTemplateModal() {
				this.isTemplateModalVisible = true
			},
			closeTemplateModal() {
				this.isTemplateModalVisible = false
			},
			showUploadModal() {
				this.isUploadModalVisible = true
			},
			closeUploadModal() {
				this.isUploadModalVisible = false
			},
			onUploadStatusChanged(upload, approvedUploadResponse) {
				// find the upload in the list and replace it with the response
				let uploadIdx = -1
				this.uploads.find((u, idx) => {
					if(u.UploadId === approvedUploadResponse.UploadId) {
						uploadIdx = idx
					}
				})
				
				if(uploadIdx > -1) {
					this.uploads[uploadIdx] = approvedUploadResponse
				}
			},
			loadPage(offset) {
				this.loadingUploads = true
				UploadsApi.getUploadsByType({type: 'financials', limit: this.limit, offset: offset, order: this.sortOrder, sort: this.sortColumn})
					.then(response => {
						const totalRows = response.data.totalRows
						console.log('get uploads response', response)
						this.uploads = response.data.uploads
						this.$refs.pagingControl.loadComplete(offset, totalRows)
					}).finally(() => {
						this.loadingUploads = false
					}).catch(e => {
						console.error("error trying to list files", e)
					});
			},
			handleDocsLoad(response, direction) {
				console.log('docs load', response, direction)
			},
			sort(sortColumn, sortDirection) {
				this.sortColumn = sortColumn
				this.sortOrder = sortDirection
				this.loadPage(0)
			},
			closeRequestUpdateModal() {
				this.isModalVisible = false
			},
			showRequestUpdateModal() {
				this.isModalVisible = true
			},
			async loadCompanies() {
				const companies = await InvestmentsApi.getInvestments()
				console.log('companies', companies.data)
				this.companies = companies.data.investments.map(companyDoc => ({
					id: companyDoc.id,
					name: companyDoc.name
				}))
					.sort((c1, c2) => c1.name.localeCompare(c2.name))
			},
		}
	}
</script>

<style lang="scss" scoped>
	@import '../../styles/custom-variables.scss';
	th {
		color: $graphite;
	}
	.btn {
		margin-left: 9px !important;
	}
	a {
		margin-right: 20px;
	}
</style>
