<template>
        <div class="row gx-5">
            <div class="col-6">
                <div class="d-flex justify-content-between">
                    <div>
                        <h3>Fund Performance - IRR</h3>
                        <!-- p class="text-alternate" v-if="hasData">As of August 28, 2022</p -->
                    </div>
                </div>
                <div>
                    <Bar :data="irrChartData" :options="chartConfig" :height="240" :plugins="plugins" v-if="(!loadingData && hasData)"/>
                    <div class="alert alert-secondary" v-if="(!loadingData && !hasData)">
                        <p>No data available.</p>
                    </div>
                </div>
                <BarGraphPlaceholder v-if="loadingData"/>
            </div>

            <div class="col-6">
                <div class="d-flex justify-content-between">
                    <div>
                        <h3>Fund Performance - MOIC</h3>
                        <!-- p class="text-alternate" v-if="hasData">As of August 28, 2022</p -->
                    </div>
                </div>
                <div>
                    <Bar :data="moicChartData" :options="moicChartConfig" :height="240" :plugins="plugins" v-if="(!loadingData && hasData)" />
                    <div class="alert alert-secondary" v-if="(!loadingData && !hasData)">
                        <p>No data available.</p>
                    </div>
                </div>
                <BarGraphPlaceholder v-if="loadingData"/>
            </div>
        </div>
</template>

<script type="text/javascript">
import { Bar } from 'vue-chartjs'
import {
        Chart as ChartJS,
        Ticks,
        Title,
        Tooltip,
        Legend,
        BarElement,
        CategoryScale,
        LinearScale
} from 'chart.js'

import FundDataService from "@/firebaseServices/FundDataService";
import BarGraphPlaceholder from '../skeletonLoaders/BarGraphPlaceholder.vue';

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
        name: 'FundPerformance',
        inheritAttrs: false,

        mixins: [],

        components: {
            Bar,
            BarGraphPlaceholder
        },

        filters: {
        },

        provide: function () {
            return {
            }
        },

        inject: [],

        props: [],

        data() {
            return {
                fundPerformance: [],
                loadingData: true,
                plugins: [{
                    beforeInit(chart) {
                    const originalFit = chart.legend.fit;
                    chart.legend.fit = function fit() {
                    originalFit.bind(chart.legend)();
                    this.height += 10;
                    }
                    }
                }]
            }
        },

        computed: {
            hasData() {
                return this.fundPerformance && (this.fundPerformance.length > 0)
            },

            irrChartData() {
                return {
                    labels: this.fundPerformance.map(perf => perf.name),
                    datasets: [
                        {
                            label: 'Gross',
                            data: this.fundPerformance.map(perf => perf.grossIRR * 100.0),
                            backgroundColor: '#005cb9'
                        },
                        {
                            label: 'Net',
                            data: this.fundPerformance.map(perf => perf.netIRR * 100.0),
                            backgroundColor: '#6e9dcd'
                        }
                    ]
                }
            },

            chartConfig() {
                return {
                    responsive: true,
                    maintainAspectRatio: false,
                    layout: {},
                    plugins: {
                        legend: {
                            position: 'top',
                            align: 'end',
                            labels: {
                                boxWidth: 28
                            }
                        }
                    },
                    scales: {
                        y: {
                            ticks: {
                                color: "#878787",
                                callback: function (value, index, ticks) {
                                    return Ticks.formatters.numeric.apply(this, [value, index, ticks]) + '%';
                                }
                            }
                        },
                        x: {
                            ticks: {
                                color: "#878787"
                            },
                            grid: {
                                display: false
                            }
                        }
                    }
                }
            },

            moicChartConfig() {
                return {
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            position: 'top',
                            align: 'end',
                            labels: {
                                boxWidth: 28
                            }
                        }
                    },
                    scales: {
                        y: {
                            ticks: {
                                color: "#878787",
                                callback: function (value, index, ticks) {
                                    return Ticks.formatters.numeric.apply(this, [value, index, ticks]) + 'x';
                                }
                            },
                            grid: {
                                color: '#F0F0F0'
                            }
                        },
                        x: {
                            ticks: {
                                color: "#878787"
                            },
                            grid: {
                                display: false,
                            }
                        }
                    }
                }
            },

            moicChartData() {
                return {
                    labels: this.fundPerformance.map(perf => perf.name),
                    datasets: [
                        {
                            label: 'Gross',
                            data: this.fundPerformance.map(perf => perf.grossMOIC),
                            backgroundColor: '#05a662'
                        },
                        {
                            label: 'Net',
                            data: this.fundPerformance.map(perf => perf.netMOIC),
                            backgroundColor: '#71c7a3'
                        }
                    ]
                }
            },

            fundsIRR() {
                return this.fundPerformance.map(perf => {
                    return {
                        id: perf.id,
                        name: perf.name,
                        netIRR: perf.netIRR * 100.0,
                        grossIRR: perf.grossIRR * 100.0
                    }
                })
            },

            fundsMOIC() {
                return this.fundPerformance.map(perf => {
                    return {
                        id: perf.id,
                        name: perf.name,
                        netMOIC: perf.netMOIC,
                        grossMOIC: perf.grossMOIC
                    }
                })
            }
        },

        watch: {

        },

        created() {
        },

        mounted() {
            this.loadFundPerformance()
        },

        methods: {
            loadFundPerformance() {
                this.loadingData = true
                FundDataService.loadFundPerformance().then(response => {
                    this.fundPerformance = response.sort((a, b) => b.name.localeCompare(a.name));
                }).catch(e => {
                    console.error("error trying to fund performance data", e)
                })
                    .finally(() => {
                        this.loadingData = false
                    })
            }
        }
}
</script>

<style lang="scss"></style>
