<template>
	<div class="row gx-5">
		<div class="col-42">
			<div>
				<div class="d-flex justify-content-between mb-3">
					<h3>ESG Information</h3>
					<p class="as-of-date">As Of:
						<format-date :date="esgFormValue('ESG Data as of:')" :from-format="'MM/dd/yy'" :utc-time="true"/>
					</p>
				</div>
				<dl class="row dl-rev-striped company-attributes ms-0" v-if="dataLoaded">
					<template v-for:="(value, key) in filteredEsgInfo">
						<dt class="col-8"><span>{{ key }}</span></dt>
						<dd v-if="isPercent(key)" class="col-4"><span><format-percent :number="value"/></span></dd>
						<dd v-else class="col-4"><span></span>{{ value }}</dd>
					</template>
				</dl>
			</div>
		</div>
	</div>
</template>


<script type="text/javascript">
import FormatDate from '@/views/utils/FormatDate'
import FormatPercent from '@/views/utils/FormatPercent'

export default {
	inheritAttrs: false,

	mixins: [],

	components: {
		FormatDate,
		FormatPercent
	},

	filters: {},

	provide: {},

	inject: [],

	props: {
		sourceData: {
			type: Object,
			required: true
		}
	},

	data() {
		return {
			data: this.sourceData
		}
	},

	computed: {
		dataLoaded() {
			return this.data != null && Object.keys(this.data).length > 0 && this.data.data != null
		},
		esgInfo() {
			if (this.dataLoaded) {
				return this.data.data
			}
			return null
		},
		filteredEsgInfo() {
			const info = this.esgInfo
			if (info != null) {
				const filtered = {}
				// First, sort the data by the displayOrder
				info.sort((a, b) => a.displayOrder - b.displayOrder)
				// Second, flatten it all out
				info.forEach( function (row) {
					if (row.label !== 'ESG Data as of:') {
						filtered[row.label] = row.value
					}
				});
				return filtered
			}
			return null

		}
	},

	methods: {
		esgFormValue(key) {
			const info = this.esgInfo
			if (info != null) {
				// Find the row that has an object with the label set to key
				for (const row of info) {
					if (row.label === key) {
						return row.value
					}
				}
			}
			return null
		},
		isPercent(key) {
			return key.includes('%') || key.toLowerCase().includes('percent')
		}
	},
	watch: {
		sourceData: function (value) {
			this.data = value
		}
	}
}
</script>

<style lang="scss">
</style>