<template>
	<div class="row gx-5">
    <div class="col-12">
      <h3>Fund MOIC & IRR</h3>
			<div v-if="hasData && !dataLoading">
				<div class="d-flex">
					<pill-control
						:nav-options="[{name: 'MOIC', value: 'moic'}, {name: 'IRR', value: 'irr'}]"
						@pill-nav-clicked="investmentViewPillClicked" />
				</div>
				<div v-if="isMOICActive">
					<Bar :data="moicChartData" :options="moicChartConfig" :height="275"/>
				</div>
				<div v-if="isIRRActive">
					<Bar :data="irrChartData" :options="irrChartConfig" :height="275"/>
				</div>
			</div>
			<div v-if="!dataLoading && !hasData">
				<div class="alert alert-secondary">
					<p>No data available</p>
				</div>
			</div>
			<div v-if="dataLoading">
				<BarGraphPlaceholder/>
			</div>
    </div>
	</div>
</template>

<script type="text/javascript">
	import { Bar } from 'vue-chartjs'
	import {
		Chart as ChartJS,
		Ticks,
		Title,
		Tooltip,
		Legend,
		BarElement,
		CategoryScale,
		LinearScale
	} from 'chart.js'

	import PillControl from "../../components/controls/PillControl"
	import {formatPercentage} from "@/utils/percent_utils";
	import {formatNumber} from "@/utils/number_utils";
	import BarGraphPlaceholder from '../skeletonLoaders/BarGraphPlaceholder.vue';
	
	ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

	export default {
		name: 'InvestmentsChart',
		inheritAttrs: false,

		mixins: [],

		components: {
			Bar,
			PillControl,
			BarGraphPlaceholder
		},

		filters: {
		},

		provide: function() {
			return {
			}
		},

		inject: [],

		props: {
			fundData: {
				type: Array
			},
			dataLoading: {
				type: Boolean
			}
		},

		data() {
			return {
        activeChart: 'MOIC'
			}
		},

		computed: {
			hasData() {
				return this.fundData.length > 0
			},
	
      isMOICActive() {
        return this.activeChart == 'MOIC'
      },

      isIRRActive() {
        return this.activeChart == 'IRR'
      },

			irrChartData() {
				return {
					labels: this.fundData.map(fund => fund.name),
					datasets: [
						{
							label: 'Gross',
							data: this.fundData.map(fund => fund.fund_performance ? fund.fund_performance.grossIRR * 100 : '-'),
							backgroundColor: '#005cb9'
						},
						{
							label: 'Net',
							data: this.fundData.map(fund => fund.fund_performance ? fund.fund_performance.netIRR * 100 : '-'),
							backgroundColor: '#6e9dcd'
						}
					]
				}
			},

			irrChartConfig() {
				let config = this.chartConfig()
				config.scales.y.ticks.callback = function (value, index, ticks) {
					return Ticks.formatters.numeric.apply(this, [value, index, ticks]) + '%';
				}
				return config
			},

			moicChartConfig() {
				let config = this.chartConfig()
				config.scales.y.ticks.callback = function (value, index, ticks) {
					return Ticks.formatters.numeric.apply(this, [value, index, ticks]) + 'x';
				}
				return config
			},

			moicChartData() {
				return {
					labels: this.fundData.map(fund => fund.name),
					datasets: [
						{
							label: 'Gross',
							data: this.fundData.map(fund => fund.fund_performance?.grossMOIC ? formatNumber(fund.fund_performance?.grossMOIC) : '-'),
							backgroundColor: '#05a662'
						},
						{
							label: 'Net',
							data: this.fundData.map(fund => fund.fund_performance?.netMOIC ? formatNumber(fund.fund_performance?.netMOIC) : '-'),
							backgroundColor: '#71c7a3'
						}
					]
				}
			},

			fundsIRR() {
				return this.fundData.map(fund => {
					return {
						id: fund.id,
						name: fund.name,
						netIRR: fund.fund_performance ? formatPercentage(fund.fund_performance.netIRR ) : '-',
						grossIRR: fund.fund_performance ? formatPercentage(fund.fund_performance.grossIRR) : '-'
					}
				})
			},

			fundsMOIC() {
				return this.fund.map(fund => {
					return {
						id: fund.id,
						name: fund.name,
						netMOIC: fund.fund_performance?.netMOIC ? formatNumber(fund.fund_performance?.netMOIC) : '-',
						grossMOIC: fund.fund_performance?.grossMOIC ? formatNumber(fund.fund_performance?.grossMOIC) : '-'
					}
				})
			},

      selectedFundId() {
        return 4
      }
		},

		watch: {

		},

		created() {
		},

		mounted() {
		},

		methods: {
			chartConfig() {
				return {
					responsive: true,
					maintainAspectRatio: false,
					plugins: {
						legend: {
							position: 'top',
							align: 'end'
						}
					},
					scales: {
						y: {
							ticks: {
								color: "#878787",
							}
						},
						x: {
							ticks: {
								color: "#878787"
							}
						}
					}
				}
			},

			investmentViewPillClicked(navClicked) {
				switch(navClicked) {
				case 'moic':
					this.activeChart = 'MOIC'
					break
				case 'irr':
					this.activeChart = 'IRR'
					break
				default:
					throw new Error(`Value for an unknown investment view clicked: '${navClicked}'`)
				}
			},

      onFundSelected(optionId) {
        console.log(`Fund selected: ${optionId}`)
      }
		}
	}
</script>

<style lang="scss">
</style>
