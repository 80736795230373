<template>

	<transition name="modal-fade">
		<div v-if="visible" class="modal fade show" tabindex="-1" style="display: block;">
			<div class="modal-dialog modal-dialog-centered">
				<div class="modal-content">
					<div class="modal-header text">
						<slot name="header">
							This is the default title!
						</slot>
						<button type="button" @click="close" class="btn-close" ></button>
					</div>


					<section class="modal-body">
						<slot name="body">
							This is the default body!
						</slot>
					</section>

					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" @click="close">Close</button>
						<slot name="footer">
						</slot>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
export default {
	name: 'ModalDialog',
	emits: ['close'],
	props: {
		visible: Boolean
	},
	methods: {
		close() {
			this.$emit('close');
		},
	},
};
</script>

<style lang="scss">
@import '../../styles/custom-variables';
.modal {
	background-color: rgba(0,0,0,0.25);
	box-shadow: 2px 2px 20px 1px;
	overflow-x: auto;
	display: flex;
	flex-direction: column;
}
//
.modal-header {
	background-color: $lines;
}
.modal-fade-enter,
.modal-fade-leave-to {
	opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
	transition: opacity .5s ease;
}
</style>