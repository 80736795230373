import { createRouter, createWebHistory } from 'vue-router'
import { getAuth, onAuthStateChanged }    from "firebase/auth";
import HomeView                           from '@/views/Home'
import AdminView                          from '@/views/admin/Admin'
import LoginRedirectView                  from '@/views/LoginRedirect'
import DashboardView                      from '@/views/dashboard/Dashboard'
import InvestmentsView                    from '@/views/investments/InvestmentsView'
import FundInvestment                     from '@/views/investments/FundInvestment'
import CompanyInvestment                  from '@/views/investments/CompanyInvestment'
import ReportsView                        from '@/views/reports/Reports'
import ReportView                         from '@/views/reports/Report'
import DataCollectionView                 from '@/views/dataCollection/DataCollection'
import MappingView                        from '@/views/mapping/Mapping'

const routes = [
	{
		path: '/login',
		name: 'LoginRedirectView',
		component: LoginRedirectView
	},
	{
		path: '/',
		name: 'HomeView',
		meta: {
			requiresAuth: true,
		},
		component: HomeView,
		children: [
			{
				name: 'DashboardView',
				path: '/',
				component: DashboardView,
				meta: {
					title: 'Dashboard'
				}
			},
			{
				name: 'InvestmentsView',
				path: 'investments',
				component: InvestmentsView,
				meta: {
					title: 'Investments'
				}
			},
			{
				name: 'FundInvestment',
				component: FundInvestment,
				path: 'funds/:fundId',
				meta: {
					title: 'Investments'
				}
			},
			{
				name: 'CompanyInvestment',
				component: CompanyInvestment,
				path: 'investments/:investmentId',
				meta: {
					title: 'Investments'
				}
			},
			{
				name: 'ReportsView',
				path: 'reports',
				component: ReportsView,
				meta: {
					title: 'Reports'
				}
			},
			{
				name: 'ReportView',
				path: 'report/:funds?/:investments?',
				component: ReportView,
				props: true,
				meta: {
					title: 'Custom Report'
				}
			},
			{
				name: 'DataCollectionView',
				path: 'dataCollection',
				component: DataCollectionView,
				meta: {
					title: 'Data Collection'
				}
			},
			{
				name: 'MappingView',
				path: 'mapping',
				component: MappingView,
				meta: {
					title: 'Mapping'
				}
			},
			{
				name: 'AdminView',
				path: 'admin',
				component: AdminView,
				meta: {
					title: 'Admin'
				}
			},
			{
				path: 'admin/company',
				name: 'CompanyList',
				meta: {
					title: 'Admin - Companies'
				},
				component: () => import(/* webpackChunkName: "companyList" */ '../views/company/List.vue')
			},
			{
				path: 'admin/company/:id',
				name: 'CompanyShow',
				meta: {
					title: 'Admin - Companies'
				},
				component: () => import(/* webpackChunkName: "companyShow" */ '../views/company/Show.vue')
			},
			{
				path: '/admin/company/create',
				name: 'CompanyCreate',
				meta: {
					title: 'Admin - Companies'
				},
				component: () => import(/* webpackChunkName: "companyCreate" */ '../views/company/Create.vue')
			},
			{
				path: '/admin/company/:id/edit',
				name: 'CompanyEdit',
				meta: {
					title: 'Admin - Companies'
				},
				component: () => import(/* webpackChunkName: "companyEdit" */ '../views/company/Edit.vue')
			},
			{
				path: 'admin/user',
				name: 'UserList',
				meta: {
					title: 'Admin - Users'
				},
				component: () => import(/* webpackChunkName: "useList" */ '../views/user/List.vue')
			},
			{
				path: 'admin/user/create',
				name: 'UserCreate',
				meta: {
					title: 'Admin - Users'
				},
				component: () => import(/* webpackChunkName: "useCreate" */ '../views/user/Create.vue')
			}
		]
	}
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

const getCurrentUser = () =>
	new Promise((resolve, reject) => {
		const removeListener = onAuthStateChanged(
			getAuth(),
			(user) => {
				removeListener();
				resolve(user);
			},
			reject
		);
	});

router.beforeEach(async (to, from, next) => {
	console.log('in beforeEach', 'to', to, 'from', from, 'next', next)
	let user = await getCurrentUser();
	if (to.matched.some((record) => record.meta.requiresAuth)) {
		if (user) {
			next();
		} else {
			next('/login');
		}
	} else if (to.matched.some((record) => record.meta.requiresNoAuth)) {
		if (!user) {
			next();
		} else {
			next("/");
		}
	} else {
		next();
	}
});

export default router