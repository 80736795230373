import { useFundStore } from '@/stores/fund'
import {InvestmentsApi} from "@/api/investment_api";

export default {
	async clearCache() {
		return useFundStore().reloadFunds()
	},

	async loadFundPerformance() {
		console.log(`loading fund performance`);
		let performances = []

		const funds = useFundStore().funds

		for(const spannerFund of funds) {
			const fundPerformanceData = {
				name: spannerFund.name,
				fundId: spannerFund.id,
				grossIRR: spannerFund.fund_performance ? spannerFund.fund_performance.grossIRR : 0,
				netIRR: spannerFund.fund_performance ? spannerFund.fund_performance.netIRR : 0,
				grossMOIC: spannerFund.fund_performance ? spannerFund.fund_performance.grossMOIC : 0,
				netMOIC: spannerFund.fund_performance ? spannerFund.fund_performance.netMOIC : 0
			}
			performances.push(fundPerformanceData);
		}

		// Fill in any missing funds
		for(const fund of funds) {
			const found = performances.filter(p => p.name == fund.name)
			if(found.length == 0) {
				performances.push({name: fund.name})
			}
		}

		// Finally.. sort them
		performances.sort((a, b) => {
			const fundA = funds.find(element => element.name == a.name)
			const fundB = funds.find(element => element.name == b.name)
			return Number(fundA.display_order) > Number(fundB.display_order) ? 1 : -1
		})

		return performances;
	},

	async loadEbitda() {
		console.log(`loadEbitda`);
		const funds = useFundStore().funds
		return InvestmentsApi.getPerformance().then( (performanceData) => {
			const rtn = { investments: [] }
			for(const fund of funds) {
				for(const investmentData of performanceData.data) {
					const inFund = investmentData.funds.find( f => f.id == fund.id )
					if(inFund) {
						rtn.investments.push({
							companyId: investmentData.id,
							name: investmentData.name,
							entry_ebitda:  Number((investmentData.performance_values?.ebitdaAtEntry ? investmentData.performance_values?.ebitdaAtEntry : 0 ) / 1000000),
							current_ebitda: Number((investmentData.performance_values?.ebitdaLatest ? investmentData.performance_values?.ebitdaLatest : 0 ) / 1000000),
							fund: fund.name,
							fundId: fund.id
						})
					}
				}
			}
			return rtn
		})
	},

	async loadValueStatus() {
		console.log(`loadValueStatus`);
		const funds = useFundStore().funds

		const rtn = { fundData: {} }
		for(const spannerFund of funds) {
			rtn.fundData[spannerFund.id] = {
				realizedAmt: spannerFund.equity_value ? spannerFund.equity_value.bertram_realized_equity_value : 0,
				unrealizedAmt: spannerFund.equity_value ? spannerFund.equity_value.bertram_unrealized_fmv_equity_value : 0
			}
		}

		return rtn
	},

	async loadFundsData() {
		console.log('loadFundsData')
		const funds = useFundStore().funds

		let fundsData = []
		for(const spannerFund of funds) {
			let fundData = {
				id: spannerFund.name,
				asOf: spannerFund.asOf,
				name: spannerFund.name,
				amtInvested: 0,
				amtFees: 0,
				amtExpenses: 0,
				amtAvailable: 0
			}
			if(typeof(spannerFund.funds_data) != 'undefined'){
				fundData.amtInvested = Number(spannerFund.funds_data.Invested ? spannerFund.funds_data.Invested : 0),
				fundData.amtFees = Number(spannerFund.funds_data.Fees ? spannerFund.funds_data.Fees : 0),
				fundData.amtExpenses = Number(spannerFund.funds_data.Expenses ? spannerFund.funds_data.Expenses : 0),
				fundData.amtAvailable = Number(spannerFund.funds_data.Available ? spannerFund.funds_data.Available : 0)
			}
			fundsData.push(fundData);
		}

		fundsData.sort((a, b) => {
			const fundA = funds.find(element => element.name == a.name)
			const fundB = funds.find(element => element.name == b.name)
			return Number(fundA.display_order) < Number(fundB.display_order) ? 1 : -1
		} )

		return fundsData;
	},

	async loadFunds() {
		const funds = useFundStore().funds
		return funds
	}
}
