<template>
	<div class="row">
		<div class="col-5" v-if="!loadingValueStatusData && hasValueStatusData">
			<div class="d-flex flex-column">
				<h3 class="text-center">Value Status</h3>
				<div class="align-items-center mb-5">
					<drop-down :options="fundsWithData" :value="selectedValueStatusFundId"
						v-on:option-selected="onValueStatusFundSelected"></drop-down>
				</div>
				<div>  <!-- The chart should be in its own container to prevent weird resizings -->
					<Pie :data="valueStatusChartData" :options="valueStatusConfig" :height="120"/>
				</div>
				<p class="realized m-0 text-center mt-5">Realized: {{ realizedAmt }} | {{ realizedPercent }}%</p>
				<p class="unrealized m-0 text-center">Unrealized: {{ unrealizedAmt }} | {{ unrealizedPercent }}%</p>
			</div>
		</div>
		<div class="col-5" v-if="!loadingValueStatusData && !hasValueStatusData">
			<h3 class="text-center">Value Status</h3>
			<div class="alert alert-secondary">
				<p>No data available.</p>
			</div>
		</div>
		<div class="col-5" v-if="loadingValueStatusData">
			<div class="d-flex flex-column">
				<div class="align-items-center my-5">
					<PieChartPlaceholder/>
				</div>
			</div>
		</div>
		<div class="col-7" v-if="(!loadingInvestmentData && hasInvestmentData)">
			<div class="d-flex justify-content-between">
				<h3>Investment Performance - EBITDA</h3>
			</div>
			<div class="d-flex">
				<drop-down :options="funds" :value="selectedFundId" v-on:option-selected="onFundSelected"></drop-down>
			</div>
			<div>
				<Bar :data="investmentPerformanceChartData" :options="investmentPerformanceChartConfig" :height="250" />
			</div>
		</div>
		<div class="col-7" v-if="!loadingInvestmentData && !hasInvestmentData">
			<h3>Investment Performance - EBITDA</h3>
			<div class="alert alert-secondary">
				<p>No data available.</p>
			</div>
		</div>
		<div class="col-7" v-if="loadingInvestmentData">
			<BarGraphPlaceholder/>
		</div>
	</div>
</template>

<script type="text/javascript">
import { formatDollars } from '@/utils/currency_utils'
import { pieChartColorPalette } from '@/utils/chart_utils'
import { DateTime } from 'luxon'
import { Bar, Pie } from 'vue-chartjs'
import DropDown from '../shared/DropDown'
import {
	Chart as ChartJS,
	Title,
	Tooltip,
	Legend,
	BarElement,
	CategoryScale,
	LinearScale,
	ArcElement, Ticks,
} from 'chart.js'
import FundDataService from "@/firebaseServices/FundDataService";
import PieChartPlaceholder from '../skeletonLoaders/PieChartPlaceholder.vue'
import BarGraphPlaceholder from '../skeletonLoaders/BarGraphPlaceholder.vue'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement)

export default {
	inheritAttrs: false,

	mixins: [],

	components: {
		Bar,
		Pie,
		DropDown,
		PieChartPlaceholder,
		BarGraphPlaceholder
	},

	filters: {
	},

	provide: function () {
		return {
		}
	},

	inject: [],

	props: [],

	data() {
		return {
			investmentData: {},
			valueStatusData: {},
			funds: [],
			fundId: null,
			valueStatusFundId: null,
			loadingFundData: true,
			loadingValueStatusData: true,
			loadingInvestmentData: true
		}
	},

	computed: {
		hasValueStatusData() {
			return (this.realizedAmt !== null) && (this.unrealizedAmt !== null)
		},

		hasInvestmentData() {
			return this.investmentData && this.investmentData.investments && (this.investmentData.investments.length > 0)
		},

		selectedFundId() {
			return this.fundId
		},
		selectedValueStatusFundId() {
			return this.valueStatusFundId
		},

		valueStatusDataForFund() {
			return this.valueStatusData.fundData[this.selectedValueStatusFundId] || { realizedAmt: 0, unrealizedAmt: 0 }
		},

		realizedAmt() {
			if (this.loadingValueStatusData) return null

			return formatDollars(this.valueStatusDataForFund.realizedAmt, { scale: 'mm' })
		},

		unrealizedAmt() {
			if (this.loadingValueStatusData) return null

			return formatDollars(this.valueStatusDataForFund.unrealizedAmt, { scale: 'mm' })
		},

		realizedPercent() {
			if (this.loadingValueStatusData) return null
			return ((this.valueStatusDataForFund.realizedAmt / this.totalRealizedUnrealized) * 100).toFixed(1)
		},

		unrealizedPercent() {
			if (this.loadingValueStatusData) return null
			return ((this.valueStatusDataForFund.unrealizedAmt / this.totalRealizedUnrealized) * 100).toFixed(1)
		},

		totalRealizedUnrealized() {
			if (this.loadingValueStatusData) return null
			return this.valueStatusDataForFund.realizedAmt + this.valueStatusDataForFund.unrealizedAmt
		},

		asOfDate() {
			return this.hasInvestmentData ? DateTime.fromJSDate(this.investmentData.asOf).toFormat('MMM dd, yyyy') : ''
		},

		// filter funds that are empty
		fundsWithData() {
			return this.findFundsWithData(this.funds)
		},

		investmentPerformanceChartData() {
			if (this.loadingInvestmentData) {
				return {}
			} else {
				const fundInvestments = this.investmentData.investments.filter(function (el) {

					return el.fundId.toString() == this.fundId
				}, this)

				return {
					labels: fundInvestments.map(d => d.name),
					datasets: [
						{
							label: 'Entry',
							data: fundInvestments.map(d => d.entry_ebitda),
							backgroundColor: '#257456'
						},
						{
							label: 'Latest',
							data: fundInvestments.map(d => d.current_ebitda),
							backgroundColor: '#71c7a3'
						}
					]
				}
			}
		},

		valueStatusChartData() {
			if (this.loadingValueStatusData) {
				return {}
			} else {
				const fundData = this.valueStatusData.fundData[this.selectedValueStatusFundId]
				return {
					labels: ['Realized', 'Unrealized'],
					datasets: [
						{
							backgroundColor: pieChartColorPalette(2),
							data: [fundData ? fundData.realizedAmt : 0, fundData ? fundData.unrealizedAmt : 0]
						}
					]
				}
			}
		},

		valueStatusConfig() {
			return {
				responsive: true,
				maintainAspectRatio: false,
				plugins: {
					legend: {
						display: false
					}
				}
			}
		},

		investmentPerformanceChartConfig() {
			return {
				responsive: true,
				maintainAspectRatio: false,
				plugins: {
					legend: {
						position: 'top',
						align: 'end',
                        labels: {
							boxWidth: 28
						}
					}
				},
				scales: {
					y: {
						ticks: {
							callback: function (value, index, ticks) {
								return Ticks.formatters.numeric.apply(this, [value, index, ticks]) + 'mm';
							}
						}
					}
				}
			}
		}

	},

	watch: {

	},

	created() {
	},

	mounted() {
		this.loadFunds()
		this.loadInvestmentData()
		this.loadValueStatusData()
	},

	methods: {

		findFundsWithData(fundList) {
			if (fundList) {
				return fundList.filter(f => (f.equity_value != null) && ((f.equity_value.bertram_realized_equity_value > 0) || (f.equity_value.bertram_unrealized_fmv_equity_value > 0)))
			} else {
				return []
			}
		},

		loadFunds() {
			this.loadingFundData = true
			FundDataService.loadFunds().then(response => {
				this.funds = response.sort((a, b) => b.name.localeCompare(a.name))
				if (this.funds.length > 0) {
					this.fundId = this.funds[0].id
					this.valueStatusFundId = this.findFundsWithData(this.funds)[0]?.id
				}
			})
				.finally(() => {
					this.loadingFundData = false
				})
		},

		loadValueStatusData() {
			this.loadingValueStatusData = true
			FundDataService.loadValueStatus().then(response => {
				console.log('loadValueStatusData', response)
				this.valueStatusData = response;
			}).catch(e => {
				console.error("error trying to load value status data", e)
			})
				.finally(() => {
					this.loadingValueStatusData = false
				})
		},

		loadInvestmentData() {
			this.loadingInvestmentData = true
			FundDataService.loadEbitda().then(response => {
				console.log('loadInvestmentData', response)
				this.investmentData = response;
			}).catch(e => {
				console.error("error trying to load ebitda data", e)
			})
				.finally(() => {
					this.loadingInvestmentData = false
				})
		},

		onFundSelected(optionId) {
			this.fundId = optionId
		},

		onValueStatusFundSelected(optionId) {
			this.valueStatusFundId = optionId
		}
	}
}
</script>

<style lang="scss" scoped>
@import '../../styles/custom-variables';

.realized {
	color: $navy;
}

.unrealized {
	color: $green;
}
.align-items-center {
	display: flex;
	justify-content: center;
}
</style>
