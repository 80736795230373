const {formatNumber} = require('./number_utils')

function formatDollars(amt, {scale=null, precision=0, sep=',', ifNull='$ -'}={}) {
	if(amt == null || Number.isNaN(amt)) return ifNull
	const isNegative = amt < 0

	amt = Math.abs(amt)

	if(isNegative) {
		return `($${formatNumber(amt, {scale, precision, sep})})`
	} else {
		return `$${formatNumber(amt, {scale, precision, sep})}`
	}
}

export {formatDollars}
