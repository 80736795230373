<template>
  <div class="skeleton-loader placeholder-wave">
    <!-- Top Row with 2 Boxes on the Left and 1 Box on the Right -->
    <div class="top-row">
      <div class="top-left">
        <div class="stacked-box1 placeholder"></div>
        <div class="stacked-box2 placeholder"></div>
      </div>
      <div class="top-right">
        <div class="right-box placeholder"></div>
      </div>
    </div>

    <!-- Large Box Below -->
    <div class="large-box placeholder"></div>

    <!-- Thin Box at the Bottom -->
    <div class="thin-box placeholder"></div>
  </div>
</template>

<script>
export default {
    name: "BarGraphPlaceholder",
};
</script>
  
<style scoped>
@import '../../styles/equivate/_placeholders.scss';
.skeleton-loader {
  width: 100%;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
}

.top-row {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
}

.top-left {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.stacked-box1 {
  width: 100px;
  height: 20px;
}

.stacked-box2 {
  width: 140px;
  height: 12px;
}

.top-right .right-box {
  width: 120px;
  height: 35px;
}

.large-box {
  width: 100%;
  height: 170px;
  margin-bottom: 5px;
}

.thin-box {
  width: 100%;
  height: 30px;
}
</style>
  