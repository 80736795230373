<template>
  <div>
		<div class="container">
			<nav-header />
		</div>
    <span class="d-md-none d-lg-block border-bottom"></span>
    <hr/>
		<div class="container">
			<main>
				<router-view/>
			</main>
		</div>
  </div>

</template>

<script>

	import { getAuth, signOut } from "firebase/auth";
	import NavHeader from '@/views/nav/NavHeader'

export default {
	name: "HomeView",
	components: { NavHeader },
  computed: {
    username() {
      return this.$store.state.username
    },
    isDashboardActive() {
      return this.$router.currentRoute.value.fullPath == '/' ? 'active' : ''
    },
    isFilesActive() {
      return this.$router.currentRoute.value.fullPath.indexOf('/financials') == 0 ? 'active' : ''
    },
    isCompanyActive() {
      return this.$router.currentRoute.value.fullPath.indexOf('/admin/company') == 0 ? 'active' : ''
    },
    isUserActive() {
      return this.$router.currentRoute.value.fullPath.indexOf('/admin/user') == 0 ? 'active' : ''
		}
  },
  methods: {
    logout: function(){

      const auth = getAuth();
      signOut(auth).then(() => {
        location.reload()
      }).catch((error) => {
        console.log("error on signout: " + error );
      });
    }
  }
}
</script>

<style scoped>

</style>
