<template>
  <div class="dropdown me-1" :class="fullWidthStyle">
    <button class="btn btn-dropdown dropdown-toggle text" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ref="dropDownRef">
      {{ selectedText }}
    </button>
    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
      <a class="dropdown-item text"
         href="#"
         v-for="option in options"
         :key="option.id"
         v-on:click="optionSelected(option, $event)"
         :class="{ active: isSelected(option) }" >
        {{option.name}}
      </a>
    </div>
  </div>
</template>

<script type="text/javascript">

	import { Dropdown } from 'bootstrap'
	export default {
		inheritAttrs: false,
    name: 'DropDown',

		props: {
      options: {
        type: Array,
        default() {
          return []
        }
      },
			value: [String, Number],
			fullWidth: Boolean
    },

    data() {
      return {
        selectedValue: null
      }
    },

		computed: {
      selectedText() {
        let text = 'Select'
        const valueToMatch = this.selectedValue || this.value
        for(const option of this.options) {
          if(option.id == valueToMatch) {
            text = option.name
          }
        }
        return text
      },
			fullWidthStyle() {
				if(this.fullWidth == true) {
					return 'full-width'
				}
				return ''
			}
		},

    methods: {
      isSelected(option) {
        return option.id == this.value
      },
			optionSelected(option, ev) {
				ev.preventDefault()
				ev.stopPropagation()
        this.selectedValue = option.id
				this.$emit('option-selected', this.selectedValue, option.name)
				new Dropdown(this.$refs.dropDownRef).hide()
      },

    }
  }
</script>

<style lang="scss">
@import '../../styles/custom-variables';
.full-width {
	width: 100%;
	button {
		width: 100%;
	}
}
.btn.btn-dropdown {
	background-color: $white;
}
</style>
