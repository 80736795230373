
const DEFAULT_BAR_PALETTE = ['#1f1a4d', '#005cb9', '#6e9dcd', '#b6d1ec', '#257456', '#05a662', '#71c7a3', '#a9d7c3']

// TODO: made up by JMC
const DEFAULT_PIE_PALETTE = ['#1f1a4d', '#257456', '#b6d1ec', '#cef2e3', '#a9d7c3']
//
// returns the list of colors to use given a number of colors desired.  There will
// be repeated colors if the number of colors requested is large enough
//
function barChartColorPalette(numColors, palette = DEFAULT_BAR_PALETTE) {
	return paletteGenerator(palette, numColors)
}

function pieChartColorPalette(numColors, palette = DEFAULT_PIE_PALETTE) {
	return paletteGenerator(palette, numColors)
}

//
// private
//
function paletteGenerator(palette, numColors) {
	const retPalette = []
	for(let i = 0 ; i < numColors ; ++i) {
		const idx = i % palette.length
		retPalette.push(palette[idx])
	}

	return retPalette
}

export {barChartColorPalette, pieChartColorPalette}
