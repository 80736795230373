<template>
  <th scope="col" class="sortable" v-bind:class="sortDirection" v-bind:data-sort-by="sortBy" v-on:click="sort">{{columnName}}</th>
</template>

<script type="text/javascript">

	export default {

    props: {
      initialSortDirection: String,
      sortBy: String,
      columnName: String
    },

		data() {
			return {
        sortDirection: this.initialSortDirection
			}
		},

		methods: {
      sort(event) {
        let target = event.target;
        let asc = target.classList.contains('asc');
        let desc = target.classList.contains('desc');
        target.classList.remove('asc');
        target.classList.remove('desc');
        let sortableColumns = document.getElementsByClassName('sortable')
        for(var i = 0; i < sortableColumns.length; i++) {
          let element = sortableColumns[i]
          element.classList.remove('asc')
          element.classList.remove('desc')
        }
        if (desc || (!asc && !desc)) {
          target.classList.add('asc')
          this.sortOrder = 'asc'
        } else {
          target.classList.add('desc')
          this.sortOrder = 'desc'
        }
        this.sortColumn = target.dataset.sortBy
        this.$emit('sort', this.sortColumn, this.sortOrder)
      }
    }
	}

</script>
